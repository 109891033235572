<template>
    <tr class="sheetmng-row" :class="{'enabled':useMode}">
        <td class="edit" @click.stop="edit_click"><icon name="pen" class="icon"></icon></td>
        <td class="code">{{item.code}}</td>
        <td class="name">{{item.name}}</td>
        <td class="startDay">{{item.startDay}}日</td>
        <td class="holidays"><button @click.stop="holidays_click">編集</button></td>
        <td>{{item.comment}}</td>
    </tr>
</template>

<script>
export default {
    props: {
        item: null
    },
    computed: {
        useMode() {
            return (this.item.useFlag == 1) ? false : true;
        }
    },
    methods: {
        edit_click() {
            this.$emit('editClick', this.item.code);
        },
        holidays_click() {
            this.$emit('holidaysClick', this.item.code);
        }
    }
}
</script>

<style lang="less">
.sheetmng-row {
    position: relative;

    td {
        padding: 6px 4px;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        white-space: nowrap;

        &:last-child {
            border-right: 1px solid black;
        }
    }

    .edit {
        cursor: pointer;
        text-align: center;

        .icon {
            width: 14px;
            fill: var(--foreColor);
        }
    }

    .startDay {
        text-align: right;
    }

    .holidays {
        text-align: center;

        button {
            padding: 0 8px;
        }
    }
}
.sheetmng-row.enabled {
    background-color: #CDCDCD;
}
</style>