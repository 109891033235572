var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "trafficPaymentInfo" }, [
    _c("div", { staticClass: "header_area" }, [
      _c("div", [_vm._v("申請日：" + _vm._s(_vm.date))]),
      _c("div", { staticClass: "icon_area" }, [
        _c(
          "span",
          [
            _c("icon", {
              staticClass: "icon",
              attrs: { name: "ellipsis-v", scale: "0.8" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.menu_popup($event)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "detail_area" }, [
      _c("div", { staticClass: "info_area" }, [
        _c("div", { staticClass: "info_area2" }, [
          _c("div", { staticClass: "payDate" }, [_vm._v(_vm._s(_vm.payDate))]),
          _c("div", { staticClass: "destination_area" }, [
            _vm._v(_vm._s(_vm.destination)),
          ]),
        ]),
        _c("div", { staticClass: "fromto_area" }, [
          _c("div", { staticClass: "fromto" }, [_vm._v(_vm._s(_vm.from))]),
          _c("div", [_vm._v("〜")]),
          _c("div", { staticClass: "fromto" }, [_vm._v(_vm._s(_vm.to))]),
        ]),
      ]),
      _c("div", { staticClass: "money_area" }, [
        _vm._v(_vm._s(_vm.getAmount(_vm.money))),
        _c("span", { staticClass: "en" }, [_vm._v("円")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }