<template>
    <div class="sheetreki-cell">
        <div class="edit" @click.stop="edit_click"><icon name="pen" class="icon"></icon></div>
        <div>{{item.code}}</div>
        <div>{{item.name}}</div>
        <div class="startDay">開始日：{{item.startDate}}</div>
        <div class="endDay">終了日：{{item.endDate}}</div>
    </div>
</template>

<script>
export default {
    props: {
        item: null
    },
    methods: {
        edit_click() {
            this.$emit('editClick', this.item.code, this.item.startDate);
        }
    }
}
</script>

<style lang="less">
.sheetreki-cell {
    display: grid;
    grid-template-columns: 36px auto auto 180px 180px;
    border-radius: 8px;
    background-color: #fff;
    color: #000;
    padding: 22px 22px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 22px;

    .edit {
        cursor: pointer;

        .icon {
            width: 14px;
            fill: #000;
        }
    }
}
</style>