var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "recordPg" } }, [
    _c("div", { staticClass: "main_area" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "leftSpace" }, [
          _c("div", { staticClass: "yearMonth" }, [
            _c(
              "span",
              {
                staticClass: "btnMoveMonthPre",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.mvMonth(-1)
                  },
                },
              },
              [_vm._v("＜")]
            ),
            _c("span", { staticClass: "year" }, [
              _vm._v(_vm._s(_vm.activeYear) + "年"),
            ]),
            _c("div", { staticClass: "month" }, [
              _vm._v(_vm._s(_vm.activeMonth) + "月分"),
            ]),
            _c(
              "span",
              {
                staticClass: "btnMoveMonthNext",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.mvMonth(1)
                  },
                },
              },
              [_vm._v("＞")]
            ),
          ]),
          _c("div", { staticClass: "company" }, [
            _vm._v("株式会社ビーブリッヂ"),
          ]),
        ]),
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.headerTitle))]),
        _c("div", { staticClass: "rightSpace" }, [
          _c("div", { staticClass: "sheet" }, [
            _vm.sheets.length
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.activeSheet,
                        expression: "activeSheet",
                      },
                    ],
                    staticClass: "sheetItem",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.activeSheet = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.changeSheet()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.sheets, function (data, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: data.code } },
                      [_vm._v(_vm._s(data.name))]
                    )
                  }),
                  0
                )
              : _c("pre", [_vm._v(" ")]),
          ]),
          _c("div", { staticClass: "select_user" }, [
            _vm.chkManager
              ? _c(
                  "span",
                  {
                    staticClass: "btnMoveUserPre",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.mvUser(-1)
                      },
                    },
                  },
                  [_vm._v("＜")]
                )
              : _vm._e(),
            _c("div", { staticClass: "name" }, [
              _vm._v("氏名：" + _vm._s(_vm.userName)),
            ]),
            _vm.chkManager
              ? _c(
                  "span",
                  {
                    staticClass: "btnMoveUserNext",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.mvUser(1)
                      },
                    },
                  },
                  [_vm._v("＞")]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _c(
        "table",
        { staticClass: "list" },
        [
          _c("tr", { staticClass: "list-header" }, [
            _vm._m(0),
            _vm._m(1),
            _c("td", { staticClass: "start", attrs: { rowspan: "3" } }, [
              _vm._v("始業時刻"),
            ]),
            _c("td", { staticClass: "end", attrs: { rowspan: "3" } }, [
              _vm._v("終了時刻"),
            ]),
            _c(
              "td",
              { staticClass: "times", attrs: { colspan: "4", rowspan: "2" } },
              [_vm._v("労働時間")]
            ),
            _c("td", { staticClass: "breakTime", attrs: { rowspan: "3" } }, [
              _vm._v("休憩"),
            ]),
            _vm._m(2),
            _vm.opcell1
              ? _c("td", { staticClass: "covid19", attrs: { rowspan: "3" } }, [
                  _vm._v(_vm._s(_vm.opcell1Nm)),
                ])
              : _vm._e(),
            _c("td", { staticClass: "comment", attrs: { rowspan: "3" } }, [
              _vm._v("備考"),
            ]),
          ]),
          _c("tr", { staticClass: "list-header" }),
          _vm._m(3),
          _vm._l(_vm.items, function (data) {
            return _c("recData", {
              key: data.fullDate,
              attrs: {
                item: data,
                opcell1: _vm.opcell1,
                locked: _vm.lockedFlg(data.fullDate),
              },
              on: { editField: _vm.editField },
            })
          }),
          _c("tr", { staticClass: "sumrow" }, [
            _c(
              "td",
              { staticClass: "title fixedfield", attrs: { colspan: "4" } },
              [_vm._v("合計")]
            ),
            _c("td", { staticClass: "times" }, [
              _vm._v(_vm._s(_vm.times(_vm.syukei15))),
            ]),
            _c("td", { staticClass: "times" }, [
              _vm._v(_vm._s(_vm.times(_vm.syukei9))),
            ]),
            _c("td", { staticClass: "times" }, [
              _vm._v(_vm._s(_vm.times(_vm.syukei10))),
            ]),
            _c("td", { staticClass: "times" }, [
              _vm._v(_vm._s(_vm.times(_vm.syukei11))),
            ]),
            _c("td", { staticClass: "times" }, [
              _vm._v(_vm._s(_vm.times(_vm.syukei16))),
            ]),
            _c("td", { staticClass: "times" }, [
              _vm._v(_vm._s(_vm.times(_vm.syukei17))),
            ]),
            _vm.opcell1
              ? _c("td", { staticClass: "times" }, [
                  _vm._v(_vm._s(_vm.times(_vm.syukei18))),
                ])
              : _vm._e(),
            _c("td", { staticClass: "fixedfield" }, [_vm._v(" ")]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editTimeFieldFlg,
                  expression: "editTimeFieldFlg",
                },
              ],
              ref: "edit_time",
              staticClass: "edit_field time",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.empty.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.valTime,
                    expression: "valTime",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.valTime },
                on: {
                  keydown: _vm.time_keydown,
                  keyup: _vm.time_keyup,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.valTime = $event.target.value
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editTimesFieldFlg,
                  expression: "editTimesFieldFlg",
                },
              ],
              ref: "edit_times",
              staticClass: "edit_field times",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.empty.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.valTimes,
                    expression: "valTimes",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.valTimes },
                on: {
                  keydown: _vm.times_keydown,
                  keyup: _vm.times_keyup,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.valTimes = $event.target.value
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editStrFieldFlg,
                  expression: "editStrFieldFlg",
                },
              ],
              ref: "edit_str",
              staticClass: "edit_field str",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.empty.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.valStr,
                    expression: "valStr",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.valStr },
                on: {
                  keydown: _vm.str_keydown,
                  keyup: _vm.str_keyup,
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.str_keypress.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.valStr = $event.target.value
                  },
                },
              }),
            ]
          ),
        ],
        2
      ),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "footer-area tbl" }, [
          _c("div", { staticClass: "tblrow" }, [
            _c(
              "div",
              { staticClass: "tblcell" },
              [
                _c("recSummary", {
                  attrs: {
                    opcell1: _vm.opcell1,
                    syoteiDays: _vm.syukei1,
                    syukinDays: _vm.syukei2,
                    kekinDays: _vm.syukei3,
                    yukyuDays: _vm.syukei4,
                    kyujitsuDays: _vm.syukei5,
                    houteigaiDays: _vm.syukei19,
                    tokuDays: _vm.syukei6,
                    tikokuCnt: _vm.syukei7,
                    yukyuSyokaDays: _vm.syukei13,
                    kyugyoDays: _vm.syukei8,
                    jikangaiTimes: _vm.syukei9,
                    sinyaTimes: _vm.syukei10,
                    kyujitsuTimes: _vm.syukei11,
                    houteigaiTimes: _vm.syukei20,
                    comment: _vm.comment,
                    yukyuZanDays: _vm.syukei14,
                    kyugyoTimes: _vm.syukei18,
                    opsyukei8Nm: _vm.opsyukei8Nm,
                    opsyukei12Nm: _vm.opsyukei12Nm,
                    hougaiFlag: _vm.hougaiFlag,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "stamp-area tblcell" }, [
              _c(
                "button",
                {
                  staticClass: "pdf_btn",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.pdfDownload()
                    },
                  },
                },
                [_vm._v("PDFでダウンロード")]
              ),
              _c("table", { staticClass: "stamp-list" }, [
                _c("tr", { staticClass: "fixheader" }, [
                  _c(
                    "td",
                    { staticClass: "syoninBtn", attrs: { rowspan: "2" } },
                    [
                      _vm.chkSyoninFlg
                        ? _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.syonin(false)
                                },
                              },
                            },
                            [_vm._v("承認取消")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("td", [_vm._v("所属長")]),
                  _c("td", [_vm._v("本人")]),
                ]),
                _c("tr", { staticClass: "cell" }, [
                  _c("td", { staticClass: "stamp" }, [
                    !_vm.chkSyonin
                      ? _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.syonin(true)
                              },
                            },
                          },
                          [_vm._v("承認")]
                        )
                      : _vm._e(),
                    !_vm.chkSyonin
                      ? _c(
                          "button",
                          {
                            staticClass: "sashimodoshi",
                            on: {
                              click: function ($event) {
                                return _vm.sashimodoshi(false)
                              },
                            },
                          },
                          [_vm._v("差戻")]
                        )
                      : _vm._e(),
                    _vm.syoninFlg
                      ? _c(
                          "div",
                          {
                            ref: "inei1",
                            staticClass: "inei",
                            attrs: {
                              "data-name1": _vm.myouji1(_vm.syoninName),
                              "data-name2": _vm.myouji2(_vm.syoninName),
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "date",
                              attrs: { "data-date": _vm.syoninDate },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("td", { staticClass: "stamp" }, [
                    !_vm.chkKakutei
                      ? _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.kakutei(true)
                              },
                            },
                          },
                          [_vm._v("確定")]
                        )
                      : _vm._e(),
                    _vm.kakuteiFlg
                      ? _c(
                          "div",
                          {
                            ref: "inei1",
                            staticClass: "inei",
                            attrs: {
                              "data-name1": _vm.myouji1(_vm.userName),
                              "data-name2": _vm.myouji2(_vm.userName),
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "date",
                              attrs: { "data-date": _vm.kakuteiDate },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "date", attrs: { rowspan: "3" } }, [
      _vm._v("日"),
      _c("br"),
      _vm._v("付"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "day", attrs: { rowspan: "3" } }, [
      _vm._v("曜"),
      _c("br"),
      _vm._v("日"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "extime", attrs: { rowspan: "3" } }, [
      _vm._v("遅刻"),
      _c("br"),
      _vm._v("早退"),
      _c("br"),
      _vm._v("時間"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "list-header subheader" }, [
      _c("td", { staticClass: "time1" }, [_vm._v("所定内")]),
      _c("td", { staticClass: "time2" }, [_vm._v("時間外")]),
      _c("td", { staticClass: "time3" }, [_vm._v("深夜")]),
      _c("td", { staticClass: "time4" }, [_vm._v("休日")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }