var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "ui-datetime-org" }, [
    _c("input", {
      class: _vm.className,
      attrs: {
        type: "date",
        name: _vm.name,
        readonly: _vm.readonly,
        autocomplete: _vm.autocomplete,
        max: _vm.max,
        min: _vm.min,
        step: _vm.step,
        required: _vm.required,
        list: _vm.list,
      },
      domProps: { value: _vm.value },
      on: { change: _vm.change, input: _vm.input },
    }),
    _c(
      "select",
      {
        staticClass: "select_hours",
        attrs: { readonly: _vm.readonly, required: _vm.required },
        domProps: { value: _vm.hour },
        on: { change: _vm.change, input: _vm.input },
      },
      _vm._l(_vm.hours, function (h, i) {
        return _c("option", { key: i }, [_vm._v(_vm._s(h))])
      }),
      0
    ),
    _vm._v(": "),
    _c(
      "select",
      {
        staticClass: "select_minutes",
        attrs: { readonly: _vm.readonly, required: _vm.required },
        domProps: { value: _vm.minut },
        on: { change: _vm.change, input: _vm.input },
      },
      _vm._l(_vm.minutes, function (m, i) {
        return _c("option", { key: i }, [_vm._v(_vm._s(m))])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }