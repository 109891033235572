<template>
    <div id="substituteHolidayPg">
        <div class="info_area">
            <div></div>
            <div><div class="label">{{todate}}</div>から<div class="label">{{fromdate}}</div>へ<div class="savebtn" @click.stop="savebtn_click()">登録</div></div>
        </div>
        <div ref="main" class="main_area" @scroll.stop="main_scroll($event)">
            <div class="calendar_area">
                <div ref="calendarTable" class="tbl">
                    <div class="tblrow" v-for="(data) in items" :key="data.fullDate">
                        <div class="date tblcell">{{getDate(data)}}</div>
                        <div class="day tblcell" :class="{'saturday':isSataurday(data), 'holiday':isHoliday(data)}">{{getDay(data)}}</div>
                        <div class="comment tblcell" @click.stop="click_comment(data)">{{getComment(data)}}</div>
                        <div class="del tblcell"><div class="trash" v-if="delIcon(data)" @click.stop="delete_click(data)"><icon name="trash-alt" scale="0.8" class="icon"></icon></div></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer"></div>
    </div>
</template>

<script>
import Vue from 'vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "substituteHolidayPg",
    mixins: [utilityMixin],
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        },
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            accountId: "",
            activeAccountId: "",
            items: [],
            todate: "",
            fromdate: "",
            targetdate: "",
            stopScrollEvent: false,
            differencePos: 0
        }
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", "振替休日");
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            let now = new Date();
            this.targetdate = now.toChar("yyyy-MM-dd");
            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                this.items = [];
                
                let param = {
					accountId: this.activeAccountId,
                    targetdate: this.targetdate
                };
                let ress = await Vue.$cache.post('substitute/calendar/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.stopScrollEvent = true;
                        this.items = ress.data.data.list;
                        this.$nextTick(() => {
                            this.calendarTopMiddle();
                        })
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async saveSubstituteHoliday() {
            try {
                let param = {
					accountId: this.activeAccountId,
                    todate: this.todate,
                    fromdate: this.fromdate
                };
                let ress = await Vue.$cache.post('substitute/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.stopScrollEvent = false;
                        this.setMiddleDate();
                        await this.getData();
                        this.todate = "";
                        this.fromdate = "";
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async deleteSubstituteHoliday(data) {
            try {
                let param = {
					accountId: this.activeAccountId,
                    targetdate: data.fullDate
                };
                let ress = await Vue.$cache.post('substitute/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.stopScrollEvent = false;
                        this.setMiddleDate();
                        await this.getData();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        click_comment(data) {
            try {
                let date = new Date(data.fullDate);
                if (data.day == 6 || data.day == 0 || data.holiday) {
                    if (this.todate == date.toChar("yyyy-MM-dd")) {
                        this.todate = "";
                        this.fromdate = "";
                        return;
                    } else {
                        this.todate = date.toChar("yyyy-MM-dd");
                    }
                    if (data.to != undefined && data.to != "") {
                        this.fromdate = data.to;
                    } else if (data.comment != undefined && data.comment != "") {
                        let i = data.comment.indexOf("#4 ");
                        if (i > -1) {
                            let wdats = data.comment.match(/(\d+)月(\d+)日/);
                            let now = new Date();
                            let wdate = new Date(now.getFullYear(), (wdats[1] - 1), wdats[2]);
                            this.fromdate = wdate.toChar("yyyy-MM-dd");
                        }
                    }
                } else {
                    if (this.fromdate == date.toChar("yyyy-MM-dd")) {
                        this.fromdate = "";
                        this.todate = "";
                        return;
                    } else {
                        this.fromdate = date.toChar("yyyy-MM-dd");
                    }
                    if (data.from != undefined && data.from != "") {
                        this.todate = data.from;
                    } else if (data.comment != undefined && data.comment != "") {
                        let i = data.comment.indexOf("#4 ");
                        if (i > -1) {
                            let wdats = data.comment.match(/(\d+)月(\d+)日/);
                            let now = new Date();
                            let wdate = new Date(now.getFullYear(), (wdats[1] - 1), wdats[2]);
                            this.todate = wdate.toChar("yyyy-MM-dd");
                        }
                    }
                }
            } catch(error) {
                console.error(error);
            }
        },
        savebtn_click() {
            try {
                this.saveSubstituteHoliday();
            } catch(error) {
                console.error(error);
            }
        },
        delete_click(data) {
            try {
                this.deleteSubstituteHoliday(data);
            } catch(error) {
                console.error(error);
            }
        },
        main_scroll(e) {
            if (this.stopScrollEvent) {
                return false;
            }

            let h = e.target.scrollHeight;
            let ch = e.target.clientHeight;
            let margin = 80;
            if ((e.target.scrollTop < margin) || (e.target.scrollTop > (h - ch - margin))) {
                this.stopScrollEvent = false;
                this.setMiddleDate();
                this.getData();
            }
        },
        calendarTopMiddle() {
            let main = this.$refs.main;
            main.scrollTop = ((main.scrollHeight - main.clientHeight) / 2) + this.differencePos;
            setTimeout(() => {
                this.stopScrollEvent = false;
            }, 10);
        },
        setMiddleDate() {
            let main = this.$refs.main;
            let height = main.clientHeight;
            let h = main.scrollTop + (height / 2);

            let tbl = this.$refs.calendarTable;
            let col = tbl.getElementsByClassName("tblcell")[0];
            if (col == undefined) return;
            
            let ch = Math.floor(tbl.clientHeight / this.items.length);
            this.differencePos = h % ch;
            let rows = Math.floor(h / ch);
            
            this.targetdate = this.items[rows - 1].fullDate;
        },
        getDate(data) {
            let date = new Date(data.fullDate);
            return date.toChar('MM/dd');
        },
        getDay(data) {
            let date = new Date(data.fullDate);
            return date.jaDayName();
        },
        getComment(data) {
            let result = "";
            if (data.to != undefined && data.to != "") {
                let date = new Date(data.to);
                result = "振休 (%1へ)".replace("%1", date.toChar("M月d日"))
            } else if (data.from != undefined && data.from != "") {
                let date = new Date(data.from);
                result = "振休 (%1分)".replace("%1", date.toChar("M月d日"))
            }
            if (data.comment != undefined && data.comment != "") {
                let i = data.comment.indexOf("#4 ");
                let dat = "";
                if (i > -1) {
                    if ((data.to != undefined && data.to != "") || (data.from != undefined && data.from != "")) {
                        dat = data.comment.substring(i+3);
                        let p = dat.indexOf(" ");
                        if (p > -1) {
                            dat = dat.substring(p+1);
                        }
                    } else {
                        dat = "振休 "+data.comment.substring(i+3);
                    }
                } else {
                    dat = data.comment;
                }
                result = (result != "") ? " "+dat : dat;
                result = Vue.$utils.convertComment(result);
            }

            return result;
        },
        isSataurday(data) {
            let res = (data.day == 6) ? true : false;
            if (res) {
                res = (data.holiday) ? false : true;
            }
            return res;
        },
        isHoliday(data) {
            let res = (data.day == 0) ? true : false;
            if (!res) {
                res = (data.holiday) ? true : false;
            }
            return res
        },
        delIcon(data) {
            let result = false;

            if ((data.day == 6 || data.day == 0 || data.holiday)) {
                if (data.to != "" && data.to != undefined) {
                    result = true;
                }
            }

            return result;
        },
        base_click() {
            //--
        },
    }
}
</script>

<style lang="less">
#substituteHolidayPg {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 120px auto 28px;

    .info_area {
        display: grid;
        grid-template-rows: 78px 42px;
        border-bottom: 1px solid black;
        text-align: center;
        width: 100%;
        background-color: var(--backColor);

        .label {
            display: inline-block;
            width: 120px;
            padding: 3px 0;
        }

        .savebtn {
            display: inline-block;
            padding: 3px 10px;
            border-radius: 5px;
            border: 1px solid black;
            margin-left: 16px;
            cursor: pointer;
        }
    }

    .main_area {
        width: 100%;
        overflow: auto;
        padding: 0 12%;
        
        .calendar_area {
            .tbl {
                width: 100%;

                .tblcell {
                    padding: 6px 4px;
                    border-bottom: 1px solid black;
                    border-left: 1px solid black;

                    &:last-child {
                        border-right: 1px solid black;
                    }
                }

                .date {
                    width: 60px;
                    text-align: center;
                    vertical-align: middle;
                }

                .day {
                    width: 28px;
                    text-align: center;
                    vertical-align: middle;
                }

                .comment {
                    vertical-align: middle;
                }

                .del {
                    width: 28px;
                    text-align: center;
                    vertical-align: middle;

                    .trash {
                        cursor: pointer;
                        background-color: #f33;
                        padding: 5px 7px;

                        .icon {
                            width: 14px;
                            fill: #fff;
                        }
                    }
                }

                .saturday {
                    background-color: var(--saturdayBackColor);
                    color: var(--saturdayForeColor);
                }

                .holiday {
                    background-color: var(--holidayBackColor);
                    color: var(--holidayForeColor);
                }
            }
        }
    }
}
</style>