import jpCalendar from '@/utils/japaneseCalendar.js';

// 指定フォーマットでの文字列化
window.Date.prototype.toChar = function(format) {
	if ((format == undefined) || (format == "")) {
		format = "yyyy/MM/dd HH:mm:ss";
	}
	format = format.replace(/yyyy/g, this.getFullYear());
	if ((format.indexOf("G")) || (format.indexOf("g"))) {
		format = format.replace(/yy/g, ("0" + this.getWarekiYear()).slice(-2));
	} else {
		format = format.replace(/yy/g, (this.getFullYear() + "").slice(-2));
	}
	format = format.replace(/y/g, this.getWarekiYear());
	format = format.replace(/MM/g, ('0' + (this.getMonth() + 1)).slice(-2));
	format = format.replace(/M/g, (this.getMonth() + 1));
	format = format.replace(/dd/g, ('0' + this.getDate()).slice(-2));
	format = format.replace(/d/g, (this.getDate()));
	format = format.replace(/HH/g, ('0' + this.getHours()).slice(-2));
	format = format.replace(/H/g, (this.getHours()));
	format = format.replace(/hh/g, ('0' + (this.getHours() % 12)).slice(-2));
	format = format.replace(/h/g, (this.getHours() % 12));
	format = format.replace(/mm/g, ('0' + this.getMinutes()).slice(-2));
	format = format.replace(/m/g, (this.getMinutes()));
	format = format.replace(/ss/g, ('0' + this.getSeconds()).slice(-2));
	format = format.replace(/s/g, (this.getSeconds()));
	format = format.replace(/SSS/g, ('00' + this.getMilliseconds()).slice(-3));
	format = format.replace(/SS/g, ('0' + this.getMilliseconds()).slice(-2));
	format = format.replace(/S/g, (this.getMilliseconds()));
	format = format.replace(/F/g, (this.getDay()));
	format = format.replace(/A/g, ((this.getHours() < 12) ? '午前' : '午後'));
	format = format.replace(/aa/g, ((this.getHours() < 12) ? 'am' : 'pm'));
	format = format.replace(/a/g, ((this.getHours() < 12) ? 'a' : 'p'));
	format = format.replace(/EEE/g, (this.jaDayName(1)));
	format = format.replace(/EE/g, (this.jaDayName(1)));
	format = format.replace(/E/g, (this.jaDayName()));
	format = format.replace(/eee/g, (this.enDayName(2)));
	format = format.replace(/ee/g, (this.enDayName(1)));
	format = format.replace(/e/g, (this.enDayName()));
	format = format.replace(/GG/g, (this.jaWareki(2)));
	format = format.replace(/G/g, (this.jaWareki(1)));
	format = format.replace(/g/g, (this.jaWareki()));
	if ((format.substr(0, 2) == "Np") || (format.substr(0, 2) == "pN")) {
		format = "";
	}
	return format;
};
// 日本語曜日名
window.Date.prototype.jaDayName = function(mode) {
	let result = "";
	result = jpCalendar.dayNames[this.getDay()];
	if (mode === 1) {
		result = result + "曜日";
	}
	return result;
};
// 英語曜日名
window.Date.prototype.enDayName = function(mode) {
	let result = "";
	let list = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
	let name = list[this.getDay()];
	if (name == undefined) {
		return "";
	}
	if (mode === 2) {
		result = name;
	} else if (mode === 1) {
		result = name.substr(0, 3);
	} else {
		result = name.substr(0, 1);
	}
	return result;
};
// 英語の月名を取得
window.Date.prototype.enMonth = function(mode) {
	let list = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	let result = list[this.getMonth()];
	if (mode === 1) {
		// No Step
	} else {
		result = result.substr(0, 3);
	}
	return result;
};
window.Date.prototype.getWarekiYear = function() {
	let y = jpCalendar.getWarekiYear(this);
	return y;
};
// 和暦変換
window.Date.prototype.jaWareki = function(mode) {
	let result = "";
	let data = jpCalendar.getNengoDataFromDate(this);
	if (mode === 2) {
		result = data.name;
	} else if (mode === 1) {
		result = data.name.substr(0, 1);
	} else {
		result = data.alphabet;
	}
	return result;
};
// 祝日の判定
window.Date.prototype.isHoliday = function() {
	let holidays = jpCalendar.getHolidaysByYear(this.getFullYear());
	let _self = this;
	let res = holidays.find(function(v){
		return v.date == _self.toChar("yyyy/MM/dd");
	})
	return res;
};
// 指定した和暦で年を変換
window.Date.prototype.warekiToSeireki = function(gg) {
	let data = gg.match(/^(\D+)(\d+)$/);
	if (data.length < 3) {
		return;
	}
	let year = jpCalendar.warekiToSeireki(data[1], data[2]);
	this.setFullYear(year);
	return;
};
// 月末日の取得
window.Date.prototype.getLastDate = function() {
	let date = new Date(this.getFullYear(), this.getMonth() + 1, 0);
	return date.getDate();
};
// 指定年の第X週Y曜日の日付を取得
window.Date.prototype.getDateByDayNum = function(y, m, s, n) {
	let date = new Date(y, (m - 1), 1);
	let dd = date.getDay();
	let f = ((n - dd) < 0) ? (n - dd + 7) : (n - dd);
	date = new Date(y, (m - 1), (1 + f));
	if (s > 1) {
		let wdate = new Date(y, (m - 1), date.getDate());
		wdate.setTime(wdate.getTime() + ((7 * (s - 1)) * 24 * 3600 * 1000));
		date = wdate;
	}
	if (date.getMonth() != (m - 1)) {
		return null;
	}
	return date;
};
// 指定年数分を増減
window.Date.prototype.addYear = function(n) {
	if ((n == undefined) || (n == "")) {
		n = 0;
	}
	this.setFullYear(this.getFullYear() + n);
	return;
};
// 指定月数分を増減
window.Date.prototype.addMonth = function(n) {
	if ((n == undefined) || (n == "")) {
		n = 1;
	}
	this.setMonth(this.getMonth() + n);
};
// 指定日数分を増減
window.Date.prototype.addDate = function(n) {
	if ((n == undefined) || (n == "")) {
		n = 0;
	}
	this.setDate(this.getDate() + n);
};
// 指定時間分を増減
window.Date.prototype.addDHours = function(n) {
	if ((n == undefined) || (n == "")) {
		n = 0;
	}
	this.setHours(this.getHours() + n);
};
// 指定分数分を増減
window.Date.prototype.addDMinutes = function(n) {
	if ((n == undefined) || (n == "")) {
		n = 0;
	}
	this.setMinutes(this.getMinutes() + n);
};
// 指定秒数分を増減
window.Date.prototype.addSeconds = function(n) {
	if ((n == undefined) || (n == "")) {
		n = 0;
	}
	this.setSeconds(this.getSeconds() + n);
};
window.Date.prototype.convertWarekiToSeireki = function(date) {
	let g = "";
	let y = "";
	let w = "";
	let n = 0;
	for (let p = 0; p < date.length; p++) {
		let d = date.charAt(p);
		if (d.match(/^[0-9]$/)) {
			if (n == 0) {
				n = 1;
			}
		} else {
			if (n == 1) {
				n = 2;
			}
		}
		if (n == 0) {
			g = g + d;
		} else if (n ==1) {
			y = y + "" + d;
		} else {
			if (!d.match(/^[0-9]$/)) {
				if (d.match(/^\s$/)) {
					continue;
				}
				d = "-";
			}
			w = w + "" + d;
		}
	}
	if (w.slice(-1) == "-") {
		w = w.substr(0, w.length- 1);
	}
	let year = jpCalendar.warekiToSeireki(g, y);
	let datex = year + "" + w;
	let o = new Date(datex);

	return o;
};
