var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "applyTrafficPayment" },
      on: {
        scroll: function ($event) {
          $event.stopPropagation()
          return _vm.list_scroll($event)
        },
      },
    },
    [
      _vm.list.length == 0
        ? _c("div", { staticClass: "empty_area" }, [
            _vm._v("表示できる交通費申請はありません"),
          ])
        : _vm._e(),
      _vm.list.length > 0
        ? _c(
            "div",
            { ref: "list", staticClass: "list_area" },
            [
              _vm._l(_vm.list, function (dat, ix) {
                return _c("info", {
                  key: ix,
                  staticClass: "info",
                  attrs: {
                    id: dat.id,
                    date: dat.date,
                    payDate: dat.payDate,
                    destination: dat.destination,
                    facilities: dat.facilities,
                    from: dat.from,
                    to: dat.to,
                    money: dat.total,
                  },
                  on: {
                    menu_popup: function ($event) {
                      return _vm.menu_popup($event, ix)
                    },
                  },
                })
              }),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.menuFlag,
                      expression: "menuFlag",
                    },
                  ],
                  ref: "menu",
                  staticClass: "menu_area",
                },
                [
                  _c("li", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onEdit()
                          },
                        },
                      },
                      [_vm._v("編集")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onDelete()
                          },
                        },
                      },
                      [_vm._v("削除")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onCopy()
                          },
                        },
                      },
                      [_vm._v("コピーして新規作成")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onDownloadPDF()
                          },
                        },
                      },
                      [_vm._v("PDFでダウンロード")]
                    ),
                  ]),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "backBtn",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.backPage()
            },
          },
        },
        [
          _c("icon", { staticClass: "icon", attrs: { name: "chevron-left" } }),
          _vm._v(" 戻る"),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "newCreateBtn",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.createApply.apply(null, arguments)
            },
          },
        },
        [_vm._v("+")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }