<template>
    <div id="sheetMngPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <button class="addBtn" @click.stop="addNew">新規作成</button>
                <form class="search_form" @submit.prevent="getData">
                    <div class="search_area">
                        <svg class="search-icon" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                        </svg>
                        <input type="text" v-model="searchVal" placeholder="シートの検索"/>
                    </div>
                </form>
            </div>
            <!-- 表 -->
            <table class="list">
                <tr class="list-header">
                    <td class="edit"></td>
                    <td class="employNo">コード</td>
                    <td class="name">シート名</td>
                    <td class="hireDate">開始日</td>
                    <td class="holidays">休業日</td>
                    <td></td>
                </tr>
                <mngData v-for="(data) in items" :key="data.code" :item="data" @editClick="edit_click" @holidaysClick="holidays_click"></mngData>
            </table>
            <!-- フッター -->
            <div class="footer">

            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import mngData  from '@/components/sheetData.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "sheetMngPg",
    mixins: [utilityMixin],
    components: {
        'mngData': mngData
    },
    data() {
        return {
            items: [],
            searchVal: ""
        }
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", "シート管理");
            this.$store.dispatch("operator_baseClick", "");

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                this.items = [];
                
                let param = {
					search: this.searchVal
                };
                let ress = await Vue.$cache.post('record/sheetmng/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.items = ress.data.data.list;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        addNew() {
            this.$store.commit("operator_routeParam", {edited: '1'});
            this.$router.push('/kintai/sheet/@', () => {});
        },
        edit_click(code) {
            this.$store.commit("operator_routeParam", {edited: '1'});
            this.$router.push('/kintai/sheet/'+code, () => {});
        },
        holidays_click(code) {
            this.$router.push('/kintai/holidays/'+code, () => {});
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#sheetMngPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .header {
            height: 42px;
            
            .addBtn {
                width: 160px;
            }

            .search_form {
                margin: 0;
                display: inline;
            }

            .search_area {
                position: relative;
                width: 280px;
                float: right;
                border: 1px solid #000;
                border-radius: 17px;
                padding: 6px 12px 6px 30px;

                .search-icon {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 6px;
                    margin: auto;
                    fill: #777777;
                }
                
                input[type=text] {
                    width: 100%;
                    border: 0;

                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
            }
        }

        .list {
            width: 100%;
            border-spacing: 0;

            .list-header {
                .edit {
                    width: 36px;
                }
                .employNo {
                    width: 80px;
                }
                .name {
                    width: 220px;
                }
                .kengen {
                    width: 120px;
                }
                .hireDate {
                    width: 68px;
                }
                .holidays {
                    width: 76px;
                }
            }

            .list-header {
                background-color: var(--gridFixedBackColor);
                font-weight: bold;
                color: white;

                td {
                    border-bottom: 1px solid white;
                    border-bottom-color: var(--gridFixedBorderColor);
                    border-left: 1px solid white;
                    border-left-color: var(--gridFixedBorderColor);
                    padding: 6px 4px;
                    text-align: center;
                    white-space: nowrap;

                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:1027px) {
    #sheetMngPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;

            .header {
                width: 1028px;
            }

            .list {
                width: 1028px;
            }

            .footer {
                width: 1028px;
            }
        }
    }
}
</style>