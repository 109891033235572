<template>
    <tr class="record">
        <td class="date">{{item.date}}</td>
        <td class="day" :class="{'saturday':isSataurday(), 'holiday':isHoliday()}">{{getDay()}}</td>
        <td class="start" @click="editField($event, 'start', 'time')">{{time(item.start)}}</td>
        <td class="end" @click="editField($event, 'end', 'time')">{{time(item.end)}}</td>
        <td class="time1" @click="editField($event, 'time1', 'times')">{{times(item.time1)}}</td>
        <td class="time2" @click="editField($event, 'time2', 'times')">{{times(item.time2)}}</td>
        <td class="time3" @click="editField($event, 'time3', 'times')">{{times(item.time3)}}</td>
        <td class="time4" @click="editField($event, 'time4', 'times')">{{times(item.time4)}}</td>
        <td class="breakTime" @click="editField($event, 'breakTime', 'times')">{{times(item.breakTime)}}</td>
        <td class="extime" @click="editField($event, 'extime', 'times')">{{times(item.extime)}}</td>
        <td v-if="opcell1" class="covid19" @click="editField($event, 'covid19', 'times')">{{times(item.jitan)}}</td>
        <td class="comment" @click="editField($event, 'comment', 'string')">{{comment(item.comment)}}</td>
    </tr>
</template>

<script>
import Vue from 'vue';
export default {
    name: 'record',
    props: {
        item: null,
        opcell1: {type:Boolean, default:false},
        locked: {type:Boolean, default:false}
    },
    data() {
        return {
            editFieldFlg: false,
            valTime: ""
        };
    },
    mounted() {
		
    },
    methods: {
        isSataurday() {
            let res = (this.item.day == 6) ? true : false;
            if (res) {
                res = (this.item.holiday) ? false : true;
            }
            return res;
        },
        isHoliday() {
            let res = (this.item.day == 0) ? true : false;
            if (!res) {
                res = (this.item.holiday) ? true : false;
            }
            return res
        },
        getDay() {
            let date = new Date(this.item.fullDate);
            return date.jaDayName();
        },
        editField(e, className, type) {
            if (document.getElementById("sideMenu").classList.contains("on_menu")) {
                return false;
            }

            e.stopPropagation();

            if (this.locked) {
                return false;
            }

            let field = className;
            if (className == "covid19") { field = "jitan"; }

            this.$emit('editField', this.item.fullDate, field, e.target, type);

            return false;
        },
        time(val) {
            if ((val == "") || (val == undefined)) {
                val = ":";
            }
            return val;
        },
        times(val) {
            if ((val == "") || (val == undefined) || (val <= 0)) {
                val = ":";
            } else {
                val = this.formatTimes(val);
            }
            return val;
        },
        comment(val) {
            val = Vue.$utils.convertComment(val);
            return val;
        },
        formatTimes(value) {
            value = value * 60;
            let m = value % 60;
            let h = (value - m) / 60;

            m = 100 + m;
            m = (m + "").substr(1,2);
            let result = h + ":" + m;
            
            return result;
        }
    }
}
</script>

<style lang="less">
.record {
    position: relative;

    td {
        padding: 6px 4px;
        border-bottom: 1px solid black;
        border-left: 1px solid black;

        &:last-child {
            border-right: 1px solid black;
        }
    }

    .saturday {
        background-color: var(--saturdayBackColor);
        color: var(--saturdayForeColor);
    }

    .holiday {
        background-color: var(--holidayBackColor);
        color: var(--holidayForeColor);
    }
}
</style>