<template>
    <div id="applyPaymentTop">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'applyPaymentTop'
}
</script>

<style lang="less" scoped>
#applyPaymentTop {
    height: 100%;
    width: 100%;
    padding: 86px 12% 12px;
}

#base_area {
    height: 100%;

    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:500px) {
    #applyPaymentTop {
        padding: 86px 12px 12px;
    }
}
</style>