<template>
    <div id="recordPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <div class="leftSpace">
                    <div class="yearMonth"><span class="btnMoveMonthPre" @click.stop="mvMonth(-1)">＜</span><span class="year">{{activeYear}}年</span><div class="month">{{activeMonth}}月分</div><span class="btnMoveMonthNext" @click.stop="mvMonth(1)">＞</span></div>
                    <div class="company">株式会社ビーブリッヂ</div>
                </div>
                <div class="title">{{headerTitle}}</div>
                <div class="rightSpace">
                    <div class="sheet">
                        <select v-if="sheets.length" class="sheetItem" v-model="activeSheet" @change="changeSheet()">
                            <option v-for="(data, index) in sheets" :key="index" :value="data.code">{{data.name}}</option>
                        </select>
                        <pre v-else> </pre>
                    </div>
                    <div class="select_user"><span v-if="chkManager" class="btnMoveUserPre" @click.stop="mvUser(-1)">＜</span><div class="name">氏名：{{userName}}</div><span v-if="chkManager" class="btnMoveUserNext" @click.stop="mvUser(1)">＞</span></div>
                </div>
            </div>
            <!-- 表 -->
            <table class="list">
                <tr class="list-header">
                    <td class="date" rowspan="3">日<br>付</td>
                    <td class="day" rowspan="3">曜<br>日</td>
                    <td class="start" rowspan="3">始業時刻</td>
                    <td class="end" rowspan="3">終了時刻</td>
                    <td class="times" colspan="4" rowspan="2">労働時間</td>
                    <td class="breakTime" rowspan="3">休憩</td>
                    <td class="extime" rowspan="3">遅刻<br>早退<br>時間</td>
                    <td v-if="opcell1" class="covid19" rowspan="3">{{opcell1Nm}}</td>
                    <td class="comment" rowspan="3">備考</td>
                </tr>
                <tr class="list-header">
                </tr>
                <tr class="list-header subheader">
                    <td class="time1">所定内</td>
                    <td class="time2">時間外</td>
                    <td class="time3">深夜</td>
                    <td class="time4">休日</td>
                </tr>
                <recData v-for="(data) in items" :key="data.fullDate" :item="data" :opcell1="opcell1" :locked="lockedFlg(data.fullDate)" @editField="editField"></recData>
                <tr class="sumrow">
                    <td class="title fixedfield" colspan="4">合計</td>
                    <td class="times">{{times(syukei15)}}</td>
                    <td class="times">{{times(syukei9)}}</td>
                    <td class="times">{{times(syukei10)}}</td>
                    <td class="times">{{times(syukei11)}}</td>
                    <td class="times">{{times(syukei16)}}</td>
                    <td class="times">{{times(syukei17)}}</td>
                    <td v-if="opcell1" class="times">{{times(syukei18)}}</td>
                    <td class="fixedfield">&nbsp;</td>
                </tr>
                <div v-show="editTimeFieldFlg" ref="edit_time" class="edit_field time" @click.stop="empty"><input type="text" v-model="valTime" @keydown="time_keydown" @keyup="time_keyup"/></div>
                <div v-show="editTimesFieldFlg" ref="edit_times" class="edit_field times" @click.stop="empty"><input type="text" v-model="valTimes" @keydown="times_keydown" @keyup="times_keyup"/></div>
                <div v-show="editStrFieldFlg" ref="edit_str" class="edit_field str" @click.stop="empty"><input type="text" v-model="valStr" @keydown="str_keydown" @keyup="str_keyup" @keypress.enter="str_keypress"/></div>
            </table>
            <!-- フッター -->
            <div class="footer">
                <div class="footer-area tbl">
                    <div class="tblrow">
                        <div class="tblcell">
                            <!-- 集計表 -->
                            <recSummary
                                :opcell1 = "opcell1"
                                :syoteiDays = "syukei1"
                                :syukinDays = "syukei2"
                                :kekinDays = "syukei3"
                                :yukyuDays = "syukei4"
                                :kyujitsuDays = "syukei5"
                                :houteigaiDays = "syukei19"
                                :tokuDays = "syukei6"
                                :tikokuCnt = "syukei7"
                                :yukyuSyokaDays = "syukei13"
                                :kyugyoDays = "syukei8"
                                :jikangaiTimes = "syukei9"
                                :sinyaTimes = "syukei10"
                                :kyujitsuTimes = "syukei11"
                                :houteigaiTimes = "syukei20"
                                :comment = "comment"
                                :yukyuZanDays = "syukei14"
                                :kyugyoTimes = "syukei18"
                                :opsyukei8Nm = "opsyukei8Nm"
                                :opsyukei12Nm = "opsyukei12Nm"
                                :hougaiFlag = "hougaiFlag"
                            ></recSummary>
                        </div>
                        <div class="stamp-area tblcell">
                            <button class="pdf_btn" @click.stop="pdfDownload()">PDFでダウンロード</button>
                            <!-- 捺印 -->
                            <table class="stamp-list">
                                <tr class="fixheader">
                                    <td rowspan="2" class="syoninBtn"><button v-if="chkSyoninFlg" @click="syonin(false)">承認取消</button></td>
                                    <td>所属長</td>
                                    <td>本人</td>
                                </tr>
                                <tr class="cell">
                                    <td class="stamp"><button v-if="!chkSyonin" @click="syonin(true)">承認</button><button v-if="!chkSyonin" @click="sashimodoshi(false)" class="sashimodoshi">差戻</button><div v-if="syoninFlg" ref="inei1" class="inei" :data-name1="myouji1(syoninName)" :data-name2="myouji2(syoninName)"><div class="date" :data-date="syoninDate"></div></div></td>
                                    <td class="stamp"><button v-if="!chkKakutei" @click="kakutei(true)">確定</button><div v-if="kakuteiFlg" ref="inei1" class="inei" :data-name1="myouji1(userName)" :data-name2="myouji2(userName)"><div class="date" :data-date="kakuteiDate"></div></div></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import recData  from '@/components/recordData.vue';
import recSummary  from '@/components/recordSummary.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "recordPg",
    mixins: [utilityMixin],
    components: {
        'recData': recData,
        'recSummary': recSummary
    },
    data() {
        return {
            items: [],
            headerTitle: "出勤簿",
            accountId: "",
            activeAccountId: "",
            activeYear: 0,
            activeMonth: 0,
            activeSheet: "",
            userName: "",
            hireDate: "",
            syoninName: "",
            kakuteiDate: "",
            syoninDate: "",
            syukei1: 0,
            syukei2: 0,
            syukei3: 0,
            syukei4: 0,
            syukei5: 0,
            syukei6: 0,
            syukei7: 0,
            syukei8: 0,
            syukei9: 0,
            syukei10: 0,
            syukei11: 0,
            syukei12: 0,
            syukei13: 0,
            syukei14: 0,
            syukei15: 0,
            syukei16: 0,
            syukei17: 0,
            syukei18: 0,
            syukei19: 0,
            syukei20: 0,
            comment: "",
            sheets: [],
            staffList: [],
            opcell1: false,
            opsyukei8: false,
            opsyukei12: false,
            opcell1Nm: "",
            opsyukei8Nm: "",
            opsyukei12Nm: "",
            editFullDate: "",
            editFieldName: "",
            editTimeFieldFlg: false,
            valTime: "",
            editTimesFieldFlg: false,
            valTimes: "",
            editStrFieldFlg: false,
            valStr: "",
            valBackup: "",
            canComment: false,
            kakuteiFlg: false,
            nodataFlg: false,
            syoninFlg: false,
            isProcessing: false,
            pdfFileName: "",
            hougaiFlag: false
        }
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        },
        chkKakutei() {
            let result = false;
            if (!this.isMyRecord()) {
                result = true;
            }
            if ((this.isMyRecord()) && (this.nodataFlg)) {
                result = true;
            }
            if ((this.isMyRecord()) && (this.kakuteiFlg)) {
                result = true;
            }
            return result;
        },
        chkSyonin() {
            let result = false;
            if (!this.kakuteiFlg) {
                result = true;
            }
            if (this.isMyRecord()) {
                result = true;
            }
            if ((!this.isMyRecord()) && (this.syoninFlg)) {
                result = true;
            }
            return result;
        },
        chkSyoninFlg() {
            let result = false;
            let auth = this.$store.state.operator.authority;
            let mng = this.$store.state.operator.manager;
            if ((mng == true) && ((auth == "99") || (auth == "10")) && (this.syoninFlg)) {
                result = true;
            }
            return result;
        },
        chkManager() {
            let result = false;
            let mng = this.$store.state.operator.manager;
            if (mng == true) {
                result = true;
            }
            return result;
        }
    },
    watch: {
        editTimeFieldFlg(nv, ov) {
            if (!nv) {
                if (this.editFullDate != "") {
                    let data = this.items.find(item => item.fullDate === this.editFullDate);
                    Vue.$utils.calcTimeRecords(data);
                    this.saveData(data);
                }
            }
        },
        editTimesFieldFlg(nv, ov) {
            if (!nv) {
                if (this.editFullDate != "") {
                    let data = this.items.find(item => item.fullDate === this.editFullDate);
                    Vue.$utils.calcTimeRecords(data);
                    this.saveData(data);
                }
            }
        },
        editStrFieldFlg(nv, ov) {
            if (!nv) {
                if (this.editFullDate != "") {
                    let data = this.items.find(item => item.fullDate === this.editFullDate);
                    Vue.$utils.checkCommentRecords(data);
                    Vue.$utils.calcTimeRecords(data);
                    this.saveData(data);
                }
            }
        },
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");

            const now = new Date();
            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;
            this.activeYear = this.$store.state.operator.activeYear;
            this.activeMonth = this.$store.state.operator.activeMonth;
            this.activeSheet = this.$store.state.operator.activeSheet;
            
            if (this.activeYear == 0) {
                this.activeYear = now.getFullYear();
            }
            if (this.activeMonth == 0) {
                this.activeMonth = now.getMonth() + 1;
                if (now.getDate() < this.$store.state.operator.baseDate) {
                    --this.activeMonth;
                    if (this.activeMonth < 1) {
                        this.activeMonth = 12;
                        --this.activeYear;
                    }
                }
            }
            if (this.activeSheet == "") {
                this.activeSheet = "default";
            }

            this.$store.commit("operator_activeYear", this.activeYear);
            this.$store.commit("operator_activeMonth", this.activeMonth);
            this.$store.commit("operator_activeSheet", this.activeSheet);

            this.getData();

        });
    },
    methods: {
        async getData() {
            try {
                this.items = [];
                
                let param = {
					accountId: this.activeAccountId,
                    year: this.activeYear,
                    month: this.activeMonth,
                    sheet: this.activeSheet,
                    syoninId: this.accountId
                };
                let ress = await Vue.$cache.post('record/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.userName = ress.data.data.userName;
                        this.sheets = ress.data.data.sheets
                        this.items = ress.data.data.list;
                        this.opcell1 = ress.data.data.opcell1 ? true : false;
                        this.opsyukei8 = ress.data.data.opsyukei8 ? true : false;
                        this.opsyukei12 = ress.data.data.opsyukei12 ? true : false;
                        this.opcell1Nm = ress.data.data.opcell1Nm;
                        this.opsyukei8Nm = ress.data.data.opsyukei8Nm;
                        this.opsyukei12Nm = ress.data.data.opsyukei12Nm;
                        this.setSummary(ress.data.data.summary);
                        this.kakuteiFlg = ress.data.data.kakuteiFlg ? true : false;
                        this.kakuteiDate = ress.data.data.kakuteiDate;
                        this.syoninFlg = ress.data.data.syoninFlg ? true : false;
                        this.syoninDate = ress.data.data.syoninDate;
                        this.syoninName = ress.data.data.syoninName;
                        this.hireDate = ress.data.data.hireDate;
                        this.staffList = ress.data.data.staffList;
                        this.hougaiFlag = (ress.data.data.hougaiFlag == 1) ? true : false;
                        if (this.sheets.length == 0) {
                            this.nodataFlg = true;
                        } else {
                            this.nodataFlg = false;
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async saveData(item) {
            try {
                let param = {
                    accountId: this.activeAccountId,
                    year: this.activeYear,
                    month: this.activeMonth,
                    sheet: this.activeSheet,
                    item: item
                };
                let ress = await Vue.$cache.post('record/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.setSummary(ress.data.data.summary);
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        kakutei(val) {
            if (this.checkKakutei(val)) {
                this.kakuteiEx(val);
            }
        },
        async kakuteiEx(val) {
            try {
                this.kakuteiFlg = val;
                let param = {
                    accountId: this.activeAccountId,
                    year: this.activeYear,
                    month: this.activeMonth,
                    sheet: this.activeSheet,
                    kakutei: (this.kakuteiFlg ? 1 : 0)
                };
                let ress = await Vue.$cache.post('record/kakutei/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        let now = new Date();
                        this.kakuteiDate = now.toChar("yyyy.MM.dd");
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        checkKakutei(val) {
            let result = true;
            
            for (const data of this.items) {
                if ((data.start != "" && data.start != undefined) && (data.end == "" || data.end == undefined)) {
                    alert("終了時刻が不明です");
                    result = false;
                    break;
                }
                if (data.start != "") {
                    let d1 = new Date("2020-01-01 "+data.start+":00");
                    let d2 = new Date("2020-01-01 "+data.end+":00");
                    if (d1.getTime() > d2.getTime()) {
                        alert("始業時刻と終了時刻が不正です");
                        result = false;
                        break;
                    }
                }
                console.log(data);
                if (this.activeSheet == "default") {
                    if (!((data.day == "0") || (data.day == "6") || (data.holiday))) {
                        if ((data.start == "") && (data.end == "") && (!data.yukyu) && (!data.tokukyu)) {
                            if (data.comment == "") {
                                alert("記入が不足しています");
                                result = false;
                                break;
                            } else if ((data.comment.indexOf("#1") < 0) && (data.comment.indexOf("#2") < 0) && (data.comment.indexOf("#3") < 0) && (data.comment.indexOf("#4") < 0)) {
                                alert("記入が不足しています");
                                result = false;
                                break;
                            }
                        }
                    }
                }
            }

            return result;
        },
        async syonin(val) {
            try {
                this.syoninFlg = val;
                let param = {
                    syoninId: this.accountId,
                    accountId: this.activeAccountId,
                    year: this.activeYear,
                    month: this.activeMonth,
                    sheet: this.activeSheet,
                    syonin: (this.syoninFlg ? 1 : 0)
                };
                let ress = await Vue.$cache.post('record/syonin/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        if (this.syoninFlg) {
                            let now = new Date();
                            this.syoninDate = now.toChar("yyyy.MM.dd");
                        } else {
                            this.syoninDate = "";
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        sashimodoshi(val) {
            if (!confirm("差戻しますか？")) {
                return;
            }
            this.sashimodoshiAPI(val);
        },
        async sashimodoshiAPI(val) {
            try {
                this.kakuteiFlg = val;
                let param = {
                    syoninId: this.accountId,
                    accountId: this.activeAccountId,
                    year: this.activeYear,
                    month: this.activeMonth,
                    sheet: this.activeSheet,
                    kakutei: this.kakuteiFlg
                };
                let ress = await Vue.$cache.post('record/kakutei/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.kakuteiDate = "";
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async pdfDownload() {
            try {
                let name = this.userName.replace(/\s+/g, "");
                let shtDt = this.sheets.find(item => item.code === this.activeSheet);
                let shtnm = "";
                if (this.activeSheet != "default") {
                    shtnm = "(" + shtDt.name + ")"
                }
                this.pdfFileName = "出勤簿"+shtnm+"_"+this.activeYear+"年"+this.activeMonth+"月_"+name+".pdf"
                let param = {
                    accountId: this.activeAccountId,
                    year: this.activeYear,
                    month: this.activeMonth,
                    sheet: this.activeSheet
                };

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'blob',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };

                let ress = await Vue.$cache.post('record/pdf/', param, this.$store, request);
                if (ress.status == 200) {
                    const url = URL.createObjectURL(new Blob([ress.data]));

                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', this.pdfFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);

                    /*
                    if (ress.data.status == "ok") {
                        //--
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                    */
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        lockedFlg(date) {
            let result = false;
            if ((this.nodataFlg) || (this.kakuteiFlg)) {
                result = true;
            }
            if (this.hireDate != "") {
                let wdate = date.replace(/\//g, '');
                if (wdate < this.hireDate) {
                    result = true;
                }
            }
            return result;
        },
        isMyRecord() {
            if (this.activeAccountId != this.accountId) {
                return false;
            }
            return true;
        },
        setSummary(summary) {
            this.syukei1 = summary.syoteiDays;
            this.syukei2 = summary.syukinDays;
            this.syukei3 = summary.kekkinDays;
            this.syukei4 = summary.yukyuDays;
            this.syukei5 = summary.kyujitsuDays;
            this.syukei6 = summary.tokukyuDays;
            this.syukei7 = summary.tikokuCnt;
            this.syukei8 = summary.extime2Days;
            this.syukei9 = summary.jikangaiTimes;
            this.syukei10 = summary.sinyaTimes;
            this.syukei11 = summary.kyujitsuTimes;
            this.syukei12 = summary.extime2Times;
            this.syukei13 = summary.yukyuSyokaDays;
            this.syukei14 = summary.yukyuZanCnt;
            this.syukei15 = summary.syoteiTimes;
            this.syukei16 = summary.breakSum;
            this.syukei17 = summary.tikokuSum;
            this.syukei18 = summary.extime2Sum;
            this.syukei19 = summary.houteigaiDays;
            this.syukei20 = summary.houteigaiTimes;
        },
        mvMonth(m) {
            this.activeMonth = this.activeMonth + m;

            if (this.activeMonth > 12) {
                this.activeMonth = 1;
                ++this.activeYear;
            } else if (this.activeMonth < 1) {
                this.activeMonth = 12;
                --this.activeYear;
            }

            this.$store.commit("operator_activeYear", this.activeYear);
            this.$store.commit("operator_activeMonth", this.activeMonth);

            this.getData();
        },
        mvUser(m) {
            let uid = this.activeAccountId;
            let flg = 0
            if (m > 0) {
                for (let p = 0; p < this.staffList.length; p++) {
                    if (this.staffList[p] == uid) {
                        flg = 1;
                        continue;
                    }
                    if (flg) {
                        uid = this.staffList[p];
                        flg = 0;
                        break;
                    }
                }
            } else {
                for (let p = this.staffList.length-1; p > -1; p--) {
                    if (this.staffList[p] == uid) {
                        flg = 1;
                        continue;
                    }
                    if (flg) {
                        uid = this.staffList[p];
                        flg = 0;
                        break;
                    }
                }
            }
            if (flg) {
                if (m > 0) {
                    uid = this.staffList[0];
                } else {
                    uid = this.staffList[this.staffList.length - 1];
                }
            }
            this.activeAccountId = uid;

            this.$store.commit("operator_activeAccountId", uid);

            this.getData();
        },
        changeSheet() {
            this.$store.commit("operator_activeSheet", this.activeSheet);
            this.getData();
        },
        /*
        myouji1(name) {
            if ((name == "") || (name == undefined)) {
                return "";
            }
            let ms = name.split("");
            let res = ms[0];
            if (res.length <= 2) {
                return res.substr(0, 1);
            } else {
                return res.substr(0, 2);
            }
        },
        myouji2(name) {
            if ((name == "") || (name == undefined)) {
                return "";
            }
            let ms = name.split("");
            let res = ms[0];
            if (res.length == 1) {
                return "";
            } else if (res.length == 2) {
                return res.substr(1);
            } else {
                return res.substr(2);
            }
        },
        */
        editField(date, field, target, type) {
            if (((type == "time") && (this.editTimeFieldFlg)) || ((type == "times") && (this.editTimesFieldFlg)) || ((type == "string") && (this.editStrFieldFlg))) {
                if (this.editFullDate != "") {
                    let data = this.items.find(item => item.fullDate === this.editFullDate);
                    if (this.editStrFieldFlg) {
                        Vue.$utils.checkCommentRecords(data);
                    }
                    Vue.$utils.calcTimeRecords(data);
                    this.saveData(data);
                }
            }
            
            this.editFullDate = date;
            this.editFieldName = field;
            let data = this.items.find(item => item.fullDate === date);
            
            let elm = this.$refs.edit_time;
            this.valBackup = data[field];
            if (type == "time") {
                this.valTime = data[field];
            } else if (type == "times") {
                this.valTimes = data[field];
                elm = this.$refs.edit_times;
            } else {
                this.valStr = data[field];
                elm = this.$refs.edit_str;
            }

            let area = document.querySelector(".main_area");
            let rect = target.getBoundingClientRect();
            
            elm.style.width = target.clientWidth + 2 + "px";
            elm.style.height = target.clientHeight + 2 + "px";
            elm.style.top = rect.top + document.documentElement.scrollTop - 1 + "px";
            elm.style.left = rect.left + area.scrollLeft + "px";

            if (type == "time") {
                this.editTimesFieldFlg = false;
                this.editStrFieldFlg = false;
                this.editTimeFieldFlg = true;
                this.valTimes = "";
                this.valStr = "";
            } else if (type == "times") {
                this.editTimeFieldFlg = false;
                this.editStrFieldFlg = false;
                this.editTimesFieldFlg = true;
                this.valTime = "";
                this.valStr = "";
            } else {
                this.editTimeFieldFlg = false;
                this.editTimesFieldFlg = false;
                this.editStrFieldFlg = true;
                this.valTime = "";
                this.valTimes = "";
            }

            setTimeout(function(){
                let inp = elm.getElementsByTagName("input")[0];
                inp.setSelectionRange(0, 10);
                inp.focus();
            }, 100);
        },
        time_keydown(e) {
            if (!((e.keyCode >= 48) && (e.keyCode <= 57)) && !((e.keyCode >= 96) && (e.keyCode <= 105)) && (e.keyCode != 8) && (e.keyCode != 9) && (e.keyCode != 27) && (e.keyCode != 13)) {
                e.preventDefault();
                return false;
            }
        },
        time_keyup(e) {
            if (e.keyCode == 27) {
                this.base_click();
                let data = this.items.find(item => item.fullDate === this.editFullDate);
                data[this.editFieldName] = this.valBackup;
                return;
            } else if ((e.keyCode == 13) || (e.keyCode == 9)) {
                //let data = this.items.find(item => item.fullDate === this.editFullDate);
                this.editTimeFieldFlg = false;
                //Vue.$utils.calcTimeRecords(data);
                //this.saveData(data);
                return;
            }
            
            let data = this.items.find(item => item.fullDate === this.editFullDate);
            
            if (this.editFieldName == "start") {
                if (e.currentTarget.value > 2359) {
                    data[this.editFieldName] = "";
                    return;
                }
            }

            if (this.editFieldName == "end") {
                if (e.currentTarget.value > 4759) {
                    data[this.editFieldName] = "";
                    return;
                }
            }

            data[this.editFieldName] = Vue.$utils.formatTime(e.currentTarget.value);
        },
        times_keydown(e) {
            if (!((e.keyCode >= 48) && (e.keyCode <= 57)) && !((e.keyCode >= 96) && (e.keyCode <= 105)) && (e.keyCode != 8) && (e.keyCode != 9) && (e.keyCode != 27) && (e.keyCode != 13) && (e.keyCode != 190) && (e.keyCode != 110)) {
                e.preventDefault();
                return false;
            }
        },
        times_keyup(e) {
            if (e.keyCode == 27) {
                this.base_click();
                let data = this.items.find(item => item.fullDate === this.editFullDate);
                data[this.editFieldName] = this.valBackup;
                return;
            } else if ((e.keyCode == 13) || (e.keyCode == 9)) {
                //let data = this.items.find(item => item.fullDate === this.editFullDate);
                this.editTimesFieldFlg = false;
                //Vue.$utils.calcTimeRecords(data);
                //this.saveData(data);
                return;
            }
            
            let data = this.items.find(item => item.fullDate === this.editFullDate);
            data[this.editFieldName] = e.currentTarget.value;
        },
        str_keydown(e) {
            //--
        },
        str_keyup(e) {
            if (e.keyCode == 27) {
                this.base_click();
                let data = this.items.find(item => item.fullDate === this.editFullDate);
                data[this.editFieldName] = this.valBackup;
                return;
            } else if ((e.keyCode == 13) || (e.keyCode == 9)) {
                if (!this.canComment) {
                    return;
                }
                this.canComment = false;
                //let data = this.items.find(item => item.fullDate === this.editFullDate);
                this.editStrFieldFlg = false;
                //Vue.$utils.checkCommentRecords(data);
                //this.saveData(data);
                return;
            }
            
            let data = this.items.find(item => item.fullDate === this.editFullDate);
            data[this.editFieldName] = e.currentTarget.value;
        },
        str_keypress(e) {
            this.canComment = true;
        },
        base_click() {
            this.editTimesFieldFlg = false;
            this.editStrFieldFlg = false;
            this.editTimeFieldFlg = false;
            this.valTime = "";
            this.valTimes = "";
            this.valStr = "";
        },
        times(val) {
            if ((val == "") || (val == undefined) || (val <= 0)) {
                val = ":";
            } else {
                val = this.formatTimes(val);
            }
            return val;
        },
        formatTimes(value) {
            value = value * 60;
            let m = value % 60;
            let h = (value - m) / 60;

            m = 100 + m;
            m = (m + "").substr(1,2);
            let result = h + ":" + m;
            
            return result;
        },
        empty() {

        }
    }
}
</script>

<style lang="less">
#recordPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;
        
        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0 0 8px;

            .leftSpace {
                width: 220px;
                user-select: none;

                .yearMonth {
                    width: 100%;
                    height: 30px;
                    font-size: 1.3rem;
                    font-weight: bold;

                    .btnMoveMonthPre {
                        margin-right: 8px;
                        cursor: pointer;
                    }

                    .btnMoveMonthNext {
                        margin-left: 8px;
                        cursor: pointer;
                    }

                    .year {
                        margin-right: 16px;
                    }

                    .month {
                        width: 72px;
                        text-align: right;
                        display: inline-block;
                    }
                }

                .company {
                    height: 30px;
                    padding-top: 6px;
                    padding-left: 12px;
                    border-bottom: 1px solid black;
                }
            }

            .title {
                font-size: 1.8rem;
                font-weight: bold;
                letter-spacing: 34px;
                text-indent: 34px;
            }

            .rightSpace {
                width: 220px;

                .sheet {
                    height: 30px;

                    .sheetItem {
                        width: 100%;
                    }
                }

                .select_user {
                    height: 30px;
                    text-align: right;
                    display: flex;
                    justify-content: space-between;

                    .btnMoveUserPre {
                        padding-top: 6px;
                        margin-right: 8px;
                        user-select: none;
                        cursor: pointer;
                    }

                    .btnMoveUserNext {
                        padding-top: 6px;
                        margin-left: 8px;
                        margin-right: 8px;
                        user-select: none;
                        cursor: pointer;
                    }

                    .name {
                        height: 30px;
                        padding-top: 6px;
                        padding-left: 12px;
                        border-bottom: 1px solid black;
                    }
                }
            }
        }

        .list {
            width: 100%;
            border-spacing: 0;
            
            .list-header, .record, .sumrow {
                .times {
                    text-align: center;
                }

                .date, .day {
                    width: 28px;
                    text-align: center;
                }

                .start, .end, .time1, .time2, .time3, .time4, .breakTime, .extime, .covid19 {
                    width: 80px;
                    text-align: center;
                    padding: 6px 4px;
                }
            }

            .list-header {
                background-color: var(--gridFixedBackColor);
                font-weight: bold;
                color: white;

                .comment {
                    text-align: center;
                }

                td {
                    border-bottom: 1px solid white;
                    border-bottom-color: var(--gridFixedBorderColor);
                    border-left: 1px solid white;
                    border-left-color: var(--gridFixedBorderColor);

                    &:first-child {
                        border-left: 0;
                    }
                }

                .covid19 {
                    white-space: pre-wrap;
                    word-wrap: break-word;
                }
            }

            .sumrow {
                td {
                    padding: 6px 4px;
                    border-bottom: 1px solid black;
                    border-left: 1px solid black;

                    &:last-child {
                        border-right: 1px solid black;
                    }
                }

                .fixedfield {
                    background-color: #cce1ff;
                }

                .title {
                    text-align: right;
                }
            }

            .subheader {
                td {
                    &:first-child {
                        border-left: 1px solid white;
                        border-left-color: var(--gridFixedBorderColor);
                    }
                }
            }
        }

        .footer {
            margin-top: 28px;

            .footer-area {
                width: 100%;

                .stamp-area {
                    width: 210px;
                    text-align: right;
                    vertical-align: bottom;

                    .pdf_btn {
                        width: 160px;
                        margin-bottom: 20px;
                    }

                    .stamp-list {
                        border-spacing: 0;
                        margin: 0 auto;

                        .fixheader {
                            .syoninBtn {
                                vertical-align: bottom;
                            }

                            td {
                                width: 80px;
                                background-color: #ffe77a;
                                text-align: center;
                                border-bottom: 1px solid black;
                                border-right: 1px solid black;
                                
                                &:first-child {
                                    width: 50px;
                                    background-color: var(--backColor);
                                }
                            }

                            &:first-child {
                                td {
                                    border-top: 1px solid black;

                                    &:first-child {
                                        border-top: 0;
                                        border-bottom: 0;
                                    }
                                }
                            }

                            button {
                                width: 50px;
                            }
                        }

                        .cell {
                            td {
                                padding: 6px 4px;
                                border-bottom: 1px solid black;
                                border-right: 1px solid black;
                            }

                            .stamp {
                                position: relative;
                                height: 80px;
                                text-align: center;

                                button {
                                    padding: 0 10px;
                                }

                                button.sashimodoshi {
                                    background-color: #f4433d;
                                    border: 1px solid #f16a65;
                                }

                                .inei {
                                    position: absolute;
                                    width: 70px;
                                    height: 70px;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    margin: auto;
                                    border: 4px solid #ee6a59;
                                    border-radius: 35px;
                                    overflow: hidden;
                                    color: #ee6a59;
                                    font-weight: bold;
                                    text-align: center;
                                    line-height: 70px;

                                    &::before {
                                        position: absolute;
                                        width: 62px;
                                        content: attr(data-name1);
                                        line-height: normal;
                                        font-size: 0.9rem;
                                        top: 0;
                                        left: 0;
                                    }

                                    &::after {
                                        position: absolute;
                                        width: 62px;
                                        content: attr(data-name2);
                                        line-height: normal;
                                        font-size: 0.9rem;
                                        top: 42px;
                                        left: 0;
                                    }

                                    .date {
                                        position: absolute;
                                        width: 62px;
                                        height: 22px;
                                        border-top: 1px solid #ee6a59;
                                        border-bottom: 1px solid #ee6a59;
                                        top: 20px;

                                        &::before {
                                        position: absolute;
                                        width: 62px;
                                        content: attr(data-date);
                                        line-height: normal;
                                        font-size: 0.7rem;
                                        top: 3px;
                                        left: 0;
                                    }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .edit_field {
        position: absolute;
        box-shadow: 1px 1px 6px 2px rgba(0,0,0,0.2);
        
        input[type="text"] {
            width: 100%;
            height: 100%;
            border: 0;
            padding: 2px 6px;
            
            &:focus {
                border: 0;
                outline: 0;
            }
        }

        &.time, &.times {
            input[type="text"] {
                text-align: center;
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:1027px) {
    #recordPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;

            .header {
                width: 1028px;
            }

            .list {
                width: 1028px;
            }

            .footer {
                width: 1028px;
            }
        }
    }
}

&[data-theme="dark"] {
    #recordPg {
        .main_area {
            .list {
                .sumrow {
                    .fixedfield {
                        background-color: #758aa5;
                    }
                }
            }

            .footer {
                .footer-area {
                    .stamp-area {
                        .stamp-list {
                            .fixheader {
                                td {
                                    background-color: #a08f44;

                                    &:first-child {
                                        background-color: var(--backColor);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    #recordPg {
        .main_area {
            .list {
                .sumrow {
                    .fixedfield {
                        background-color: #758aa5;
                    }
                }
            }

            .footer {
                .footer-area {
                    .stamp-area {
                        .stamp-list {
                            .fixheader {
                                td {
                                    background-color: #a08f44;

                                    &:first-child {
                                        background-color: var(--backColor);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &[data-theme="light"] {
        #recordPg {
            .main_area {
                .list {
                    .sumrow {
                        .fixedfield {
                            background-color: #cce1ff;
                        }
                    }
                }

                .footer {
                    .footer-area {
                        .stamp-area {
                            .stamp-list {
                                .fixheader {
                                    td {
                                        background-color: #ffe77a;

                                        &:first-child {
                                            background-color: var(--backColor);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>