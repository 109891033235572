<template>
    <div id="sideMenu" :class="{'on_menu':onmenuFlg}" @click.stop="empty_click">
        <div v-if="visibleCheck('stamping')" class="menu" @click="menu_click('stamping')">打刻</div>
        <div v-if="visibleCheck('record')" class="menu" @click="menu_click('record')">出勤簿</div>
        <div v-if="visibleCheck('applyLeave')" class="menu" @click="menu_click('applyLeave')">休暇申請</div>
        <div v-if="visibleCheck('substituteHoliday')" class="menu" @click="menu_click('substituteHoliday')">振替休日</div>
        <div v-if="visibleCheck('applyPayment')" class="menu" @click="menu_click('applyPayment')">精算申請</div>
        <div v-if="visibleCheck('control')" class="menu" @click="menu_click('control')">出勤簿管理</div>
        <div v-if="visibleCheck('leaveApproval')" class="menu" @click="menu_click('leaveApproval')">休暇承認</div>
        <div v-if="visibleCheck('paymentApproval')" class="menu" @click="menu_click('paymentApproval')">精算承認</div>
        <div v-if="visibleCheck('staff')" class="menu" @click="menu_click('staff')">スタッフ管理</div>
        <div v-if="visibleCheck('sheet')" class="menu" @click="menu_click('sheet')">シート管理</div>
        <div v-if="visibleCheck('accountInfo')" class="menu" @click="menu_click('accountInfo')">アカウント</div>
        <div v-if="visibleCheck('config')" class="menu" @click="menu_click('config')">設定</div>
        <div v-if="visibleCheck('logoff')" class="menu" @click="menu_click('logoff')">ログオフ</div>
    </div>
</template>

<script>
export default {
    name: "sideMenu",
    props: {
        onmenuFlg: {type:Boolean, default:false},
        managerFlg: {type:Boolean, default:false},
        runingMode: {type:String, default:""}
    },
    methods: {
        menu_click(name) {
            this.$emit('menu_click', name);
        },
        empty_click() {
            //--
        },
        visibleCheck(menu) {
            let res = true;

            switch (menu) {
                case 'paymentApproval':
                    // 作成中のため本番は非表示
                    if (this.runingMode == 'production') {
                        res = false;
                    }
                    if (!this.managerFlg) {
                        res = false;
                    }
                    break;
                case 'leaveApproval':
                case 'control':
                case 'staff':
                case 'sheet':
                    if (this.managerFlg) {
                        res = true;
                    } else {
                        res = false;
                    }
                    break;
                default:
                    res = true;

            }

            return res;
        }

    }
}
</script>

<style lang="less">
#sideMenu {
    position: fixed;
    background-color: #000;
    width: calc(100vw - 10%);
    height: 100vh;
    top: 0;
    left: calc(100vw * -1);
    transition: left 0.6s;
    z-index: 9999;
    padding: 22px;

    .menu {
        margin-bottom: 6px;
        padding: 6px 0;
        color: #fff;
        cursor: default;
        font-weight: bold;
    }
}

#sideMenu.on_menu {
    left: 0;
}

@media(min-width:500px) {
    #sideMenu {
        width: 300px;
    }
}
</style>