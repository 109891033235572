<template>
    <div class="calendarCell">
        <div class="month">{{month}}月</div>
        <div class="cell_area">
            <div v-for="(item, idx) in dates" class="cell"
                :key="item.dateString"
                :class="{'fcancel':cellNum(idx), 'saturday':item.day == 6, 'holiday':item.day == 0, 'outer':item.outer, 'selectDay':selected(item)}"
                @click.stop="cell_click(item)">{{item.date}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "calendarCell",
    props: {
        year: {type:Number, default:2020},
        month: {type:Number, default:1},
        days: null
    },
    computed: {
        dates() {
            let list = [];
            let p = 0;
            let fd = new Date(this.year, this.month - 1, 1);

            fd.setDate(fd.getDate() - fd.getDay());
            do {
                let outer = false;
                if ((fd.getFullYear() != this.year) || ((fd.getMonth() + 1) != this.month)) {
                    outer = true;
                }
                let itm = {
                    dateString: fd.toChar('yyyy-MM-dd'),
                    date: fd.getDate(),
                    day: fd.getDay(),
                    outer: outer
                };
                list.push(itm);
                fd.setDate(fd.getDate() + 1);
                ++p;
            } while(p < 42)

            return list;
        }
    },
    mounted() {
        this.$nextTick(function() {

        });
    },
    methods: {
        cellNum(n) {
            if (n % 7 == 0) {
                return true;
            }
            return false;
        },
        selected(item) {
            if (item.outer) {
                return false;
            }
            let data = this.days.find(itm => itm === item.dateString);
            if (data != undefined) {
                return true;
            }
            return false;
        },
        cell_click(item) {
            if (item.outer) {
                return false;
            }
            this.$emit('cellClick', item.dateString);
        }
    }
}
</script>

<style lang="less">
.calendarCell {
    width: 288px;
    padding: 8px;

    .month {
        font-weight: bold;
        text-align: center;
        padding: 3px 0;
        vertical-align: middle;
        border: 1px solid #000;
        color: white;
        background-color: var(--gridFixedBackColor);
    }

    .cell_area {
        .cell {
            width: 14.285%;
            height: 42px;
            float: left;
            text-align: center;
            padding: 12px 0;
            border-bottom: 1px solid #000;
            border-right: 1px solid #000;
            cursor: pointer;
        }

        .cell.fcancel {
            clear: left;
            border-left: 1px solid #000;
        }

        .saturday {
            background-color: var(--saturdayBackColor);
        }

        .holiday {
            background-color: var(--holidayBackColor);
        }

        .outer {
            background-color: var(--grayoutBackColor);
            color: #A8A8A8;
            cursor: default;
        }

        .outer.selectDay {
            color: #A8A8A8;
        }

        .selectDay {
            position: relative;
            color: red;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 24px;
                height: 24px;
                content: "";
                border: 3px solid #ee6a59;
                border-radius: 50%;
            }
        }
    }
}
</style>