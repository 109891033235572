var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "applyTravelPaymentEdit" } }, [
    _c(
      "div",
      {
        staticClass: "backBtn",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.backPage()
          },
        },
      },
      [
        _c("icon", { staticClass: "icon", attrs: { name: "chevron-left" } }),
        _vm._v(" 一覧"),
      ],
      1
    ),
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header1" }, [
        _c("div", { staticClass: "date_area" }, [
          _vm._v("申請日："),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.date,
                expression: "date",
              },
            ],
            attrs: { type: "date" },
            domProps: { value: _vm.date },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.date = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "total_area" }, [
          _vm._v("合計："),
          _c("span", { staticClass: "total" }, [
            _vm._v(_vm._s(_vm.getAmount(_vm.total))),
          ]),
          _c("span", { staticClass: "unit" }, [_vm._v("円")]),
        ]),
      ]),
    ]),
    _c("div", { ref: "detail", staticClass: "detail_list" }, [
      _c(
        "div",
        { ref: "list", staticClass: "list" },
        [
          _c("div", { staticClass: "header2" }, [
            _c("div", { staticClass: "destination_area" }, [
              _vm._v("訪問先："),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.destination,
                    expression: "destination",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.destination },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.destination = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "location_area" }, [
              _vm._v("所在地："),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.location,
                    expression: "location",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.location },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.location = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "matter_area" }, [
              _vm._v("用　件："),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.matter,
                    expression: "matter",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.matter },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.matter = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "traffic_area" }, [
              _vm._v("期　間："),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.startDt,
                    expression: "startDt",
                  },
                ],
                staticClass: "from",
                attrs: { type: "date" },
                domProps: { value: _vm.startDt },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.startDt = $event.target.value
                  },
                },
              }),
              _vm._v(" 〜 "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.endDt,
                    expression: "endDt",
                  },
                ],
                staticClass: "to",
                attrs: { type: "date" },
                domProps: { value: _vm.endDt },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.endDt = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "header3" }, [
            _c("div", { staticClass: "etc_area" }, [
              _c("div", { staticClass: "title1" }, [_vm._v("日当定額：")]),
              _c("div", [
                _vm._v(_vm._s(_vm.getAmount(_vm.diemsPrice, "¥")) + " × "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.diemsDays,
                      expression: "diemsDays",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.diemsDays },
                  on: {
                    change: function ($event) {
                      $event.stopPropagation()
                      return _vm.change_nittou.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.diemsDays = $event.target.value
                    },
                  },
                }),
                _vm._v(" 日"),
              ]),
              _c("div", { staticClass: "etc_money" }, [
                _vm._v(_vm._s(_vm.getAmount(_vm.diems, "¥"))),
              ]),
            ]),
            _c("div", { staticClass: "etc_area" }, [
              _c("div", [_vm._v("半日当定額：")]),
              _c("div", [
                _vm._v(_vm._s(_vm.getAmount(_vm.diemsHalfPrice, "¥")) + " × "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.diemsHalfDays,
                      expression: "diemsHalfDays",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.diemsHalfDays },
                  on: {
                    change: function ($event) {
                      $event.stopPropagation()
                      return _vm.change_halfNittou.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.diemsHalfDays = $event.target.value
                    },
                  },
                }),
                _vm._v(" 日"),
              ]),
              _c("div", { staticClass: "etc_money" }, [
                _vm._v(_vm._s(_vm.getAmount(_vm.diemsHalf, "¥"))),
              ]),
            ]),
            _c("div", { staticClass: "etc_area" }, [
              _c("div", [_vm._v("宿泊料定額：")]),
              _c("div", [
                _vm._v(_vm._s(_vm.getAmount(_vm.stayPrice, "¥")) + " × "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.stayDays,
                      expression: "stayDays",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.stayDays },
                  on: {
                    change: function ($event) {
                      $event.stopPropagation()
                      return _vm.change_stay.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.stayDays = $event.target.value
                    },
                  },
                }),
                _vm._v(" 泊"),
              ]),
              _c("div", { staticClass: "etc_money" }, [
                _vm._v(_vm._s(_vm.getAmount(_vm.stay, "¥"))),
              ]),
            ]),
            _c("div", { staticClass: "etc_area" }, [
              _c("div", [_vm._v("宿泊料実額：")]),
              _c("div", [
                _vm._v("¥ "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.stayPrice2,
                      expression: "stayPrice2",
                    },
                  ],
                  staticClass: "stay_price",
                  attrs: { type: "number" },
                  domProps: { value: _vm.stayPrice2 },
                  on: {
                    change: function ($event) {
                      $event.stopPropagation()
                      return _vm.change_stayPrice2.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.stayPrice2 = $event.target.value
                    },
                  },
                }),
                _vm._v(" × "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.stayDays2,
                      expression: "stayDays2",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.stayDays2 },
                  on: {
                    change: function ($event) {
                      $event.stopPropagation()
                      return _vm.change_stay2.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.stayDays2 = $event.target.value
                    },
                  },
                }),
                _vm._v(" 泊"),
              ]),
              _c("div", { staticClass: "etc_money" }, [
                _vm._v(_vm._s(_vm.getAmount(_vm.stay2, "¥"))),
              ]),
            ]),
          ]),
          _vm._l(_vm.details, function (dat, ix) {
            return _c("item", {
              key: ix,
              staticClass: "detail",
              attrs: {
                payDate: dat.payDate,
                facilities: dat.facilities,
                from: dat.from,
                to: dat.to,
                money: _vm.getMoney(dat.money),
              },
              on: {
                paydate_change: function ($event) {
                  return _vm.paydate_change($event, ix)
                },
                facilities_change: function ($event) {
                  return _vm.facilities_change($event, ix)
                },
                from_change: function ($event) {
                  return _vm.from_change($event, ix)
                },
                to_change: function ($event) {
                  return _vm.to_change($event, ix)
                },
                change_money: function ($event) {
                  return _vm.change_money($event, ix)
                },
                menu_popup: function ($event) {
                  return _vm.menu_popup($event, ix)
                },
              },
            })
          }),
          _c("div", { staticClass: "button_area" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.save_travel.apply(null, arguments)
                  },
                },
              },
              [_vm._v("保存")]
            ),
          ]),
          !_vm.checkNewData
            ? _c("div", { staticClass: "button_area" }, [
                _c(
                  "button",
                  {
                    staticClass: "pdf_print",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.downloadBtn()
                      },
                    },
                  },
                  [_vm._v("PDF出力")]
                ),
              ])
            : _vm._e(),
          !_vm.checkNewData
            ? _c("div", { staticClass: "button_area deleteBtn" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.delete_travel.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("削除")]
                ),
              ])
            : _vm._e(),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuFlag,
                  expression: "menuFlag",
                },
              ],
              ref: "menu",
              staticClass: "menu_area",
            },
            [
              _c("li", [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onReversal()
                      },
                    },
                  },
                  [_vm._v("反対方向を追加")]
                ),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onDelete()
                      },
                    },
                  },
                  [_vm._v("削除")]
                ),
              ]),
              _vm.upbtnFlag
                ? _c("li", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onUp()
                          },
                        },
                      },
                      [_vm._v("１つ上に移動")]
                    ),
                  ])
                : _vm._e(),
              _vm.downbtnFlag
                ? _c("li", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onDown()
                          },
                        },
                      },
                      [_vm._v("１つ下に移動")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }