<template>
    <table class="recsummary">
        <tr class="fixheader">
            <td>所定日数</td>
            <td>出勤日数</td>
            <td>欠勤日数</td>
            <td>有給休暇<br>取得日数</td>
            <td>休日出勤<br>日数</td>
            <td>特別休暇<br>取得日数</td>
            <td class="smallText">遅刻・早退<br>回数</td>
            <td>有休消化<br>日数</td>
            <td v-if="opcell1" class="smallText">{{opsyukei8Nm}}</td>
        </tr>
        <tr class="cell">
            <td class="nissu">{{syoteiDays}}</td>
            <td class="nissu">{{syukinDays}}</td>
            <td class="nissu">{{kekinDays}}</td>
            <td class="nissu">{{yukyuDays}}</td>
            <td class="nissu">{{kyujitsuDays}}</td>
            <td class="nissu">{{tokuDays}}</td>
            <td class="nissu">{{tikokuCnt}}</td>
            <td class="nissu">{{yukyuSyokaDays}}</td>
            <td v-if="opcell1" class="nissu">{{kyugyoDays}}</td>
        </tr>
        <tr class="fixheader">
            <td>時間外<br>労働時間</td>
            <td>深夜<br>労働時間</td>
            <td>休日<br>労働時間</td>
            <td v-if="hougaiFlag">法外休日<br>出勤日数</td>
            <td v-if="hougaiFlag">法外休日<br>労働時間</td>
            <td v-if="hougaiFlag" class="comment" colspan="2">備<span></span>考</td>
            <td v-if="!hougaiFlag" class="comment" colspan="4">備<span></span>考</td>
            <td>有休残<br>日数</td>
            <td v-if="opcell1" class="smallText covid19">{{opsyukei12Nm}}</td>
        </tr>
        <tr class="cell">
            <td class="times">{{times(jikangaiTimes)}}</td>
            <td class="times">{{times(sinyaTimes)}}</td>
            <td class="times">{{times(kyujitsuTimes)}}</td>
            <td v-if="hougaiFlag" class="nissu">{{houteigaiDays}}</td>
            <td v-if="hougaiFlag" class="times">{{times(houteigaiTimes)}}</td>
            <td v-if="hougaiFlag" colspan="2">{{comment}}</td>
            <td v-if="!hougaiFlag" colspan="4">{{comment}}</td>
            <td class="nissu">{{yukyuZanDays}}</td>
            <td v-if="opcell1" class="times covid19">{{times(kyugyoTimes)}}</td>
        </tr>
    </table>
</template>

<script>
export default {
    name: 'recsummary',
    props: {
        opcell1: {type:Boolean, default:false},
        syoteiDays: {type:Number, default:0},
        syukinDays: {type:Number, default:0},
        kekinDays: {type:Number, default:0},
        yukyuDays: {type:Number, default:0},
        kyujitsuDays: {type:Number, default:0},
        houteigaiDays: {type:Number, default:0},
        tokuDays: {type:Number, default:0},
        tikokuCnt: {type:Number, default:0},
        yukyuSyokaDays: {type:Number, default:0},
        kyugyoDays: {type:Number, default:0},
        jikangaiTimes: {type:Number, default:0},
        sinyaTimes: {type:Number, default:0},
        kyujitsuTimes: {type:Number, default:0},
        houteigaiTimes: {type:Number, default:0},
        comment: {type:String, default:''},
        yukyuZanDays: {type:Number, default:0},
        kyugyoTimes: {type:Number, default:0},
        opsyukei8Nm: {type:String, default:''},
        opsyukei12Nm: {type:String, default:''},
        hougaiFlag: {type:Boolean, default:false}
    },
    methods: {
        times(val) {
            if ((val == "") || (val == undefined) || (val <= 0)) {
                val = ":";
            } else {
                val = this.formatTimes(val);
            }
            return val;
        },
        formatTimes(value) {
            value = value * 60;
            let m = value % 60;
            let h = (value - m) / 60;

            m = 100 + m;
            m = (m + "").substr(1,2);
            let result = h + ":" + m;
            
            return result;
        }
    }
}
</script>

<style lang="less">
.recsummary {
    border-spacing: 0;

    .fixheader {
        td {
            width: 80px;
            background-color:#cce1ff;
            text-align: center;
            border-bottom: 1px solid black;
            border-left: 1px solid black;

            &:last-child {
                border-right: 1px solid black;
            }
        }

        .smallText {
            font-size: 0.9rem;
        }

        .comment {
            span {
                margin-left: 28px;
            }
        }

        &:first-child {
            td {
                border-top: 1px solid black;
            }
        }
    }

    .cell {
        td {
            padding: 6px 4px;
            border-bottom: 1px solid black;
            border-left: 1px solid black;

            &:last-child {
                border-right: 1px solid black;
            }
        }

        .nissu {
            text-align: right;
        }

        .times {
            text-align: center;
        }
    }

    .covid19 {
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}

&[data-theme="dark"] {
    .recsummary {
        .fixheader {
            td {
                background-color:#758aa5;
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    .recsummary {
        .fixheader {
            td {
                background-color:#758aa5;
            }
        }
    }

    &[data-theme="light"] {
        .recsummary {
            .fixheader {
                td {
                    background-color:#cce1ff;
                }
            }
        }
    }
}
</style>