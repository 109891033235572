<template>
    <span class="ui-date">
        <input type="date"
            :value="value" 
            :name="name" 
            :class="className" 
            :readonly="readonly" 
            :autocomplete="autocomplete" 
            :max="max" 
            :min="min" 
            :step="step" 
            :required="required" 
            :list="list"
            @change="change"
            @input="input"
        />
    </span>
</template>

<script>
export default {
    name: "ui-date",
    props: {
        name: {type:String, default:""},
        source: {type:String, default:""},
        max: {type:String, default:""},
        min: {type:String, default:""},
        step: {type:String, default:""},
        readonly: {type:Boolean, default: false},
        required: {type:Boolean, default: false},
        autocomplete: {type:String, default:"default"},
        list: {type:String, default:""},
        className: {type:String, default:undefined}
    },
    data() {
        return {
            value: ""
        }
    },
    watch: {
        source(n, o) {
            this.value = n;
        }
    },
    mounted() {
        this.value = this.source;
    },
    methods: {
        change(e) {
            this.$emit('change', e);
        },
        input(e) {
            this.value = e.target.value;
            this.$emit('input', e);
        }
    }
}
</script>

<style>

</style>