<template>
    <div id="loginPg">
        <div class="loginArea">
            <div class="title">ログイン</div>
            <div class="formArea tbl">
                <div class="tblrow">
                    <div class="sp tblcell"></div>
                    <div class="name tblcell">アカウント</div>
                    <div class="tblcell"><div class="inputBase"><input type="text" ref="accountField" placeholder="アカウントを入力" v-model="account" /></div></div>
                    <div class="sp tblcell"></div>
                </div>
                <div class="tblrow">
                    <div class="sp tblcell"></div>
                    <div class="name tblcell">パスワード</div>
                    <div class="tblcell"><div class="inputBase"><input type="password" placeholder="パスワードを入力" v-model="pwd"/></div></div>
                    <div class="sp tblcell"></div>
                </div>
            </div>
            <div class="buttonArea">
                <button @click.stop="login">ログイン</button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
export default {
    name: "loginPg",
    data() {
        return {
            account: "",
            pwd: ""
        }
    },
    mounted() {
        this.$nextTick(function() {
            let _self = this;
            setTimeout(function(){
                let elm = _self.$refs.accountField;
                elm.focus();
            });
        });
    },
    methods: {
        async login() {
            try {
                let deviceCode = this.$store.state.operator.deviceCode;

                let param = {
                    account: this.account,
                    password: this.pwd,
					deviceCode: deviceCode
                };
                let ress = await Vue.$cache.post('account/login/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        if (ress.data.data.checked == "1") {
                            //--
                            this.$store.commit("operator_accountId", ress.data.data.accountId);
                            this.$store.commit("operator_authority", ress.data.data.authority);
                            this.$store.commit("operator_activeAccountId", ress.data.data.accountId);
                            let setDate = Vue.$utils.getCookieDate(365);
                            this.$cookies.set('Account', ress.data.data.encstr, {expires: setDate});
                            this.$cookies.set('Authority', ress.data.data.authority, {expires: setDate});

                            if (this.$store.state.operator.logoutJump != "") {
                                let url = this.$store.state.operator.logoutJump;
                                this.$store.dispatch("operator_logoutJump", "");
                                this.$router.push(url);
                            } else {
                                this.$router.push("/kintai/");
                            }
                        } else {
                            alert('ログインに失敗しました。\n\nアカウント及びパスワードをご確認下さい。');
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        }
    }
}
</script>

<style lang="less">
#loginPg {
    position: relative;
    width: 100%;
    height: 90%;
    font-size: 1.1rem;

    .loginArea {
        position: absolute;
        top: 0;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
        width: 320px;
        height: 228px;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 8px;
        box-shadow: 4px 4px 12px 6px rgba(0,0,0,0.1);
        padding: 0 18px;

        .title {
            font-size: 1.3rem;
            font-weight: bold;
            text-align: center;
            padding: 12px;
        }

        .formArea {
            width: 100%;

            .sp {
                width: 2px;
                height: 52px;
            }

            .inputBase {
                border: 1px solid #676767;
                border-radius: 18px;
                background-color: var(--backColor);
                padding: 6px 18px;
                height: 36px;
            }

            .name {
                width: 96px;
            }

            input {
                width: 100%;
                border: 0;
                border-radius: 0;
                -webkit-appearance: none;
                outline: none;

                &:focus {
                    outline: none;
                }
            }
        }

        .buttonArea {
            text-align: center;
            padding: 12px;

            button {
                width: 120px;
                height: 42px;
                border-radius: 21px;

                &:focus {
                    outline: none;
                }
            }
        }
    }
}

@media (max-width: 479px) {
    /* スマホ */
    #loginPg {
        .loginArea {
            border: 0px;
            box-shadow: 0 0 0 0;
        }
    }
}
</style>