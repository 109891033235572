var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "applyLeavePg" } }, [
    _c("div", { staticClass: "dummy" }),
    _c("div", { key: _vm.dummyKey, staticClass: "list_area" }, [
      _vm.cardSelected || _vm.newFlag
        ? _c("div", { staticClass: "edit_tool_area" }, [
            _c(
              "div",
              {
                staticClass: "compliteBtn text_button",
                on: { click: _vm.compliteBtn_click },
              },
              [_vm._v("完了")]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "cards_area",
          class: { edited: _vm.cardSelected || _vm.newFlag },
          style: "height:" + _vm.cardAreaHeight + "px",
        },
        [
          _vm.cards.length == 0
            ? _c("div", { staticClass: "empty_area" }, [
                _vm._v("表示できる休暇申請はありません"),
              ])
            : _vm._e(),
          _vm._l(_vm.cards, function (card, idx) {
            return _c("card", {
              key: idx,
              staticClass: "card",
              attrs: {
                accountId: _vm.accountId,
                leaveId: card.leaveId,
                timestamp: card.timestamp,
                start: card.start,
                end: card.end,
                leaveType: card.leaveType,
                specialleave: card.specialleave,
                comment: card.comment,
                remandComment: card.remandComment,
                applydate: card.applydate,
                status: card.status,
                leaveTypeList: _vm.leaveTypeList,
                specialleaveList: _vm.specialleaveList,
                zanYukyu: _vm.zanYukyu,
                select: card.select,
                visible: card.visible,
                top: card.top,
                zindex: card.zindex,
              },
              on: {
                card_update: function ($event) {
                  return _vm.card_update($event, idx)
                },
                card_click: function ($event) {
                  return _vm.card_click($event, idx)
                },
                card_delete: _vm.card_delete,
              },
            })
          }),
          _vm.newFlag
            ? _c("card", {
                key: _vm.newCardKey,
                staticClass: "newCard",
                attrs: {
                  accountId: _vm.accountId,
                  start: _vm.newData.start,
                  end: _vm.newData.end,
                  leaveType: _vm.newData.leaveType,
                  specialleave: _vm.newData.specialleave,
                  comment: _vm.newData.comment,
                  remandComment: "",
                  leaveTypeList: _vm.leaveTypeList,
                  specialleaveList: _vm.specialleaveList,
                  zanYukyu: _vm.zanYukyu,
                  newFlag: true,
                  top: 40,
                  zindex: 9990,
                },
                on: {
                  card_update: function ($event) {
                    return _vm.card_update($event, -1)
                  },
                },
              })
            : _vm._e(),
        ],
        2
      ),
      !_vm.newFlag && !_vm.cardSelected
        ? _c(
            "div",
            {
              staticClass: "newCreateBtn",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.createCard.apply(null, arguments)
                },
              },
            },
            [_vm._v("+")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }