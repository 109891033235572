<template>
    <tr class="leave-managed-row" @click.stop="row_click($event)">
        <td class="employNo">{{cvtEmploy(item.employNo)}}</td>
        <td class="name">{{item.name}}</td>
        <td class="leaveName">{{vleaveName}}</td>
        <td class="applydate">{{vapplydate}}</td>
        <td class="start">{{item.start}}</td>
        <td class="end">{{item.end}}</td>
        <td class="status">{{cvtSyonin(item.status)}}</td>
    </tr>
</template>

<script>
import Vue from 'vue';
export default {
    props: {
        accountId: {type: String, default: ""},
        item: null,
        specialleaveList:null
    },
    computed: {
        vleaveName() {
            let result = "";
            if (this.item.leaveType == 2) {
                result = this.getSpecialLeaveName();
            } else {
                result = this.getYukyuName();
            }
            return result;
        },
        vapplydate() {
            return this.item.applydate.split(":")[0]+":"+this.item.applydate.split(":")[1];
        }
    },
    data() {
        return {
            timeon: false
        }
    },
    mounted() {
        if (this.item.start.split(" ").length > 1) {
            this.timeon = true;
        }
    },
    methods: {
        row_click(e) {
            this.$emit("leaave_click", this.item.leaveId);
        },
        cvtEmploy(val) {
            if (val == 0) {
                val = "0001";
            } else {
                val = ((1000 + (val - 0)) + "");
            }
            return val;
        },
        cvtSyonin(val) {
            let result = (val == "2") ? "承認" : (val == "4") ? "取下げ承認待ち" : "承認待ち";
            if (result == "承認待ち" && this.item.syoninDate != "" && this.item.syoninDate != undefined) {
                result = "再承認待ち";
            }
            return result;
        },
        getSpecialLeaveName() {
            let code = this.item.specialleave;
            if (code == "" || code == undefined) { code = "11"; }
            let datas = this.specialleaveList.filter((item) => {
                return item.code == code;
            });
            if (datas.length == 0) {
                return "";
            }
            return datas[0].name;
        },
        getYukyuName() {
            let result = "有給休暇";
            if (this.timeon) {
                if (this.item.start != "" && this.item.start != undefined && this.item.end != "" && this.item.end != undefined) {
                    let end = this.item.end.split(" ")[0];
                    let sday = this.item.start.split(" ")[0];
                    let ed1 = new Date(sday + "T18:00");
                    let ed2 = new Date(this.item.start.replace(" ", "T"));
                    let wt = end * 60 * 60 * 1000;
                    ed2.setTime(ed2.getTime() + wt);
                    let sec = (ed1.getTime() - ed2.getTime()) / 1000;
                    let h = sec / 60 / 60;
                    if (h > 6) { h -= 1; }
                    if (h < 8) {
                        result = "半日休暇";
                    }
                }
            }
            return result;
        }
    }
}
</script>

<style lang="less">
.leave-managed-row {
    position: relative;

    td {
        padding: 6px 4px;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        white-space: nowrap;

        &:last-child {
            border-right: 1px solid black;
        }
    }

    .tool_area {
        button {
            cursor: pointer;
            margin-right: 6px;
        }
    }

    .pdf, .kakutei, .hireDate, .syonin, .age, .sheetreki {
        text-align: center;
    }

    .syouka, .zan {
        text-align: right;
    }

    .sheetreki {
        cursor: pointer;

        .icon {
            width: 14px;
            fill: var(--foreColor);
        }
    }
}
</style>