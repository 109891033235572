<template>
    <span class="ui-datetime">
        <input type="datetime" v-model="value" 
            :name="name" 
            :class="className" 
            :readonly="readonly" 
            :autocomplete="autocomplete" 
            :max="max" 
            :min="min" 
            :step="step" 
            :required="required" 
            :list="list"
            @change="change"
            @input="input"
        />
    </span>
</template>

<script>
export default {
    name: "ui-datetime",
    props: {
        name: {type:String, default:""},
        source: {type:String, default:""},
        max: {type:String, default:""},
        min: {type:String, default:""},
        step: {type:String, default:""},
        readonly: {type:Boolean, default: false},
        required: {type:Boolean, default: false},
        autocomplete: {type:String, default:"default"},
        list: {type:String, default:""},
        className: {type:String, default:undefined}
    },
    data() {
        return {
            value: ""
        }
    },
    watch: {
        value(nv, ov) {
            if ((nv != "") && (nv != undefined)) {
                let da = new Date(nv);
                let v = da.toChar("yyyy-MM-dd");
                let t = da.toChar("HH:mm");
                if ((v == "") || (t == "")) {
                    this.value = ov;
                }
            }
        }
    },
    mounted() {
        this.value = this.source;
    },
    methods: {
        change(e) {
            console.log("change", this.value)
            this.$emit('change', this.value, e);
        },
        input(e) {
            this.$emit('input', this.value, e);
        }
    }
}
</script>

<style>

</style>