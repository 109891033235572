<template>
    <div id="samplePg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
            </div>
            <!-- メイン -->
            <div class="main">
                <div class="ui-area">
                    text：<input type="text" />
                </div>
                <div class="ui-area">
                    password：<input type="password" />
                </div>
                <div class="ui-area">
                    search：<input type="search" />
                </div>
                <div class="ui-area">
                    email：<input type="email" />
                </div>
                <div class="ui-area">
                    tel：<input type="tel" />
                </div>
                <div class="ui-area">
                    url：<input type="url" />
                </div>
                <div class="ui-area">
                    date：<input type="date" />&nbsp;※使わない&nbsp;(SafariはNG)
                </div>
                <div class="ui-area">
                    ui-date：<ui-date :source="vdate" :className="'ui-date-class'" @change="change_ui_date" />&nbsp;(SafariはNG)
                </div>
                <div class="ui-area">
                    time：<input type="time" />&nbsp;(SafariはNG)
                </div>
                <div class="ui-area">
                    datetime：<input type="datetime" />&nbsp;※使わない&nbsp;(Safari, FireFoxはNG)
                </div>
                <div class="ui-area">
                    ui-datetime：<ui-datetime :source="vdatetime" :className="'ui-datetime-class'" @change="change_ui_datetime" />&nbsp;(Safari, FireFoxはNG)
                </div>
                <div class="ui-area">
                    datetime-local：<input type="datetime-local" />&nbsp;※使わない&nbsp;(Safari, FireFoxはNG)
                </div>
                <div class="ui-area">
                    ui-datetime-local：<ui-datetime-local :source="vdatetimelocal" :className="'ui-datetimelocal-class'" @change="change_ui_datetime_local" />&nbsp;(Safari, FireFoxはNG)
                </div>
                <div class="ui-area">
                    month：<input type="month" />&nbsp;(Safari, FireFoxはNG)
                </div>
                <div class="ui-area">
                    week：<input type="week" />&nbsp;(Safari, iOS版Safari, FireFoxはNG)
                </div>
                <div class="ui-area">
                    number：<input type="number" />
                </div>
                <div class="ui-area">
                    range：<input type="range" />
                </div>
                <div class="ui-area">
                    color：<input type="color" />
                </div>
                <div class="ui-area">
                    checkbox：<input type="checkbox" />
                </div>
                <div class="ui-area">
                    radio：<input type="radio" />
                </div>
                <div class="ui-area">
                    file：<input type="file" />
                </div>
                <hr style="margin:20px 0">
                <div class="ui-area">
                    select：<select>
                        <option>オプション１</option>
                        <option>オプション２</option>
                        <option>オプション３</option>
                    </select>
                </div>
                <hr style="margin:20px 0">
                <div class="ui-area">
                    textarea：<textarea></textarea>
                </div>
            </div>
            <!-- フッター -->
            <div class="footer">
            </div>
        </div>
    </div>
</template>

<script>
import uiDate  from '@/components/ui/date.vue';
import uiDatetime  from '@/components/ui/datetime.vue';
import uiDatetimeLocal  from '@/components/ui/datetime-local.vue';
export default {
    name: "samplePg",
    components: {
        'ui-date': uiDate,
        'ui-datetime': uiDatetime,
        'ui-datetime-local': uiDatetimeLocal
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov) {
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            headerTitle: "UIサンプル",
            vdate: "2021-03-02",
            vdatetime: "2021-03-02 12:35",
            vdatetimelocal: "2021-03-02T12:35"
        }
    },
    mounted() {
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");

            

        });
    },
    methods: {
        change_ui_date(value, e) {
            console.log("change_ui_date", value, e);
        },
        change_ui_datetime(value, e) {
            console.log("change_ui_datetime", value, e);
        },
        change_ui_datetime_local(value, e) {
            console.log("change_ui_datetime_local", value, e);
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#samplePg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .main {
            .ui-area {
                margin-bottom: 6px;
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:1027px) {
    #samplePg {
        .main_area {
            .main {
                .ui-area {
                    margin-right: 0px;
                }
            }
        }
    }
}
</style>