<template>
    <div id="applyPaymentPg">
        <div class="main_area">
            <div class="header"></div>
            <div class="menu_area">
                <button @click.stop="menu_click('traffic')">交通費精算</button>
                <button @click.stop="menu_click('goods')">物品購入精算</button>
                <button @click.stop="menu_click('near_travel')">旅費精算（近距離）</button>
                <button @click.stop="menu_click('travel')">旅費精算（一般）</button>
            </div>
            <div class="footer"></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "applyPaymentPg",
    mixins: [utilityMixin],
    data() {
        return {
            headerTitle: "精算申請",
            accountId: "",
            activeAccountId: ""
        }
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        condition() {
            this.getRekiList();
        },
        baseClick(nv, ov){
            //--
        }
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;
        });
    },
    methods: {
        menu_click(mode) {
            switch (mode) {
                case 'traffic':
                    this.jump_pg('/kintai/applypayment/traffic');
                    break;
                case 'goods':
                    this.jump_pg('/kintai/applypayment/goods');
                    break;
                case 'near_travel':
                    this.jump_pg('/kintai/applypayment/nearTravel');
                    break;
                case 'travel':
                    this.jump_pg('/kintai/applypayment/travel');
                    break;
            }
        },
        jump_pg(key, param) {
            this.paging += 1;
            if (param != undefined) {
                this.$store.commit("operator_routeParam", param);
            }
            this.$router.push(key, () => {});
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less" scoped>
#applyPaymentPg {
    width: 100%;
    height: 100%;

    .main_area {
        width: 100%;
        height: 100%;
        padding: 40px 12%;

        .menu_area {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column;
            justify-content:space-around;

            button {
                padding: 12px 24px;
                border-radius: 999px;
                cursor: pointer;
            }
        }
    }
}

#base_area {
    height: 100%;

    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:500px) {
    #applyPaymentPg {
        .main_area {
            padding: 40px 12px;
        }
    }
}
</style>