var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "header-bar" } }, [
    _c("div", { staticClass: "left_area" }, [
      _c(
        "div",
        {
          staticClass: "menu_btn",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.menuOpen.apply(null, arguments)
            },
          },
        },
        [_c("span", { staticClass: "menu icon" })]
      ),
    ]),
    _c("div", { staticClass: "center_area" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right_area" }, [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }