var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goodsPaymentEdit" }, [
    _c("table", [
      _c("tr", [
        _c("td", { staticClass: "fixedcell" }, [_vm._v("領収日：")]),
        _c("td", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.payDateEx,
                expression: "payDateEx",
              },
            ],
            staticClass: "paydate",
            attrs: { type: "date" },
            domProps: { value: _vm.payDateEx },
            on: {
              change: _vm.paydate_change,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.payDateEx = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _c("tr", [
        _c("td", { staticClass: "fixedcell" }, [_vm._v("領収先：")]),
        _c("td", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.receiptNameEx,
                expression: "receiptNameEx",
              },
            ],
            staticClass: "receiptName",
            attrs: { type: "text" },
            domProps: { value: _vm.receiptNameEx },
            on: {
              change: _vm.receiptName_change,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.receiptNameEx = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _c("tr", [
        _c("td", { staticClass: "fixedcell" }, [_vm._v("品目：")]),
        _c("td", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.itemNameEx,
                expression: "itemNameEx",
              },
            ],
            staticClass: "itemName",
            attrs: { type: "text" },
            domProps: { value: _vm.itemNameEx },
            on: {
              change: _vm.itemName_change,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.itemNameEx = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _c("tr", [
        _c("td", { staticClass: "fixedcell" }, [_vm._v("金額：")]),
        _c("td", { staticClass: "money_area" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.moneyEx,
                expression: "moneyEx",
              },
            ],
            staticClass: "money",
            attrs: { type: "text" },
            domProps: { value: _vm.moneyEx },
            on: {
              focus: _vm.money_focus,
              blur: _vm.money_blur,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.moneyEx = $event.target.value
              },
            },
          }),
          _c("span", [_vm._v("円")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }