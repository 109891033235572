<template>
    <div id="header-bar">
        <div class="left_area">
            <div class="menu_btn" @click.stop="menuOpen"><span class='menu icon'></span></div>
        </div>
        <div class="center_area">{{title}}</div>
        <div class="right_area">
            <span></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {type:String, default:''}
    },
    data() {
        return {
        }
    },
    methods: {
        menuOpen() {
            this.$emit('menuOpen');
        }
    }
}
</script>

<style lang="less" scoped>
#header-bar {
    position: fixed;
    width: 100%;
    height: 50px;
    background-color: var(--baseColor);
    box-shadow: 0 2px 10px 4px rgba(0,0,0,0.2);
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    z-index: 9999;
    
    .left_area, .center_area, .right_area {
        color: white;
        font-weight: bold;
    }

    .left_area {
        font-size: 1rem;
        text-align: left;
        vertical-align: middle;
        white-space: nowrap;
        
        .menu_btn {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            margin-top: 15px;
        }

        .menu.icon {
			color: #fff;
            position: absolute;
            margin-top: 7.5px;
			width: 20px;
			height: 2px;
			background-color: currentColor;
			left: 10px;
		}

		.menu.icon:before {
			content: '';
			position: absolute;
			top: -6px;
			left: 0;
			width: 20px;
			height: 2px;
			background-color: currentColor;
		}

		.menu.icon:after {
			content: '';
			position: absolute;
			top: 6px;
			left: 0;
			width: 20px;
			height: 2px;
			background-color: currentColor;
		}
    }

    .center_area {
        display: flex;
        text-align: center;
        align-items: center;
		font-size: 1.2rem;
    }

    .right_area {
        font-size: 1rem;
        vertical-align: middle;
        white-space: nowrap;
    }
}
</style>