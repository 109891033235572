var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "holidaysPg" } }, [
    _c("div", { staticClass: "main_area" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "year_area" }, [
          _c(
            "span",
            {
              staticClass: "btnMoveYearPre",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.mvYear(-1)
                },
              },
            },
            [_vm._v("＜")]
          ),
          _c("span", { staticClass: "year" }, [
            _vm._v(_vm._s(_vm.activeYear) + "年"),
          ]),
          _c(
            "span",
            {
              staticClass: "btnMoveYearNext",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.mvYear(1)
                },
              },
            },
            [_vm._v("＞")]
          ),
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.cancel.apply(null, arguments)
                },
              },
            },
            [_vm._v("閉じる")]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "info_area" },
        _vm._l(12, function (m) {
          return _c("holiCalen", {
            key: m,
            staticClass: "calen_area",
            attrs: { year: _vm.activeYear, month: m, days: _vm.holidays },
            on: { cellClick: _vm.cell_click },
          })
        }),
        1
      ),
      _c("div", { staticClass: "footer" }, [
        _c(
          "button",
          {
            staticClass: "button cancel",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.cancel.apply(null, arguments)
              },
            },
          },
          [_vm._v("閉じる")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }