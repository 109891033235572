<template>
    <span class="ui-datetime-org">
        <input type="date"
            :value="value" 
            :name="name" 
            :class="className" 
            :readonly="readonly" 
            :autocomplete="autocomplete" 
            :max="max" 
            :min="min" 
            :step="step" 
            :required="required" 
            :list="list"
            @change="change"
            @input="input"
        />
        <select class="select_hours"
            :readonly="readonly" 
            :required="required"
            :value="hour"
            @change="change"
            @input="input">
            <option v-for="(h, i) in hours" :key="i">{{h}}</option>
        </select>:
        <select class="select_minutes"
            :readonly="readonly" 
            :required="required"
            :value="minut"
            @change="change"
            @input="input">
            <option v-for="(m, i) in minutes" :key="i">{{m}}</option>
        </select>
    </span>
</template>

<script>
export default {
    name: "ui-datetime-org",
    props: {
        name: {type:String, default:""},
        source: {type:String, default:""},
        max: {type:String, default:""},
        min: {type:String, default:""},
        step: {type:String, default:""},
        readonly: {type:Boolean, default: false},
        required: {type:Boolean, default: false},
        autocomplete: {type:String, default:"default"},
        list: {type:String, default:""},
        className: {type:String, default:undefined}
    },
    data() {
        return {
            value: "",
            hours: ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
            minutes: ["00","30"],
            hour: "",
            minut: ""
        }
    },
    watch: {
        source(n, o) {
            this.value = n.split(" ")[0];
            if (n.split(" ").length > 1) {
                let hm = n.split(" ")[1];
                this.hour = hm.split(":")[0];
                this.minut = hm.split(":")[1];
            }
        }
    },
    mounted() {
        for (let i=0; i<24; i++) {
            this.hours.push()
        }
        this.value = this.source.split(" ")[0];
        if (this.source.split(" ").length > 1) {
            let hm = this.source.split(" ")[1];
            this.hour = hm.split(":")[0];
            this.minut = hm.split(":")[1];
        }
    },
    methods: {
        change(e) {
            let val = e.target.value;
            
            if (e.target.tagName == "INPUT") {
                this.value = val;
                val = val + "T" + this.hour + ":" + this.minut;
            } else if (e.target.tagName == "SELECT") {
                if (e.target.classList.contains("select_hours")) {
                    this.hour = val;
                    val = this.value + "T" + val + ":" + this.minut;
                } else if (e.target.classList.contains("select_minutes")) {
                    this.minut = val;
                    val = this.value + "T" + this.hour + ":" + val;
                }
            }

            if (this.value == "" || this.hour == "" || this.minut == "") {
                return;
            }

            this.$emit('change', val);
        },
        input(e) {
            let val = e.target.value;

            if (e.target.tagName == "INPUT") {
                val = val + "T" + this.hour + ":" + this.minut;
            } else if (e.target.tagName == "SELECT") {
                if (e.target.classList.contains("select_hours")) {
                    val = this.value + "T" + val + ":" + this.minut;
                } else if (e.target.classList.contains("select_minutes")) {
                    val = this.value + "T" + this.hour + ":" + val;
                }
            }

            if (this.value == "" || this.hour == "" || this.minut == "") {
                return;
            }
            
            this.$emit('input', e);
        }
    }
}
</script>

<style lang="less">
.ui-datetime-org {
    .select_hours {
        margin: 0 4px;
    }
}
</style>