<template>
    <tr class="acmanaged-row" :class="{'enabled':useMode}">
        <td class="edit" @click.stop="edit_click"><icon name="pen" class="icon"></icon></td>
        <td class="employNo">{{cvtEmploy(item.employNo)}}</td>
        <td class="name">{{item.name}}</td>
        <td class="age">{{item.age}}</td>
        <td class="hireDate">{{item.hireDate}}</td>
        <td class="kakutei">{{cvtKengen(item.authority)}}</td>
        <td class="syouka">{{item.email}}</td>
        <td></td>
    </tr>
</template>

<script>
export default {
    props: {
        item: null
    },
    computed: {
        useMode() {
            return (this.item.useFlag == 1) ? false : true;
        }
    },
    methods: {
        cvtEmploy(val) {
            if (val == 0) {
                val = "0001";
            } else {
                val = ((1000 + (val - 0)) + "");
            }
            return val;
        },
        cvtKengen(val) {
            let kengen = "";
            switch (val) {
                case 99:
                    kengen = "全権";
                    break;
                case 10:
                    kengen = "労務管理";
            }

            return kengen;
        },
        edit_click() {
            this.$emit('editClick', this.item.employNo);
        }
    }
}
</script>

<style lang="less">
.acmanaged-row {
    position: relative;

    td {
        padding: 6px 4px;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        white-space: nowrap;

        &:last-child {
            border-right: 1px solid black;
        }
    }

    .edit {
        cursor: pointer;

        .icon {
            width: 14px;
            fill: var(--foreColor);
        }
    }

    .edit, .hireDate, .age {
        text-align: center;
    }
}
.acmanaged-row.enabled {
    background-color: var(--grayoutBackColor);
}
</style>