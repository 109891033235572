var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    { staticClass: "sheetmng-row", class: { enabled: _vm.useMode } },
    [
      _c(
        "td",
        {
          staticClass: "edit",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.edit_click.apply(null, arguments)
            },
          },
        },
        [_c("icon", { staticClass: "icon", attrs: { name: "pen" } })],
        1
      ),
      _c("td", { staticClass: "code" }, [_vm._v(_vm._s(_vm.item.code))]),
      _c("td", { staticClass: "name" }, [_vm._v(_vm._s(_vm.item.name))]),
      _c("td", { staticClass: "startDay" }, [
        _vm._v(_vm._s(_vm.item.startDay) + "日"),
      ]),
      _c("td", { staticClass: "holidays" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.holidays_click.apply(null, arguments)
              },
            },
          },
          [_vm._v("編集")]
        ),
      ]),
      _c("td", [_vm._v(_vm._s(_vm.item.comment))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }