<template>
    <div class="trafficPaymentEdit">
        <table>
            <tr>
                <td class="fixedcell">日付：</td>
                <td><input class="paydate" type="date" v-model="payDateEx" @change="paydate_change"/></td>
            </tr>
            <tr>
                <td class="fixedcell">訪問先：</td>
                <td><input class="destination" type="text" v-model="destinationEx" @change="destination_change"/></td>
            </tr>
            <tr>
                <td class="fixedcell">交通機関：</td>
                <td><input class="facilities" type="text" v-model="facilitiesEx" @change="facilities_change"/></td>
            </tr>
            <tr>
                <td colspan="2"><input class="from" type="text" v-model="fromEx" @change="from_change"/> &rarr; <input class="to" type="text" v-model="toEx" @change="to_change"/></td>
            </tr>
            <tr>
                <td class="fixedcell">金額：</td>
                <td class="money_area"><input class="money" type="text" v-model="moneyEx" @focus="money_focus" @blur="money_blur"/><span>円</span></td>
            </tr>
        </table>
    </div>
</template>

<script>
import Vue from 'vue';
export default {
    name: "trafficPaymentEdit",
    props: {
        payDate: { type: String, default: "" },
        destination: { type: String, default: "" },
        facilities: { type: String, default: "" },
        from: { type: String, default: "" },
        to: { type: String, default: "" },
        money: { type: String, default: "" }
    },
    data() {
        return {
            payDateEx: "",
            destinationEx: "",
            facilitiesEx: "",
            fromEx: "",
            toEx: "",
            moneyEx: "",
            preMoney: ""
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.payDateEx = this.payDate;
            this.destinationEx = this.destination;
            this.facilitiesEx = this.facilities;
            this.fromEx = this.from;
            this.toEx = this.to;
            this.moneyEx = this.getAmount(this.money);
        });
    },
    methods: {
        money_focus() {
            this.moneyEx = this.moneyEx.replace(",", "");
            this.preMoney = this.moneyEx;
        },
        money_blur() {
            let money = this.moneyEx.replace(",", "");
            this.moneyEx = this.getAmount(money);
            if (money != this.preMoney) {
                this.$emit("change_money", money);
            }
        },
        paydate_change() {
            this.$emit("paydate_change", this.payDateEx);
        },
        destination_change() {
            this.$emit("destination_change", this.destinationEx);
        },
        facilities_change() {
            this.$emit("facilities_change", this.facilitiesEx);
        },
        from_change() {
            this.$emit("from_change", this.fromEx);
        },
        to_change() {
            this.$emit("to_change", this.toEx);
        },
        getAmount(val) {
            return Vue.$utils.convertCurrency(val);
        }
    }
}
</script>

<style lang="less" scoped>
.trafficPaymentEdit {
    width: 100%;
    border: 1px solid #999;
    border-radius: 6px;
    padding: 4px;

    table {
        width: 100%;
    }

    .paydate {
        width: 114px;
        height: 28px;
    }

    .destination, .facilities {
        width: 100%;
        height: 28px;
    }

    .from, .to {
        width: calc(50% - 13px);
        height: 28px;
    }

    .money_area {

        .money {
            width: 100px;
            height: 28px;
            text-align: right;
        }

        span {
            margin-left: 4px;
        }
    }

    .fixedcell {
        text-align: right;
        width: 90px;
    }
}
</style>