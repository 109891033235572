var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { on_menu: _vm.onmenuFlg },
      attrs: { id: "sideMenu" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.empty_click.apply(null, arguments)
        },
      },
    },
    [
      _vm.visibleCheck("stamping")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("stamping")
                },
              },
            },
            [_vm._v("打刻")]
          )
        : _vm._e(),
      _vm.visibleCheck("record")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("record")
                },
              },
            },
            [_vm._v("出勤簿")]
          )
        : _vm._e(),
      _vm.visibleCheck("applyLeave")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("applyLeave")
                },
              },
            },
            [_vm._v("休暇申請")]
          )
        : _vm._e(),
      _vm.visibleCheck("substituteHoliday")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("substituteHoliday")
                },
              },
            },
            [_vm._v("振替休日")]
          )
        : _vm._e(),
      _vm.visibleCheck("applyPayment")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("applyPayment")
                },
              },
            },
            [_vm._v("精算申請")]
          )
        : _vm._e(),
      _vm.visibleCheck("control")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("control")
                },
              },
            },
            [_vm._v("出勤簿管理")]
          )
        : _vm._e(),
      _vm.visibleCheck("leaveApproval")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("leaveApproval")
                },
              },
            },
            [_vm._v("休暇承認")]
          )
        : _vm._e(),
      _vm.visibleCheck("paymentApproval")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("paymentApproval")
                },
              },
            },
            [_vm._v("精算承認")]
          )
        : _vm._e(),
      _vm.visibleCheck("staff")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("staff")
                },
              },
            },
            [_vm._v("スタッフ管理")]
          )
        : _vm._e(),
      _vm.visibleCheck("sheet")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("sheet")
                },
              },
            },
            [_vm._v("シート管理")]
          )
        : _vm._e(),
      _vm.visibleCheck("accountInfo")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("accountInfo")
                },
              },
            },
            [_vm._v("アカウント")]
          )
        : _vm._e(),
      _vm.visibleCheck("config")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("config")
                },
              },
            },
            [_vm._v("設定")]
          )
        : _vm._e(),
      _vm.visibleCheck("logoff")
        ? _c(
            "div",
            {
              staticClass: "menu",
              on: {
                click: function ($event) {
                  return _vm.menu_click("logoff")
                },
              },
            },
            [_vm._v("ログオフ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }