<template>
    <div id="configPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
            </div>
            <!-- メイン -->
            <div class="main">
                <div class="styleMode">
                    外観モード：
                    <label><input type="radio" v-model="configDb.darkMode" value="light" @change.stop="changeScheme"/>ライト</label>
                    <label><input type="radio" v-model="configDb.darkMode" value="dark" @change.stop="changeScheme"/>ダーク</label>
                    <label><input type="radio" v-model="configDb.darkMode" value="" @change.stop="changeScheme"/>自動</label>
                </div>
            </div>
            <!-- フッター -->
            <div class="footer">
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "configPg",
    mixins: [utilityMixin],
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov) {
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        },
        "configDb.darkMode" (nv, ov) {
            console.log("config_watch", nv);
            this.save();
        }
    },
    data() {
        return {
            headerTitle: "設定",
            configDb: {
                darkMode: ""
            }
        }
    },
    created() {
        if (localStorage.getItem('config')) {
            try {
                this.configDb = JSON.parse(localStorage.getItem('config'));
                console.log("config_created", this.configDb.darkMode);
            } catch(e) {
                localStorage.removeItem('config');
            }
        }
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");

            

        });
    },
    methods: {
        changeScheme() {
            console.log("config_changeScheme", this.configDb.darkMode);
            this.$store.commit("config_darkMode", this.configDb.darkMode);
        },
        save() {
            console.log("config_save", this.configDb.darkMode)
            const parsed = JSON.stringify(this.configDb);
            localStorage.setItem('config', parsed);
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#configPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .main {
            .styleMode {
                label {
                    margin: 0 3px 0 5px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        .footer {
            clear: both;
            text-align: center;
            padding-top: 22px;

            .hensyu, .save, .cancel, .delete {
                width: 180px;
            }

            .cancel {
                margin-left: 20px;
            }

            .delete {
                margin-left: 20px;
                background-color: red;
                border: 2px solid red;
                color: white;
            }
        }
    }

    .button {
        padding: 3px 12px;
        font-weight: bold;
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:1027px) {
    #configPg {
        .main_area {
            .main {
                
            }
        }
    }
}
</style>