<template>
    <div class="goodsPaymentEdit">
        <table>
            <tr>
                <td class="fixedcell">領収日：</td>
                <td><input class="paydate" type="date" v-model="payDateEx" @change="paydate_change"/></td>
            </tr>
            <tr>
                <td class="fixedcell">領収先：</td>
                <td><input class="receiptName" type="text" v-model="receiptNameEx" @change="receiptName_change"/></td>
            </tr>
            <tr>
                <td class="fixedcell">品目：</td>
                <td><input class="itemName" type="text" v-model="itemNameEx" @change="itemName_change"/></td>
            </tr>
            <tr>
                <td class="fixedcell">金額：</td>
                <td class="money_area"><input class="money" type="text" v-model="moneyEx" @focus="money_focus" @blur="money_blur"/><span>円</span></td>
            </tr>
        </table>
    </div>
</template>

<script>
import Vue from 'vue';
export default {
    name: "goodsPaymentEdit",
    props: {
        payDate: { type: String, default: "" },
        receiptName: { type: String, default: "" },
        itemName: { type: String, default: "" },
        money: { type: String, default: "" }
    },
    data() {
        return {
            payDateEx: "",
            receiptNameEx: "",
            itemNameEx: "",
            moneyEx: "",
            preMoney: ""
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.payDateEx = this.payDate;
            this.receiptNameEx = this.receiptName;
            this.itemNameEx = this.itemName;
            this.moneyEx = this.getAmount(this.money);
        });
    },
    methods: {
        money_focus() {
            this.moneyEx = this.moneyEx.replace(",", "");
            this.preMoney = this.moneyEx;
        },
        money_blur() {
            let money = this.moneyEx.replace(",", "");
            this.moneyEx = this.getAmount(money);
            if (money != this.preMoney) {
                this.$emit("change_money", money);
            }
        },
        paydate_change() {
            this.$emit("paydate_change", this.payDateEx);
        },
        receiptName_change() {
            this.$emit("receiptName_change", this.receiptNameEx);
        },
        itemName_change() {
            this.$emit("itemName_change", this.itemNameEx);
        },
        getAmount(val) {
            return Vue.$utils.convertCurrency(val);
        }
    }
}
</script>

<style lang="less" scoped>
.goodsPaymentEdit {
    width: 100%;
    border: 1px solid #999;
    border-radius: 6px;
    padding: 4px;

    table {
        width: 100%;
    }

    .paydate {
        width: 114px;
        height: 28px;
    }

    .receiptName, .itemName {
        width: 100%;
        height: 28px;
    }

    .from, .to {
        width: calc(50% - 13px);
        height: 28px;
    }

    .money_area {

        .money {
            width: 100px;
            height: 28px;
            text-align: right;
        }

        span {
            margin-left: 4px;
        }
    }

    .fixedcell {
        text-align: right;
        width: 90px;
    }
}
</style>