var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "applyTrafficPaymentEdit" } }, [
    _c(
      "div",
      {
        staticClass: "backBtn",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.backPage()
          },
        },
      },
      [
        _c("icon", { staticClass: "icon", attrs: { name: "chevron-left" } }),
        _vm._v(" 一覧"),
      ],
      1
    ),
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "date_area" }, [
        _vm._v("申請日："),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.date,
              expression: "date",
            },
          ],
          attrs: { type: "date" },
          domProps: { value: _vm.date },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.date = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "total_area" }, [
        _vm._v("合計："),
        _c("span", { staticClass: "total" }, [
          _vm._v(_vm._s(_vm.getAmount(_vm.total))),
        ]),
        _c("span", { staticClass: "unit" }, [_vm._v("円")]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "detail_list" },
      [
        _vm._l(_vm.details, function (dat, ix) {
          return _c("item", {
            key: ix,
            staticClass: "detail",
            attrs: {
              payDate: dat.payDate,
              destination: dat.destination,
              facilities: dat.facilities,
              from: dat.from,
              to: dat.to,
              money: _vm.getMoney(dat.money),
            },
            on: {
              paydate_change: function ($event) {
                return _vm.paydate_change($event, ix)
              },
              destination_change: function ($event) {
                return _vm.destination_change($event, ix)
              },
              facilities_change: function ($event) {
                return _vm.facilities_change($event, ix)
              },
              from_change: function ($event) {
                return _vm.from_change($event, ix)
              },
              to_change: function ($event) {
                return _vm.to_change($event, ix)
              },
              change_money: function ($event) {
                return _vm.change_money($event, ix)
              },
            },
          })
        }),
        _c("div", { staticClass: "button_area" }, [
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.save_traffic.apply(null, arguments)
                },
              },
            },
            [_vm._v("保存")]
          ),
        ]),
        !_vm.checkNewData
          ? _c("div", { staticClass: "button_area" }, [
              _c(
                "button",
                {
                  staticClass: "pdf_print",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.downloadBtn()
                    },
                  },
                },
                [_vm._v("PDF出力")]
              ),
            ])
          : _vm._e(),
        !_vm.checkNewData
          ? _c("div", { staticClass: "button_area deleteBtn" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.delete_traffic.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("削除")]
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }