var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "LeaveConfirmationPg" } }, [
    _c("div", { staticClass: "dummy" }),
    _c(
      "div",
      { key: _vm.dummyKey, staticClass: "cards_area" },
      [
        _c("card", {
          staticClass: "card",
          attrs: {
            accountId: _vm.accountId,
            leaveId: _vm.leaveId,
            timestamp: _vm.timestamp,
            start: _vm.start,
            end: _vm.end,
            leaveType: _vm.leaveType,
            specialleave: _vm.specialleave,
            comment: _vm.comment,
            remandComment: _vm.remandComment,
            applydate: _vm.applydate,
            status: _vm.status,
            leaveTypeList: _vm.leaveTypeList,
            specialleaveList: _vm.specialleaveList,
            zanYukyu: _vm.zanYukyu,
            select: false,
            visible: true,
            approval: true,
            top: 40,
            zindex: 9990,
          },
          on: {
            card_update: function ($event) {
              return _vm.card_update($event)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }