<template>
    <tr class="managed-row" @click.stop="openRecord()">
        <td class="pdf" @click.stop="empty_click"><input type="checkbox" v-model="item.checked"/></td>
        <td class="employNo">{{cvtEmploy(item.employNo)}}</td>
        <td class="name">{{item.name}}</td>
        <td class="kakutei">{{cvtKakutei(item.kakuteiFlg)}}</td>
        <td class="syonin">{{cvtSyonin(item.syoninFlg)}}</td>
        <td class="syouka">{{item.syouka}}</td>
        <td class="zan">{{item.zan}}</td>
        <td class="hireDate">{{item.hireDate}}</td>
        <td class="sheetreki" @click.stop="sheet_click"><icon name="pen" class="icon"></icon></td>
        <td></td>
    </tr>
</template>

<script>
export default {
    props: {
        item: null
    },
    watch: {
        'item.checked'(nv, ov) {
            this.$emit('changeChecked', nv);
        }
    },
    methods: {
        cvtEmploy(val) {
            if (val == 0) {
                val = "0001";
            } else {
                val = ((1000 + (val - 0)) + "");
            }
            return val;
        },
        cvtKakutei(val) {
            val = (val == "1") ? true : false;
            return (val == true) ? "◯" : ""
        },
        cvtSyonin(val) {
            val = (val == "1") ? true : false;
            return (val == true) ? "◯" : ""
        },
        openRecord() {
            this.$emit('openRecord', this.item.employNo);
        },
        sheet_click() {
            this.$emit('openSheetReki', this.item.employNo);
        },
        empty_click() {

        }
    }
}
</script>

<style lang="less">
.managed-row {
    position: relative;

    td {
        padding: 6px 4px;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        white-space: nowrap;

        &:last-child {
            border-right: 1px solid black;
        }
    }

    .pdf, .kakutei, .hireDate, .syonin, .age, .sheetreki {
        text-align: center;
    }

    .syouka, .zan {
        text-align: right;
    }

    .sheetreki {
        cursor: pointer;

        .icon {
            width: 14px;
            fill: var(--foreColor);
        }
    }
}
</style>