var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "samplePg" } }, [
    _c("div", { staticClass: "main_area" }, [
      _c("div", { staticClass: "header" }),
      _c("div", { staticClass: "main" }, [
        _vm._m(0),
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
        _vm._m(5),
        _vm._m(6),
        _c(
          "div",
          { staticClass: "ui-area" },
          [
            _vm._v(" ui-date："),
            _c("ui-date", {
              attrs: { source: _vm.vdate, className: "ui-date-class" },
              on: { change: _vm.change_ui_date },
            }),
            _vm._v(" (SafariはNG) "),
          ],
          1
        ),
        _vm._m(7),
        _vm._m(8),
        _c(
          "div",
          { staticClass: "ui-area" },
          [
            _vm._v(" ui-datetime："),
            _c("ui-datetime", {
              attrs: { source: _vm.vdatetime, className: "ui-datetime-class" },
              on: { change: _vm.change_ui_datetime },
            }),
            _vm._v(" (Safari, FireFoxはNG) "),
          ],
          1
        ),
        _vm._m(9),
        _c(
          "div",
          { staticClass: "ui-area" },
          [
            _vm._v(" ui-datetime-local："),
            _c("ui-datetime-local", {
              attrs: {
                source: _vm.vdatetimelocal,
                className: "ui-datetimelocal-class",
              },
              on: { change: _vm.change_ui_datetime_local },
            }),
            _vm._v(" (Safari, FireFoxはNG) "),
          ],
          1
        ),
        _vm._m(10),
        _vm._m(11),
        _vm._m(12),
        _vm._m(13),
        _vm._m(14),
        _vm._m(15),
        _vm._m(16),
        _vm._m(17),
        _c("hr", { staticStyle: { margin: "20px 0" } }),
        _vm._m(18),
        _c("hr", { staticStyle: { margin: "20px 0" } }),
        _vm._m(19),
      ]),
      _c("div", { staticClass: "footer" }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" text："),
      _c("input", { attrs: { type: "text" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" password："),
      _c("input", { attrs: { type: "password" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" search："),
      _c("input", { attrs: { type: "search" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" email："),
      _c("input", { attrs: { type: "email" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" tel："),
      _c("input", { attrs: { type: "tel" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" url："),
      _c("input", { attrs: { type: "url" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" date："),
      _c("input", { attrs: { type: "date" } }),
      _vm._v(" ※使わない (SafariはNG) "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" time："),
      _c("input", { attrs: { type: "time" } }),
      _vm._v(" (SafariはNG) "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" datetime："),
      _c("input", { attrs: { type: "datetime" } }),
      _vm._v(" ※使わない (Safari, FireFoxはNG) "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" datetime-local："),
      _c("input", { attrs: { type: "datetime-local" } }),
      _vm._v(" ※使わない (Safari, FireFoxはNG) "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" month："),
      _c("input", { attrs: { type: "month" } }),
      _vm._v(" (Safari, FireFoxはNG) "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" week："),
      _c("input", { attrs: { type: "week" } }),
      _vm._v(" (Safari, iOS版Safari, FireFoxはNG) "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" number："),
      _c("input", { attrs: { type: "number" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" range："),
      _c("input", { attrs: { type: "range" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" color："),
      _c("input", { attrs: { type: "color" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" checkbox："),
      _c("input", { attrs: { type: "checkbox" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" radio："),
      _c("input", { attrs: { type: "radio" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" file："),
      _c("input", { attrs: { type: "file" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" select："),
      _c("select", [
        _c("option", [_vm._v("オプション１")]),
        _c("option", [_vm._v("オプション２")]),
        _c("option", [_vm._v("オプション３")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-area" }, [
      _vm._v(" textarea："),
      _c("textarea"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }