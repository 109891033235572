<template>
    <div id="applyLeavePg">
        <div class="dummy"></div>
        <div class="list_area" :key="dummyKey">
            <div class="edit_tool_area" v-if="cardSelected || newFlag">
                <div class="compliteBtn text_button" @click="compliteBtn_click">完了</div>
            </div>
            <div class="cards_area" :class="{'edited':(cardSelected || newFlag)}" :style="`height:${cardAreaHeight}px`">
                <div v-if="cards.length == 0" class="empty_area">表示できる休暇申請はありません</div>
                <card class="card" v-for="(card, idx) in cards" :key="idx"
                    :accountId="accountId"
                    :leaveId="card.leaveId"
                    :timestamp="card.timestamp"
                    :start="card.start"
                    :end="card.end"
                    :leaveType="card.leaveType"
                    :specialleave="card.specialleave"
                    :comment="card.comment"
                    :remandComment="card.remandComment"
                    :applydate="card.applydate"
                    :status="card.status"
                    :leaveTypeList="leaveTypeList"
                    :specialleaveList="specialleaveList"
                    :zanYukyu="zanYukyu"
                    :select="card.select"
                    :visible="card.visible"
                    :top="card.top"
                    :zindex="card.zindex"
                    @card_update="card_update($event, idx)"
                    @card_click="card_click($event, idx)"
                    @card_delete="card_delete">
                </card>
                <card class="newCard" v-if="newFlag"
                    :accountId="accountId"
                    :start="newData.start"
                    :end="newData.end"
                    :leaveType="newData.leaveType"
                    :specialleave="newData.specialleave"
                    :comment="newData.comment"
                    :remandComment="''"
                    :leaveTypeList="leaveTypeList"
                    :specialleaveList="specialleaveList"
                    :zanYukyu="zanYukyu"
                    :newFlag="true"
                    :top="40"
                    :zindex="9990"
                    :key="newCardKey"
                    @card_update="card_update($event, -1)">
                </card>
            </div>
            <div class="newCreateBtn" v-if="!newFlag && !cardSelected" @click.stop="createCard">+</div>
        </div>
    </div>

</template>

<script>
import Vue from 'vue';
import card from '@/components/leave-card.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: 'applyLeavePg',
    mixins: [utilityMixin],
    components: {
        'card': card,
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        },
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },

    data() {
        return {
            accountId: "",
            cardSelected: false,
            activeCardIndex: -1,
            cardAreaHeight: "",
            newFlag: false,
            newCardKey: "key0",
            newCardKeyNum: 0,
            cards: [],
            newData: {
                start: "",
                end: "",
                leaveType: "",
                specialleave: "",
                comment: ""
            },
            commitData: {
                start: "",
                end: "",
                leaveType: "",
                specialleave: "",
                comment: "",
                timestamp: ""
            },
            leaveTypeList: [
                {name: "有給休暇", code: 1},
                {name: "特別休暇", code: 2},
            ],
            specialleaveList: [],
            zanYukyu: "",
            dummyKey: "dkey0",
            dummyKeyNum: 0
        };
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", "休暇申請");
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;

            this.getInit();
        });
    },
    methods: {
        async getInit() {
            await this.getSettingData();
            await this.getCardList();
        },
        async getSettingData() {
            try {
                const ress = await Vue.$cache.get('leave/setting/', undefined, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.specialleaveList = ress.data.data.specialleaveList;
                        this.dummyKeyNum += 1;
                        this.dummyKey = "dkey" + this.dummyKeyNum;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async getCardList() {
            try {

                this.cardSelected = false;
                this.newFlag = false;
                this.reset_newData();
                this.activeCardIndex = -1;
                this.cards = [];

                const param = {
                    accountId: this.accountId
                };

                const ress = await Vue.$cache.post('leave/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        let zan = ress.data.data.yukyuZanCnt + "";
                        let day = zan.split(".")[0] - 0;
                        this.zanYukyu = day + "日";
                        if (zan.split(".").length > 1) {
                            let whm = zan - day;
                            let wt = 28800000 * whm;
                            let dd = new Date();
                            dd.setTime(wt - 32400000);
                            let h = dd.getHours();
                            let m = dd.getMinutes();
                            h = h + (m / 60);
                            this.zanYukyu = this.zanYukyu + "と" + h + "時間";
                        }

                        ress.data.data.list.forEach((item) => {
                            let card = {};

                            card.leaveId = item.leaveId;
                            card.start = item.startDate;
                            if (item.startTime != "") {
                                card.start = card.start + " " + item.startTime.split(":")[0] + ":" + item.startTime.split(":")[1];
                            }
                            card.end = item.endDate;
                            if (item.leaveTimes != "") {
                                card.end = item.leaveTimes + "";
                            }
                            card.leaveType = item.leaveType + "";
                            card.specialleave = item.specialleave;
                            card.applydate = item.requestDateTime;
                            card.comment = item.comment;
                            card.remandComment = item.remandComment;
                            card.status = item.status + "";
                            card.timestamp = item.timestamp;
                            this.cards.push(card);
                        });

                        let hed = 617 - (48 * this.cards.length) - 80;
                        if (hed < 40) { hed = 40; }
                        this.cards.forEach((item, i) => {
                            item.select = false;
                            item.zindex = (6000 + i);
                            item.top = (48 * i) + hed;
                            item.visible = true;
                        });

                        this.cardAreaHeight = (48 * this.cards.length) + 80 + hed;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        createCard() {
            this.cardAreaHeight = "";
            this.cards.forEach((itm, ix) => {
                itm.visible = false;
            });
            this.reset_newData();
            this.newCardKey = "key0";
            this.newFlag = true;
        },
        card_update(dat, i) {
            this.getCardList();
        },
        card_click(e, i) {
            if (this.cards[i].select) {
                return;
            }
            
            this.cards.forEach((itm, ix) => {
                itm.select = false
                itm.zindex = 6000 + ix;
                itm.top = (48 * ix) + 40;
                itm.visible = false;
            });
            this.cards[i].select = true;
            this.cards[i].zindex = 9990;
            this.cards[i].top = 40;
            this.cards[i].visible = true;
            this.cardSelected = true;
            this.cardAreaHeight = "";
            this.activeCardIndex = i;
        },
        card_delete() {
            this.getCardList();
        },
        compliteBtn_click() {
            this.getCardList();
        },
        reset_newData() {
            this.newData = {
                start: "",
                end: "",
                leaveType: "1",
                specialleave: "",
                comment: ""
            }
        },
        base_click() {
            //--
        },
    }
}
</script>

<style lang="less">
#applyLeavePg {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50px auto;

    .list_area {
        width: 100%;
        overflow: auto;

        .cards_area {
            position: relative;
            width: 100%;
            min-height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column-reverse;

            .empty_area {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: space-around;
                align-items: center;
                font-weight: bold;
            }
        }

        .cards_area.edited {
            height: 100%;
        }

        .edit_tool_area {
            position: absolute;
            top: 60px;
            left: 20px;
            font-size: 0.9rem;
            font-weight: bold;
            z-index: 1;
        }

        .newCreateBtn {
            position: absolute;
            top: 56px;
            right: 20px;
            width: 28px;
            height: 28px;
            border: 1px solid #5c5c5c;
            border-radius: 50%;
            background-color: var(--foreColor);
            color: var(--backColor);
            font-weight: bold;
            font-size: 1.1rem;
            text-align: center;
            box-shadow: 1px 0px 3px 3px rgba(0,0,0,0.2);
            cursor: pointer;
            z-index: 9989;
        }
    }

    .main_area {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 78px 15%;
        overflow: hidden;

        .info_area {

            .detail_area {

                .titlecell {
                    display: inline;
                    float: left;
                    clear: both;
                    width: 160px;
                    text-align: right;
                    padding-right: 20px;
                    font-weight: bold;
                    margin: 10px 0 8px;
                }
                
                .remarksText {
                    width: 300px;
                    height: 120px;
                    border-radius: 3px;
                }

                .datacell {
                    display: inline;
                    float: left;
                    width: 360px;
                    margin: 8px 0;
                    border-bottom: 1px solid #CECECE;

                    .leaveSelect {
                        width: 155px;
                        height: 27px;
                        border-radius: 2px;
                    }

                    input[type='radio'] {
                        margin: 0 4px;
                    }

                    .numberTextBox {
                        width: 30px;
                        height: 25px;
                        border-radius: 3px;
                    }
                }
            }
        }

        .bottom_area {
            clear: both;
            position: relative;
            padding: 22px auto;
            text-align: center;
            // height: 100%;

            .delete {
                background-color: red;
                border: 2px solid red;
                color: white;
            }
        }

        .slide_list {

            .slide {
                background-color:rgb(175, 175, 175);
                position:absolute;
                right: 0;
                left: 0;
                margin: auto;
                padding: 15px 0;
                width: 70%;
                height: 320px;
                border: 1px solid #fff;
                border-radius: 15px;
                box-shadow: 0 4px 5px 5px rgba(0, 0, 0, .2);
                transform: translateY(160%);

                td {
                    padding-left: 8px;
                    line-height:23px;
                    text-align: left;
                }
                
                .applicationStatus {
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                }
            }
            .on_menu {
                padding-right: 0;
                z-index: 9998;
                
                transform: translateY(60%);
                transition: all .6s 0s ease;
            }

        }


        .shinki {
            margin-left: 25%;
            width: 40px;
            height: 40px;
            text-align: center;
            background-color: var(--baseColor);
            border-radius: 50%;
            font-size: 35px;
            color: #fff;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .2);
            user-select: none;

        }

        .hensyu {
            padding: 4px;
            margin-right: 10px;
            width: 40px;
            height: 40px;
            font-size: 22px;
            color: white;
            text-align: center;
            float: right;
            background: black;
            opacity: .5;
            border-radius: 12px;
            z-index: 9999;
        }

        .button {
            width: 180px;
            margin: 7px 10px ;
            padding: 3px 12px;
            font-weight: bold;
        }
    }
    .main_area.hidden_clear {
        overflow:scroll;
    }
}

#base_area {

    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:500px) {
    #applyLeavePg {
        .main_area {

            .info_area {

                .detail_area {

                    .remarksText {
                        display: block;
                        margin: 0 auto;
                        width: 100%;
                        height: 80px;
                    }

                    .titlecell {
                        float: none;
                        clear: none;
                        width: 160px;
                        text-align: left;
                    }

                    .datacell {
                        display: block;
                        float: none;
                        width: 100%;
                        margin: 8px 0 12px;
                    }
                }
            }

            .bottom_area {

                option {
                    font-size: 14px;
                }
                .delete {
                    background-color: red;
                    color: white;
                }                
                .menu_btn {
                    display: block;
                }
            }

            .slide_list {

                .slide {
                    width: 75%;
                    height: 300px;
                    padding-left: 10px;
                }

                td {
                    font-size: 12px;
                }
            }
            .on_menu {
                margin-top: 150px;
            }


            .shinki {
                margin-left: 5%;
            }
        }
    }
}
</style>