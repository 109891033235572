var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "paymentGoodsPg" } },
    [
      _vm._l(_vm.items, function (itm, ix) {
        return _c("div", { key: ix, staticClass: "receipt" }, [
          _c("div", {
            staticClass: "closed_btn",
            on: {
              click: function ($event) {
                return _vm.deleteReceipt(ix)
              },
            },
          }),
          _c("div", { staticClass: "image_area" }, [
            _c("div", {
              staticClass: "edit_btn",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.editImage($event, itm, ix)
                },
              },
            }),
            _c("img", {
              staticClass: "photo",
              attrs: { src: "data:*/*;base64," + itm.image },
              on: {
                click: function ($event) {
                  return _vm.openImage(itm.base64)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "row_area" }, [
            _c(
              "div",
              {
                staticClass: "cell_left",
                on: {
                  click: function ($event) {
                    return _vm.editField($event, itm, ix, "date")
                  },
                },
              },
              [_vm._v(_vm._s(itm.date))]
            ),
            _c(
              "div",
              {
                staticClass: "cell_right",
                on: {
                  click: function ($event) {
                    return _vm.editField($event, itm, ix, "destination")
                  },
                },
              },
              [_vm._v(_vm._s(itm.destination))]
            ),
          ]),
          _c("div", { staticClass: "row_area" }, [
            _c(
              "div",
              {
                staticClass: "cell_left",
                on: {
                  click: function ($event) {
                    return _vm.editField($event, itm, ix, "name")
                  },
                },
              },
              [_vm._v(_vm._s(itm.name))]
            ),
            _c(
              "div",
              {
                staticClass: "cell_right",
                on: {
                  click: function ($event) {
                    return _vm.editField($event, itm, ix, "amount")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.cvtAmount(itm.amount)))]
            ),
          ]),
          _c("div", { staticClass: "row_area" }, [
            _c(
              "div",
              {
                staticClass: "cell_left",
                class: { disabled_cmt: itm.comment == "" },
                on: {
                  click: function ($event) {
                    return _vm.editField($event, itm, ix, "comment")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.getComment(itm.comment)))]
            ),
          ]),
        ])
      }),
      _c("div", { staticClass: "footer" }, [
        _c("div", {
          staticClass: "complete_btn",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.completeData.apply(null, arguments)
            },
          },
        }),
        _c("div", {
          staticClass: "photo_btn",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.addPhoto.apply(null, arguments)
            },
          },
        }),
        _c("input", {
          staticStyle: { display: "none" },
          attrs: {
            type: "file",
            name: "photos",
            id: "photos",
            accept: "image/*;capture=camera",
          },
          on: { change: _vm.photos_select },
        }),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editFieldFlg,
              expression: "editFieldFlg",
            },
          ],
          ref: "edit_cell",
          staticClass: "edit_field",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.empty.apply(null, arguments)
            },
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.valEdit,
                expression: "valEdit",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.valEdit },
            on: {
              keyup: _vm.edit_keyup,
              keypress: _vm.edit_keypress,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.valEdit = $event.target.value
              },
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editDateFieldFlg,
              expression: "editDateFieldFlg",
            },
          ],
          ref: "edit_date",
          staticClass: "edit_field",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.empty.apply(null, arguments)
            },
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.valEdit,
                expression: "valEdit",
              },
            ],
            attrs: { type: "date" },
            domProps: { value: _vm.valEdit },
            on: {
              change: _vm.edit_change,
              keyup: _vm.edit_keyup,
              keypress: _vm.edit_keypress,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.valEdit = $event.target.value
              },
            },
          }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }