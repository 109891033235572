var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "base_area" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.hideMenu.apply(null, arguments)
        },
      },
    },
    [
      _c("headerBar", {
        attrs: { title: _vm.headerTitle },
        on: { menuOpen: _vm.menuOpen },
      }),
      _c("router-view", { key: _vm.paging }),
      _c("sideMenu", {
        attrs: {
          onmenuFlg: _vm.onmenuFlg,
          managerFlg: _vm.managerFlg,
          runingMode: _vm.runingMode,
        },
        on: { menu_click: _vm.menu_click },
      }),
      _vm.backPanelFlg ? _c("div", { staticClass: "back-panel" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }