export default {
    methods: {
        myouji1(name) {
            let result = ""
            if ((name == "") || (name == undefined)) return result;
            let ms = name.split("　");
            let res = ms[0];
            if (res.length <= 2) {
                result = res.substr(0, 1);
            } else {
                result = res.substr(0, 2);
            }
            return result;
        },
        myouji2(name) {
            let result = ""
            if ((name == "") || (name == undefined)) return result;
            let ms = name.split("　");
            let res = ms[0];
            if (res.length == 1) {
                //--
            } else if (res.length == 2) {
                result = res.substr(1);
            } else {
                result = res.substr(2);
            }
            return result;
        },
        loginActiveCheck(managerPage) {
            let result = true;

            let deviceCode = this.$cookies.get("DeviceCode") || '';
            let account = this.$cookies.get("Account") || '';
            let auth = this.$cookies.get("Authority") || '';
            if (account == "" || deviceCode == "") {
                let url = this.$router.currentRoute.fullPath;
                this.$store.dispatch("operator_logoutJump", url);
                this.$router.replace("/");
                result = false;
            }
            if (managerPage) {
                if ((auth != "99") && (auth != "10")) {
                    this.logout_init();
                    this.$router.replace("/");
                    result = false;
                }
            }

            return result;
        },
        logout_init() {
            this.$cookies.remove('Account');
            this.$cookies.remove("Authority");
            this.$store.commit("operator_activeAccountId", "");
            this.$store.commit("operator_activeYear", 0);
            this.$store.commit("operator_activeMonth", 0);
            this.$store.commit("operator_activeSheet", "");
            this.$store.dispatch("operator_logoutJump", "");
        }
    }
};