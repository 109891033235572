<template>
    <div id="sheetRekiPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <button class="addBtn" @click.stop="addNew">新規作成</button>
            </div>
            <!-- 表 -->
            <div class="list">
                <sheet-cell v-for="(item, ix) in items" :key="ix" :item="item" @editClick="editClick"></sheet-cell>
            </div>
            <!-- フッター -->
            <div class="footer">

            </div>
        </div>
        <div id="editorWindow" v-if="showEditor">
            <div class="header_area">
                <div class="close-win" @click="close_win">✕</div>
            </div>
            <div class="detail_area">
                <div class="tbl">
                    <div class="tblrow">
                        <div class="tblcell">シート：</div>
                        <div class="tblcell">
                            <select v-model="editSheetCode">
                                <option v-for="(itm, ix) in sheetList" :value="itm.code" :key="ix">{{itm.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="tblrow">
                        <div class="tblcell">開始日：</div>
                        <div class="tblcell"><input type="date" v-model="editStartDate"/></div>
                    </div>
                    <div class="tblrow">
                        <div class="tblcell">終了日：</div>
                        <div class="tblcell"><input type="date" v-model="editEndDate"/></div>
                    </div>
                    <div class="tblrow">
                        <div class="tblcell">オプション1：</div>
                        <div class="tblcell"><label><input type="checkbox" v-model="editOpcell1"/>&nbsp;使用する</label></div>
                    </div>
                    <div class="tblrow">
                        <div class="tblcell">集計欄8：</div>
                        <div class="tblcell"><label><input type="checkbox" v-model="editOpsyukei8"/>&nbsp;使用する</label></div>
                    </div>
                    <div class="tblrow">
                        <div class="tblcell">集計欄12：</div>
                        <div class="tblcell"><label><input type="checkbox" v-model="editOpsyukei12"/>&nbsp;使用する</label></div>
                    </div>
                    <div class="tblrow">
                        <div class="tblcell">オプション名：</div>
                        <div class="tblcell"><input type="text" v-model="editOptionTitleCode"/></div>
                    </div>
                </div>
            </div>
            <div class="footer_area">
                <button class="btnOk" @click.stop="editorOk">決定</button><button class="btnDel" v-if="selectCode!=''" @click.stop="deleteOk">削除</button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import cell  from '@/components/sheetrekiCell.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "sheetRekiPg",
    mixins: [utilityMixin],
    components: {
        'sheet-cell': cell
    },
    data() {
        return {
            accountId: "",
            activeAccountId: "",
            items: [],
            sheetList: [],
            showEditor: false,
            editSheetCode: "",
            editStartDate: "",
            editEndDate: "",
            editOpcell1: false,
            editOpsyukei8: false,
            editOpsyukei12: false,
            editOptionTitleCode: "",
            selectCode: ""
        }
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }
        
        this.$nextTick(function() {
            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;

            this.$store.commit("operator_headerTitle", "シート履歴");
            this.$store.dispatch("operator_baseClick", "");

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                this.items = [];
                this.sheetList = [];
                
                let param = {
					accountId: this.activeAccountId
                };
                let ress = await Vue.$cache.post('record/sheetreki/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.items = ress.data.data.list;
                        this.sheetList = ress.data.data.sheetList;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async setData() {
            try {
                let param = {
					accountId: this.activeAccountId,
                    updateId: this.accountId,
                    sheetCode: this.editSheetCode,
                    startDate: this.editStartDate,
                    endDate: this.editEndDate,
                    opcell1: this.editOpcell1,
                    opsyukei8: this.editOpsyukei8,
                    opsyukei12: this.editOpsyukei12,
                    optionTitleCode: this.editOptionTitleCode
                };
                let ress = await Vue.$cache.post('record/sheetreki/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.items = ress.data.data.list;
                        this.sheetList = ress.data.data.sheetList;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async deleteData() {
            try {
                let param = {
					accountId: this.activeAccountId,
                    sheetCode: this.editSheetCode,
                    startDate: this.editStartDate
                };
                let ress = await Vue.$cache.post('record/sheetreki/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.items = ress.data.data.list;
                        this.sheetList = ress.data.data.sheetList;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        addNew() {
            this.selectCode = "";
            let item = {
                code: "",
                startDate: "",
                endDate: "",
                opcell1: false,
                opsyukei8: false,
                opsyukei12: false,
                optionTitleCode: ""
            }
            this.openEditor(item);
        },
        editClick(code, date) {
            let item = this.items.find((itm) => {
                if ((itm.code == code) && (itm.startDate == date)) {
                    return true;
                }
            });
            this.openEditor(item);
        },
        openEditor(item) {
            this.editSheetCode = item.code;
            this.editStartDate = item.startDate;
            this.editEndDate = item.endDate;
            this.editOpcell1 = (item.opcell1) ? true : false;
            this.editOpsyukei8 = (item.opsyukei8) ? true : false;
            this.editOpsyukei12 = (item.opsyukei12) ? true : false;
            this.editOptionTitleCode = item.optionTitleCode;
            this.selectCode = this.editSheetCode;

            this.showEditor = true;
            this.$store.dispatch("operator_backPanel", "on");
        },
        editorOk() {
            if ((this.editSheetCode) && (this.editStartDate)) {
                //-- データ登録
                this.setData();
            }
            this.close_win();
        },
        deleteOk() {
            if (confirm("削除しますか？")) {
                this.deleteOkEx();
            }
        },
        deleteOkEx() {
            if ((this.editSheetCode) && (this.editStartDate)) {
                //-- データ削除
                this.deleteData();
            }
            this.close_win();
        },
        close_win() {
            this.showEditor = false;
            this.$store.dispatch("operator_backPanel", "");
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#sheetRekiPg {
    width: 100%;
    height: 100%;

    .main_area {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 78px 12% 28px;
        overflow: auto;
        display: grid;
        grid-template-rows: 42px auto 0px;

        .header {            
            .addBtn {
                width: 160px;
            }
        }

        .list {
            height: 100%;
            overflow: auto;
        }
    }

    #editorWindow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 406px;
        height: 380px;
        margin: auto;
        box-shadow: 3px 3px 8px 1px rgba(0,0,0,0.2);
        background-color: var(--backColor);
        border: 1px solid #acacac;
        border-radius: 8px;
        z-index: 99991;

        .header_area {
            .close-win {
                float: right;
                width: 32px;
                height: 32px;
                text-align: center;
                padding: 6px 0;
                cursor: pointer;
            }
        }

        .detail_area {
            margin-top: 32px;
            padding: 12px 20px;

            .tblcell {
                padding: 4px 0;
            }

            input[type="text"], select, option {
                height: 28px;
                padding: 0 4px;
            }
        }
        
        .footer_area {
            position: absolute;
            bottom: 24px;
            width: 100%;
            text-align: center;

            .btnOk, .btnDel {
                width: 90px;
                padding: 4px 12px;
            }

            .btnDel {
                margin-left: 22px;
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:1027px) {
    #sheetRekiPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;

            .header {
                width: 1028px;
            }

            .list {
                width: 1028px;
            }

            .footer {
                width: 1028px;
            }
        }
    }
}
</style>