var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card",
      class: {
        selected: _vm.select,
        visible: !_vm.visible,
        editer: _vm.editFlag,
        newCard: _vm.newFlag,
      },
      style: "z-index:" + _vm.zindex + "; top:" + _vm.top + "px",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.card_click($event)
        },
      },
    },
    [
      _c("div", { staticClass: "header_area" }, [
        _c("table", { staticClass: "header" }, [
          _c("tr", [
            _c("td", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.dtitle)),
              _c(
                "span",
                {
                  staticClass: "apply_status",
                  class: { warnning: _vm.warnStatus },
                },
                [_vm._v(_vm._s(_vm.vstatus))]
              ),
            ]),
            !_vm.newFlag
              ? _c("td", { staticClass: "detail" }, [
                  _c("div", { staticClass: "detail_title" }, [
                    _vm._v("取得日時"),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.vstart2))]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "detail_area" }, [
        _c("table", { staticClass: "detail" }, [
          _vm.newFlag
            ? _c("tr", [
                _c("td", { staticClass: "title" }, [_vm._v("休暇種類")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      domProps: { value: _vm.dleaveType },
                      on: {
                        change: function ($event) {
                          return _vm.change_leaveType($event)
                        },
                      },
                    },
                    _vm._l(_vm.dleaveTypeList, function (item, ix) {
                      return _c(
                        "option",
                        { key: ix, domProps: { value: item.code } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.dleaveType == "2"
            ? _c("tr", [
                _c("td", { staticClass: "title" }, [_vm._v("特休種別")]),
                !_vm.editFlag
                  ? _c("td", [_vm._v(_vm._s(_vm.getSpecialLeaveName))])
                  : _vm._e(),
                _vm.editFlag
                  ? _c("td", [
                      _c(
                        "select",
                        {
                          domProps: { value: _vm.dspecialleave },
                          on: {
                            input: function ($event) {
                              return _vm.change_specialleave($event)
                            },
                          },
                        },
                        _vm._l(_vm.dspecialleaveList, function (item, ix) {
                          return _c(
                            "option",
                            { key: ix, domProps: { value: item.code } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("tr", [
            _c("td", { staticClass: "title" }, [_vm._v("有休残日数")]),
            _c("td", [_vm._v(_vm._s(_vm.zanYukyu))]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "title" }, [_vm._v("時間指定")]),
            _c("td", [
              _c("div", { staticClass: "toggle" }, [
                _c(
                  "label",
                  { staticClass: "check_bar", class: { checked: _vm.dtimeon } },
                  [
                    _c("input", {
                      attrs: { type: "checkbox", disabled: !_vm.editFlag },
                      domProps: { checked: _vm.dtimeon },
                      on: {
                        change: function ($event) {
                          return _vm.change_timeon($event)
                        },
                      },
                    }),
                  ]
                ),
                _c("div", {
                  class: { checked: _vm.dtimeon },
                  attrs: { id: "swImg" },
                }),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "title" }, [_vm._v("開始")]),
            !_vm.editFlag ? _c("td", [_vm._v(_vm._s(_vm.vstart))]) : _vm._e(),
            _vm.editFlag
              ? _c(
                  "td",
                  [
                    _vm.dtimeon
                      ? _c("ui-datetime-org", {
                          attrs: {
                            source: _vm.dstarttime,
                            className: "ui-datetimelocal-class",
                          },
                          on: { change: _vm.change_start },
                        })
                      : _vm._e(),
                    !_vm.dtimeon
                      ? _c("ui-date", {
                          attrs: {
                            source: _vm.dstart,
                            className: "ui-date-class",
                          },
                          on: { input: _vm.change_start2 },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("tr", [
            _c("td", { staticClass: "title" }, [_vm._v(_vm._s(_vm.endTitle))]),
            !_vm.editFlag ? _c("td", [_vm._v(_vm._s(_vm.vend))]) : _vm._e(),
            _vm.editFlag && !_vm.dtimeon
              ? _c(
                  "td",
                  [
                    _c("ui-date", {
                      attrs: { source: _vm.dend, className: "ui-date-class" },
                      on: {
                        input: function ($event) {
                          return _vm.change_end($event)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.editFlag && _vm.dtimeon
              ? _c("td", [
                  _c("input", {
                    staticClass: "leave_times",
                    attrs: {
                      type: "number",
                      step: _vm.timeStep,
                      min: _vm.timeStep,
                      max: _vm.timeMax,
                    },
                    domProps: { value: _vm.dendtime },
                    on: {
                      blur: function ($event) {
                        return _vm.change_end($event)
                      },
                    },
                  }),
                  _vm._v(" 時間"),
                ])
              : _vm._e(),
          ]),
          _vm._m(0),
          _c("tr", [
            !_vm.editFlag
              ? _c("td", { staticClass: "comment", attrs: { colspan: "2" } }, [
                  _c("div", { staticClass: "comment_area" }, [
                    _vm._v(_vm._s(_vm.comment)),
                  ]),
                ])
              : _vm._e(),
            _vm.editFlag
              ? _c("td", { staticClass: "comment", attrs: { colspan: "2" } }, [
                  _c("textarea", {
                    domProps: { value: _vm.dcomment },
                    on: {
                      change: function ($event) {
                        return _vm.change_comment($event)
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _c("tr", [
            _vm.applydate != ""
              ? _c(
                  "td",
                  { staticClass: "information", attrs: { colspan: "2" } },
                  [_vm._v("申請日：" + _vm._s(_vm.vapplydate))]
                )
              : _vm._e(),
          ]),
          _vm.approval || _vm.status == "3"
            ? _c("tr", [
                _c(
                  "td",
                  { staticClass: "labe-lonly", attrs: { colspan: "2" } },
                  [_vm._v("差戻し理由")]
                ),
              ])
            : _vm._e(),
          _vm.approval || _vm.status == "3"
            ? _c("tr", [
                !_vm.approval
                  ? _c(
                      "td",
                      { staticClass: "comment", attrs: { colspan: "2" } },
                      [
                        _c("div", { staticClass: "comment_area" }, [
                          _vm._v(_vm._s(_vm.remandComment)),
                        ]),
                      ]
                    )
                  : _c(
                      "td",
                      { staticClass: "comment", attrs: { colspan: "2" } },
                      [
                        _c("textarea", {
                          domProps: { value: _vm.dremandComment },
                          on: {
                            change: function ($event) {
                              return _vm.change_remandComment($event)
                            },
                          },
                        }),
                      ]
                    ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm.approval
        ? _c("div", { staticClass: "footer_area" }, [
            _vm.status != "2"
              ? _c(
                  "div",
                  {
                    staticClass: "approvalBtn largeBtn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.card_approval($event)
                      },
                    },
                  },
                  [_vm._v("承認する")]
                )
              : _vm._e(),
            _vm.status != "2"
              ? _c(
                  "div",
                  {
                    staticClass: "remandBtn largeBtn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.card_remand($event)
                      },
                    },
                  },
                  [_vm._v("差し戻す")]
                )
              : _vm._e(),
            _vm.status == "2"
              ? _c(
                  "div",
                  {
                    staticClass: "deleteBtn largeBtn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.card_approvalCancel($event)
                      },
                    },
                  },
                  [_vm._v("承認の取り消し")]
                )
              : _vm._e(),
          ])
        : _c("div", { staticClass: "footer_area" }, [
            !_vm.newFlag && !_vm.editFlag && _vm.status != "2"
              ? _c(
                  "div",
                  {
                    staticClass: "editBtn largeBtn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.card_edit($event)
                      },
                    },
                  },
                  [_vm._v("編集する")]
                )
              : _vm._e(),
            _vm.editFlag
              ? _c(
                  "div",
                  {
                    staticClass: "updateBtn largeBtn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.card_update($event)
                      },
                    },
                  },
                  [_vm._v("登　　録")]
                )
              : _vm._e(),
            !_vm.newFlag && _vm.status != "2"
              ? _c(
                  "div",
                  {
                    staticClass: "deleteBtn largeBtn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.card_delete($event)
                      },
                    },
                  },
                  [_vm._v("削除する")]
                )
              : _vm._e(),
            !_vm.newFlag && _vm.status == "2"
              ? _c(
                  "div",
                  {
                    staticClass: "deleteBtn largeBtn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.card_cancel($event)
                      },
                    },
                  },
                  [_vm._v("取り下げる")]
                )
              : _vm._e(),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "labe-lonly", attrs: { colspan: "2" } }, [
        _vm._v("備考・理由"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }