var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "recsummary" }, [
    _c("tr", { staticClass: "fixheader" }, [
      _c("td", [_vm._v("所定日数")]),
      _c("td", [_vm._v("出勤日数")]),
      _c("td", [_vm._v("欠勤日数")]),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _vm.opcell1
        ? _c("td", { staticClass: "smallText" }, [
            _vm._v(_vm._s(_vm.opsyukei8Nm)),
          ])
        : _vm._e(),
    ]),
    _c("tr", { staticClass: "cell" }, [
      _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.syoteiDays))]),
      _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.syukinDays))]),
      _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.kekinDays))]),
      _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.yukyuDays))]),
      _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.kyujitsuDays))]),
      _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.tokuDays))]),
      _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.tikokuCnt))]),
      _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.yukyuSyokaDays))]),
      _vm.opcell1
        ? _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.kyugyoDays))])
        : _vm._e(),
    ]),
    _c("tr", { staticClass: "fixheader" }, [
      _vm._m(5),
      _vm._m(6),
      _vm._m(7),
      _vm.hougaiFlag
        ? _c("td", [_vm._v("法外休日"), _c("br"), _vm._v("出勤日数")])
        : _vm._e(),
      _vm.hougaiFlag
        ? _c("td", [_vm._v("法外休日"), _c("br"), _vm._v("労働時間")])
        : _vm._e(),
      _vm.hougaiFlag
        ? _c("td", { staticClass: "comment", attrs: { colspan: "2" } }, [
            _vm._v("備"),
            _c("span"),
            _vm._v("考"),
          ])
        : _vm._e(),
      !_vm.hougaiFlag
        ? _c("td", { staticClass: "comment", attrs: { colspan: "4" } }, [
            _vm._v("備"),
            _c("span"),
            _vm._v("考"),
          ])
        : _vm._e(),
      _vm._m(8),
      _vm.opcell1
        ? _c("td", { staticClass: "smallText covid19" }, [
            _vm._v(_vm._s(_vm.opsyukei12Nm)),
          ])
        : _vm._e(),
    ]),
    _c("tr", { staticClass: "cell" }, [
      _c("td", { staticClass: "times" }, [
        _vm._v(_vm._s(_vm.times(_vm.jikangaiTimes))),
      ]),
      _c("td", { staticClass: "times" }, [
        _vm._v(_vm._s(_vm.times(_vm.sinyaTimes))),
      ]),
      _c("td", { staticClass: "times" }, [
        _vm._v(_vm._s(_vm.times(_vm.kyujitsuTimes))),
      ]),
      _vm.hougaiFlag
        ? _c("td", { staticClass: "nissu" }, [
            _vm._v(_vm._s(_vm.houteigaiDays)),
          ])
        : _vm._e(),
      _vm.hougaiFlag
        ? _c("td", { staticClass: "times" }, [
            _vm._v(_vm._s(_vm.times(_vm.houteigaiTimes))),
          ])
        : _vm._e(),
      _vm.hougaiFlag
        ? _c("td", { attrs: { colspan: "2" } }, [_vm._v(_vm._s(_vm.comment))])
        : _vm._e(),
      !_vm.hougaiFlag
        ? _c("td", { attrs: { colspan: "4" } }, [_vm._v(_vm._s(_vm.comment))])
        : _vm._e(),
      _c("td", { staticClass: "nissu" }, [_vm._v(_vm._s(_vm.yukyuZanDays))]),
      _vm.opcell1
        ? _c("td", { staticClass: "times covid19" }, [
            _vm._v(_vm._s(_vm.times(_vm.kyugyoTimes))),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("有給休暇"), _c("br"), _vm._v("取得日数")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("休日出勤"), _c("br"), _vm._v("日数")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("特別休暇"), _c("br"), _vm._v("取得日数")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "smallText" }, [
      _vm._v("遅刻・早退"),
      _c("br"),
      _vm._v("回数"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("有休消化"), _c("br"), _vm._v("日数")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("時間外"), _c("br"), _vm._v("労働時間")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("深夜"), _c("br"), _vm._v("労働時間")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("休日"), _c("br"), _vm._v("労働時間")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("有休残"), _c("br"), _vm._v("日数")])
  },
]
render._withStripped = true

export { render, staticRenderFns }