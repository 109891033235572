var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "applyPaymentPg" } }, [
    _c("div", { staticClass: "main_area" }, [
      _c("div", { staticClass: "header" }),
      _c("div", { staticClass: "menu_area" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.menu_click("traffic")
              },
            },
          },
          [_vm._v("交通費精算")]
        ),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.menu_click("goods")
              },
            },
          },
          [_vm._v("物品購入精算")]
        ),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.menu_click("near_travel")
              },
            },
          },
          [_vm._v("旅費精算（近距離）")]
        ),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.menu_click("travel")
              },
            },
          },
          [_vm._v("旅費精算（一般）")]
        ),
      ]),
      _c("div", { staticClass: "footer" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }