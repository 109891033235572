<template>
    <div class="goodsPaymentInfo">
        <div class="header_area">
            <div>申請日：{{date}}</div>
            <div class="icon_area">
                <span><icon name="ellipsis-v" class="icon" scale="0.8" @click.stop="menu_popup($event)"></icon></span>
            </div>
        </div>
        <div class="detail_area">
            <div class="info_area">
                <div class="info_area2">
                    <div class="payDate">{{payDate}}</div>
                    <div class="destination_area">{{destination}}</div>
                </div>
                <div class="matter_area">{{matter}}</div>
            </div>
            <div class="money_area">{{getAmount(money)}}<span class="en">円</span></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
export default {
    name: 'goodsPaymentInfo',
    props: {
        id: { type: Number, default: null },
        date: { type: String, default: "" },
        payDate: { type: String, default: "" },
        destination: { type: String, default: "" },
        matter: { type: String, default: "" },
        money: { type: Number, default: null }
    },
    mounted() {

    },
    methods: {
        menu_popup(e) {
            this.$emit('menu_popup', {"e":e, "id":this.id});
        },
        getAmount(val) {
            return Vue.$utils.convertCurrency(val);
        }
    }
}
</script>

<style lang="less" scoped>
.goodsPaymentInfo {
    width: 100%;
    height: 100%;
    border: 1px solid #999;
    border-radius: 12px;
    display: grid;
    grid-template-rows: auto auto;

    .header_area {
        display: grid;
        grid-template-columns: auto 82px;
        padding: 3px 6px;

        .icon_area {
            text-align: right;
            padding-top: 4px;

            span {
                margin-right: -2px;
                cursor: pointer;

                .icon {
                    width: 14px;
                    fill: var(--foreColor);
                }
            }
        }
    }

    .detail_area {
        display: grid;
        grid-template-columns: auto 100px;
        padding: 0 6px 3px;

        .info_area {
            display: grid;
            grid-template-rows: auto auto;

            .info_area2 {
                display: grid;
                grid-template-columns: 100px auto;

                .payDate {
                    white-space: nowrap;
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                }

                .destination_area {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .money_area {
            width: 100px;
            max-width: 100px;
            min-width: 100px;
            justify-content: right;
            align-items: center;
            display: flex;
            font-size: 1.4rem;
            font-weight: bold;

            .en {
                font-size: 0.9rem;
                margin-left: 3px;
                margin-top: 6px;
            }
        }
    }
}
</style>