var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      staticClass: "leave-managed-row",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.row_click($event)
        },
      },
    },
    [
      _c("td", { staticClass: "employNo" }, [
        _vm._v(_vm._s(_vm.cvtEmploy(_vm.item.employNo))),
      ]),
      _c("td", { staticClass: "name" }, [_vm._v(_vm._s(_vm.item.name))]),
      _c("td", { staticClass: "leaveName" }, [_vm._v(_vm._s(_vm.vleaveName))]),
      _c("td", { staticClass: "applydate" }, [_vm._v(_vm._s(_vm.vapplydate))]),
      _c("td", { staticClass: "start" }, [_vm._v(_vm._s(_vm.item.start))]),
      _c("td", { staticClass: "end" }, [_vm._v(_vm._s(_vm.item.end))]),
      _c("td", { staticClass: "status" }, [
        _vm._v(_vm._s(_vm.cvtSyonin(_vm.item.status))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }