<template>
    <div id="stampingPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">

            </div>
            <!-- インフォ -->
            <div class="info_area">
                <div class="inner-panel">
                    <div class="time-disp"><div class="times"><span>{{hour}}</span>:<span>{{minute}}</span><span class="seconds">:{{second}}</span></div></div>
                    <div class="button_area"><div class="button"><div class="enrty-button" :class="{'enable':enable, 'end':endMode}" @click.stop="setStamp">{{btnText}}</div></div></div>
                    <div class="address-area"><div class="address"><div v-if="showAddressFlg">&lt; 現在地 &gt;<br>{{ cvtAddress(address) }}</div><div class="latlog">緯度：{{latitude}}<br>経度：{{longitude}}</div></div></div>
                </div>
            </div>
            <!-- フッター -->
            <div class="footer">

            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import GoogleMapsApiLoader from 'google-maps-api-loader';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "stampingPg",
    mixins: [utilityMixin],
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            accountId: "",
            hour: "00",
            minute: "00",
            second: "00",
            address: "",
            showAddressFlg: false,
            btnText: "出勤",
            enable: false,
            endMode: false,
            recordDate: "",
            start: "",
            end: "",
            google: null,
            geocoder: null,
            geoflag: false,
            latitude: 0,
            longitude: 0,
            xlatitude: 0,
            xlongitude: 0,
            watchID: undefined
        }
    },
    async mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.google = await GoogleMapsApiLoader({
            apiKey: process.env["VUE_APP_VISION_API_KEY"]
        });
        this.geocoder = new this.google.maps.Geocoder();
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", "打刻");
            this.$store.dispatch("operator_baseClick", "");

            this.accountId = this.$store.state.operator.accountId;
            
            this.getTime();
            
            let _self = this;
            const options = {
                enableHighAccuracy: true,
                maximumAge: 30000,
                timeout: 27000
            };
            this.watchID = navigator.geolocation.watchPosition(function(s){
                _self.latitude = s.coords.latitude
                _self.longitude = s.coords.longitude
                if (_self.geoflag == false) {
                    _self.getActionMode();
                }
                _self.geoflag = true;
            }, function(err){
                alert('位置情報の取得が出来ませんでした。\n位置情報へのアクセス許可を確認して下さい。');
            }, options);
            
            if (this.showAddressFlg) {
                setInterval(function(){
                    if ((_self.xlatitude != _self.latitude) || (_self.xlongitude != _self.longitude)) {
                        let latlng = new _self.google.maps.LatLng(_self.latitude, _self.longitude);
                        _self.geocoder.geocode({'latLng':latlng}, function(results, status){
                            if (status == _self.google.maps.GeocoderStatus.OK) {
                                _self.address = results[0].formatted_address;
                                _self.xlatitude = _self.latitude;
                                _self.xlongitude = _self.longitude;
                            }
                        });
                    }
                }, 30000);
            }
        });
    },
    methods: {
        getTime() {
            const now = new Date();
            const intv = 1000 - now.getMilliseconds();
            this.hour = ('0' + now.getHours()).slice(-2);
            this.minute = ('0' + now.getMinutes()).slice(-2);
            this.second = ('0' + now.getSeconds()).slice(-2);
            
            this.time = this.hour + ':' + this.minute + ':' + this.second;

            setTimeout(()=>{
                this.getTime();
            }, intv);
        },
        async getActionMode() {
            try {
                this.btnText = "出勤";
                this.endMode = false;
                this.enable = false;
                let now = new Date();
                let param = {
                    accountId: this.accountId,
                    date: now.toChar("yyyy-MM-dd")
                };
                let ress = await Vue.$cache.post('stamping/status/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.enable = (ress.data.data.enable == 1) ? true : false;
                        this.endMode = (ress.data.data.endMode == 1) ? true : false;
                        if (this.endMode) {
                            this.btnText = "退勤";
                        }
                        this.recordDate = ress.data.data.recordDate
                        this.start = ress.data.data.startDate
                        this.end = ress.data.data.endDate
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch(error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async setStamp() {
            if (this.enable == false) {
                return false;
            }

            try {
                this.enable = false;
                let now = new Date();
                await this.getGeoPosition();
                let data = {};
                if (this.btnText != "出勤") {
                    let v = 30;
                    let c = (this.minute / v | 0);
                    let n = (this.minute % v);
                    if (n > (v / 2)) {
                        c += 1;
                    }
                    let ho = this.hour - 0;
                    let mm = v * c;
                    if (mm >= 60) {
                        ho += 1;
                        mm = 0;
                    }
                    this.end = String(100 + ho).substr(1,2) + ":" + String(100 + mm).substr(1, 2);
                    data.date = this.recordDate;
                    data.start = this.start;
                    data.end = this.end;
                    Vue.$utils.calcTimeRecords(data);
                }
                let param = {
                    accountId: this.accountId,
                    date: now.toChar("yyyy-MM-dd"),
                    time: now.toChar("HH:mm"),
                    stamp: (this.btnText == "出勤") ? "s" : "e",
                    hour: this.hour,
                    minute: this.minute,
                    data: data,
                    address: this.address,
                    latitude: this.latitude,
                    longitude: this.longitude
                };
                let ress = await Vue.$cache.post('stamping/setStatus/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.enable = (ress.data.data.enable == 1) ? true : false;
                        this.endMode = (ress.data.data.endMode == 1) ? true : false;
                        if (this.endMode) {
                            this.btnText = "退勤";
                        } else {
                            this.btnText = "出勤";
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch(error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async getGeoPosition() {
            try {
                /*
                const googlemap = await GoogleMapsApiLoader({
                    apiKey: 'AIzaSyAClDn49sGWXBz7Jd4K7p8-S6h674xP4oA'
                }); */
                const s = await this.getCurrentPosition();
                this.latitude = s.coords.latitude
                this.longitude = s.coords.longitude
                if ((this.xlatitude != this.latitude) || (this.xlongitude != this.longitude)) {
                    let latlng = new this.google.maps.LatLng(s.coords.latitude, s.coords.longitude);
                    let _self = this;
                    this.geocoder.geocode({'latLng':latlng}, function(results, status){
                        if (status == _self.google.maps.GeocoderStatus.OK) {
                            _self.address = results[0].formatted_address;
                            _self.xlatitude = _self.latitude;
                            _self.xlongitude = _self.longitude;
                        }
                    });
                }
            } catch(error) {
                console.log(error);
            }
        },
        getCurrentPosition() {
            return new Promise(function(resolve, reject) {
                navigator.geolocation.getCurrentPosition(resolve, reject, {timeout:10000});
            });
        },
        cvtAddress(address) {
            if (address == "") {
                return "";
            }
            let ress = address.split(" ");
            return ress[1];
        },
        base_click() {
            
        }
    }
}
</script>

<style lang="less">
#stampingPg {
    width: 100%;
    height: 100%;

    .main_area {
        width: 100%;
        height: 100%;
        padding: 78px 12% 28px;
        
        .info_area {
            position: relative;
            width: 100%;
            height: 100%;
            
            .inner-panel {
                width: 100%;
                height: 100%;
                text-align: center;
                display: table;
                
                .time-disp {
                    display: table-row;

                    .times {
                        display: table-cell;
                        font-size: 5.5rem;
                        font-weight: bold;
                        text-align: center;
                        height: 120px;
                        vertical-align: middle;

                        .seconds {
                            font-size: 3rem;
                            padding-left: 3px;
                        }
                    }
                }

                .button_area {
                    display: table-row;

                    .button {
                        display: table-cell;
                        vertical-align: middle;

                        .enrty-button {
                            display: inline-block;
                            width: calc(100% - 18px);
                            height: 152px;
                            padding: 12px;
                            background-color: #c4c4c4;
                            font-size: 4.6rem;
                            font-weight: bold;
                            vertical-align: middle;
                            border-radius: 76px;
                            border: 8px solid #999999;
                            box-shadow: 3px 3px 8px 4px rgba(0,0,0,0.2);
                            margin: 26px 0 38px;
                            color: #999999;
                            cursor: pointer;
                        }

                        .enrty-button.enable {
                            background-color: #acd9ff;
                            border: 8px solid #49acfd;
                            color: #308fdd;
                        }

                        .enrty-button.end {
                            background-color: #cdffac;
                            border: 8px solid #70f03d;
                            color: #55c229;
                        }
                    }
                }

                .address-area {
                    display: table-row;

                    .address {
                        height: 100px;
                        display: table-cell;
                        text-align: left;
                        vertical-align: bottom;
                        padding: 0 22px;
                        color: #6e6e6e;
                        font-size: 0.8rem;

                        .latlog {
                            padding: 0 22px;
                            font-size: 0.7rem;
                        }
                    }
                }
            }
        }
    }
}

#base_area {
    height: 100%;

    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:500px) {
    #stampingPg {
        .main_area {
            padding: 78px 12px 28px;
        }
    }
}
</style>