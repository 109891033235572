var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "configPg" } }, [
    _c("div", { staticClass: "main_area" }, [
      _c("div", { staticClass: "header" }),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "styleMode" }, [
          _vm._v(" 外観モード： "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.configDb.darkMode,
                  expression: "configDb.darkMode",
                },
              ],
              attrs: { type: "radio", value: "light" },
              domProps: { checked: _vm._q(_vm.configDb.darkMode, "light") },
              on: {
                change: [
                  function ($event) {
                    return _vm.$set(_vm.configDb, "darkMode", "light")
                  },
                  function ($event) {
                    $event.stopPropagation()
                    return _vm.changeScheme.apply(null, arguments)
                  },
                ],
              },
            }),
            _vm._v("ライト"),
          ]),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.configDb.darkMode,
                  expression: "configDb.darkMode",
                },
              ],
              attrs: { type: "radio", value: "dark" },
              domProps: { checked: _vm._q(_vm.configDb.darkMode, "dark") },
              on: {
                change: [
                  function ($event) {
                    return _vm.$set(_vm.configDb, "darkMode", "dark")
                  },
                  function ($event) {
                    $event.stopPropagation()
                    return _vm.changeScheme.apply(null, arguments)
                  },
                ],
              },
            }),
            _vm._v("ダーク"),
          ]),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.configDb.darkMode,
                  expression: "configDb.darkMode",
                },
              ],
              attrs: { type: "radio", value: "" },
              domProps: { checked: _vm._q(_vm.configDb.darkMode, "") },
              on: {
                change: [
                  function ($event) {
                    return _vm.$set(_vm.configDb, "darkMode", "")
                  },
                  function ($event) {
                    $event.stopPropagation()
                    return _vm.changeScheme.apply(null, arguments)
                  },
                ],
              },
            }),
            _vm._v("自動"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "footer" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }