var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "stampingPg" } }, [
    _c("div", { staticClass: "main_area" }, [
      _c("div", { staticClass: "header" }),
      _c("div", { staticClass: "info_area" }, [
        _c("div", { staticClass: "inner-panel" }, [
          _c("div", { staticClass: "time-disp" }, [
            _c("div", { staticClass: "times" }, [
              _c("span", [_vm._v(_vm._s(_vm.hour))]),
              _vm._v(":"),
              _c("span", [_vm._v(_vm._s(_vm.minute))]),
              _c("span", { staticClass: "seconds" }, [
                _vm._v(":" + _vm._s(_vm.second)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "button_area" }, [
            _c("div", { staticClass: "button" }, [
              _c(
                "div",
                {
                  staticClass: "enrty-button",
                  class: { enable: _vm.enable, end: _vm.endMode },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.setStamp.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.btnText))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "address-area" }, [
            _c("div", { staticClass: "address" }, [
              _vm.showAddressFlg
                ? _c("div", [
                    _vm._v("< 現在地 >"),
                    _c("br"),
                    _vm._v(_vm._s(_vm.cvtAddress(_vm.address))),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "latlog" }, [
                _vm._v("緯度：" + _vm._s(_vm.latitude)),
                _c("br"),
                _vm._v("経度：" + _vm._s(_vm.longitude)),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "footer" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }