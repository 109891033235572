import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const initialState = {
	cachedb: {
		cspSessionCookie: ""
	},
	operator: {
		deviceCode: "",
		accountId: "",
		authority: "",
		baseDate: 11,
		activeAccountId: "",
		activeYear: 0,
		activeMonth: 0,
		activeSheet: "",
		manager: "",
		headerTitle: "",
		baseClick: "",
		backPanel: "",
		logoutJump: "",
		routeParam: {}
	},
	config: {
		darkMode: ""
	}
}

export default new Vuex.Store({
	state: initialState,
	getters: {
		operator_baseClick(state) {
			return state.operator.baseClick;
		},
		operator_backPanel(state) {
			return state.operator.backPanel;
		},
		operator_logoutJump(state) {
			return state.operator.logoutJump;
		}
	},
	mutations: {
		cachedb_cspSessionCookie(state, payload) {
				state.cachedb.cspSessionCookie = payload;
		},
		operator_deviceCode(state, payload) {
			state.operator.deviceCode = payload;
		},
		operator_accountId(state, payload) {
			state.operator.accountId = payload;
		},
		operator_authority(state, payload) {
			state.operator.authority = payload;
		},
		operator_baseDate(state, payload) {
			state.operator.baseDate = payload;
		},
		operator_activeAccountId(state, payload) {
			state.operator.activeAccountId = payload;
		},
		operator_activeYear(state, payload) {
			state.operator.activeYear = payload;
		},
		operator_activeMonth(state, payload) {
			state.operator.activeMonth = payload;
		},
		operator_activeSheet(state, payload) {
			state.operator.activeSheet = payload;
		},
		operator_manager(state, payload) {
			state.operator.manager = payload;
		},
		operator_headerTitle(state, payload) {
			state.operator.headerTitle = payload;
		},
		operator_baseClick(state, payload) {
			state.operator.baseClick = payload;
		},
		operator_backPanel(state, payload) {
			state.operator.backPanel = payload;
		},
		operator_logoutJump(state, payload) {
			state.operator.logoutJump = payload;
		},
		operator_routeParam(state, payload) {
			state.operator.routeParam = payload;
		}
		,
		config_darkMode(state, payload) {
			state.config.darkMode = payload;
		}
	},
	actions: {
		operator_baseClick(store, data) {
			store.commit("operator_baseClick", data);
		},
		operator_backPanel(store, data) {
			store.commit("operator_backPanel", data);
		},
		operator_logoutJump(store, data) {
			store.commit("operator_logoutJump", data);
		}
	},
	plugins: [createPersistedState({key:'ktsys'})]
})
