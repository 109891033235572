<template>
    <div id="paymentGoodsPg">
        <div class="receipt" v-for="(itm, ix) in items" :key="ix">
            <div class="closed_btn" @click="deleteReceipt(ix)"></div>
            <div class="image_area"><div class="edit_btn" @click.stop="editImage($event, itm, ix)"></div><img :src="'data:*/*;base64,' + itm.image" class="photo" @click="openImage(itm.base64)"/></div>
            <div class="row_area"><div class="cell_left" @click="editField($event, itm, ix, 'date')">{{itm.date}}</div><div class="cell_right" @click="editField($event, itm, ix, 'destination')">{{itm.destination}}</div></div>
            <div class="row_area"><div class="cell_left" @click="editField($event, itm, ix, 'name')">{{itm.name}}</div><div class="cell_right" @click="editField($event, itm, ix, 'amount')">{{cvtAmount(itm.amount)}}</div></div>
            <div class="row_area"><div class="cell_left" :class="{'disabled_cmt':itm.comment==''}" @click="editField($event, itm, ix, 'comment')">{{getComment(itm.comment)}}</div></div>
        </div>
        <div class="footer">
            <div class="complete_btn" @click.stop="completeData"></div>
            <div class="photo_btn" @click.stop="addPhoto"></div>
            <input type="file" name="photos" id="photos" accept="image/*;capture=camera" @change="photos_select" style="display:none"/>
        </div>
        <div v-show="editFieldFlg" ref="edit_cell" class="edit_field" @click.stop="empty"><input type="text" v-model="valEdit" @keyup="edit_keyup" @keypress="edit_keypress"/></div>
        <div v-show="editDateFieldFlg" ref="edit_date" class="edit_field" @click.stop="empty"><input type="date" v-model="valEdit" @change="edit_change" @keyup="edit_keyup" @keypress="edit_keypress"/></div>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
const apiKey = process.env["VUE_APP_VISION_API_KEY"];
const visionApiUrl = 'https://vision.googleapis.com/v1/images:annotate?key=' + apiKey;
export default {
    name: "paymentGoodsPg",
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    data() {
        return {
            headerTitle: "経費精算",
            accountId: "",
            activeAccountId: "",
            kakuteiFlag: false,
            items: [],
            param: {
                key: ""
            },
            editFieldFlg: false,
            editDateFieldFlg: false,
            valEdit: "",
            valBackup: "",
            activeIndex: -1,
            activeField: "",
            onLoadImage: false
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;

            this.param = this.$store.state.operator.routeParam;

            if ((this.param.key != "") && (this.param.key != undefined)) {
                this.getData(this.param.key);
            }
            //console.log(encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>'));
        });
    },
    methods: {
        async getData(key) {
            try {
                this.items = [];
                let param = {
					staffId: this.activeAccountId,
                    id: key
                };
                let ress = await Vue.$cache.post('payment/goods/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.kakuteiFlag = ress.data.data.kakuteiFlag;
                        this.items = ress.data.data.list;
                        this.items.forEach(item => {
                            item.base64 = encodeURIComponent(item.image);
                        });
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        completeData() {
            if (!confirm("以上の内容で精算書の作成を行いますか？")) {
                return;
            }
            if (!this.checkData()) {
                alert("内容に不備があります。");
                return;
            }
            this.saveData();
        },
        async saveData() {
            try {
                let key = "";
                if (this.param) {
                    if (this.param.key) {
                        key = this.param.key;
                    }
                }
                let param = {
                    userId: this.accountId,
					staffId: this.activeAccountId,
                    key: key,
                    items: this.items
                };
                let ress = await Vue.$cache.post('payment/goods/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        //--
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        checkData() {
            let result = true;

            this.items.forEach(item => {
                if ((item.base64 == "") || (item.base64 == undefined)) {
                    result = false;
                    return;
                }
                if ((item.date == "") || (item.date == undefined)) {
                    result = false;
                    return;
                }
                if ((item.destination == "") || (item.destination == undefined)) {
                    result = false;
                    return;
                }
                if ((item.name == "") || (item.name == undefined)) {
                    result = false;
                    return;
                }
                if ((item.amount == "") || (item.amount == undefined)) {
                    result = false;
                    return;
                }
            });

            return result;
        },
        deleteReceipt(key) {
            if (this.kakuteiFlag) return false;

            if (!confirm("削除しますか？")) {
                return;
            }
            console.log(key);
            this.items.splice(key, 1);
        },
        addPhoto() {
            document.getElementById("photos").value = "";
			document.getElementById("photos").click();
        },
        photos_select(e) {
			let files = e.target.files || e.dataTransfer.files;
			this.createImage(files);
		},
        createImage(files) {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
				let image = new Image();
				let reader = new FileReader();
                let maxsize = 640;
				let blob = null;
				let orientation = 0;
                let _self = this;

                reader.onload = (e) => {
                    image.onload = (e) => {
                        let w, h, rt;
                        let canvas = document.createElement('canvas');

                        if (image.width > image.height) {
							w = (image.width < maxsize) ? image.width : maxsize;
							rt = w / image.width;
							h = image.height * rt;
						} else {
							h = (image.height < maxsize) ? image.height : maxsize;
							rt = h / image.height;
							w = image.width * rt;
						}

                        let ctx = canvas.getContext('2d');
						ctx.clearRect(0, 0, w, h);

                        canvas.width = w;
						canvas.height = h;

                        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, w, h);
                        
                        let base64 = canvas.toDataURL('image/jpeg');
						let bin = atob(base64.split('base64,')[1]);
						let len = bin.length;
						let barr = new Uint8Array(len);
						let i = 0;
						while (i < len) {
							barr[i] = bin.charCodeAt(i);
							i++;
						}
						blob = new Blob([barr], {type: 'image/jpeg'});

                        let base64enc = btoa(bin);

                        
                        if ((_self.onLoadImage) && (_self.activeIndex >= 0)) {
                            let item = _self.items[_self.activeIndex];
                            item.image = base64enc;
                            item.base64 = encodeURIComponent(base64enc);
                            _self.items[_self.activeIndex] = item;
                        } else {
                            let Textlist = _self.getRecognizedText(base64enc);
                            Textlist.then((value) => {
                                let item = {};
                                item.image = base64enc;
                                item.base64 = encodeURIComponent(base64enc);
                                let data = _self.getTextValue(value);
                                if (data) {
                                    item.date = data.date;
                                    item.destination = data.destination;
                                    item.name = data.name;
                                    item.amount = data.amount;
                                    item.comment = data.comment;
                                    _self.items.push(item);
                                }
                            });
                        }

                        _self.onLoadImage = false;
                    };
                    orientation = _self.getOrientation(e.target.result);

                    let binary = '';
					let bytes = new Uint8Array(e.target.result);
					let len = bytes.byteLength;
					for (let i = 0; i < len; i++) {
						binary += String.fromCharCode( bytes[ i ] );
					}
					let base64enc = btoa(binary);
					
					image.src = "data:*/*;base64," + base64enc;
                };
                reader.readAsArrayBuffer(file);
            }
        },
        openImage(base64) {
            let url = 'data:*/*;base64,'+base64;
            let w = window.open('about:blank');
            setTimeout(() => {
                let img = w.document.createElement('img');
                img.src = url;
                w.document.body.appendChild(img);
            }, 0);
        },
        async getRecognizedText(base64enc) {
            let result = {};
            try {
                let param = {
                    requests: [
                        {
                            features: [
                                {
                                    type: "TEXT_DETECTION"
                                }
                            ],
                            image: {
                                content: base64enc
                            }
                        }
                    ]
                };
                
                let ress = await axios.post(visionApiUrl, param);
                if (ress.status == 200) {
                    console.log(ress.data.responses[0]);
                    let list = ress.data.responses[0].fullTextAnnotation.text.split("\n");
                    result.list = list;
                    result.antList = ress.data.responses[0].textAnnotations;
                    return result;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
            return;
        },
        getTextValue(data) {
            let result = {
                date: "",
                destination: "",
                name: "",
                amount: "",
                comment: ""
            };
            
            let amount = "";
            let size = 0;
            let preText = "";
            for (let i = 1; i < data.antList.length; i++) {
                const dat = data.antList[i];
                console.log(dat);
                if (dat.description.match(/¥{1}\d{1,}/g)) {
                    let p = dat.boundingPoly.vertices[3].y - dat.boundingPoly.vertices[1].y
                    if (p < 0) { p = p * -1; }
                    if (p > size) {
                        result.amount = dat.description.replace(/[¥,-円]/g, "");
                        size = p;
                    }
                }
                if (preText == "¥") {
                    if (dat.description.match(/\d{1,}/g)) {
                        let p = dat.boundingPoly.vertices[3].y - dat.boundingPoly.vertices[1].y
                        if (p < 0) { p = p * -1; }
                        if (p > size) {
                            if (dat.description.match(/\D{1,}/)) {
                                result.amount = dat.description.replace(/[¥,-円]/g, "");
                            } else {
                                result.amount = dat.description;
                            }
                            size = p;
                        }
                    }
                }
                preText = dat.description;
            }

            preText = "";
            for (let i = 0; i < data.list.length; i++) {
                const dat = data.list[i];
                console.log(dat);
                if (result.amount == "") {
                    if (preText == "通行料金") {
                        result.name = preText;
                        result.amount = dat.replace(/[¥,-円]/g, "");
                    }
                }
                let regres = dat.match(/(?<year>\d{2}|\d{4})[年./-]{1}\D{0,}(?<month>\d{1,2})[月./-]{1}\D{0,}(?<day>\d{1,2})日{0,}/u);
                if (regres) {
                    let year = regres.groups.year;
                    if ((year + "").length < 3) {
                        year = 2000 + (year - 0);
                    }
                    let day = new Date(year, (regres.groups.month - 1), regres.groups.day);
                    result.date = day.toChar("yyyy-MM-dd");
                }
                if ((dat.indexOf("株式会社") != -1) || (dat.indexOf("(株)") != -1) || (dat.indexOf("合資会社") != -1) || (dat.indexOf("(資)") != -1) || (dat.indexOf("有限会社") != -1) || (dat.indexOf("(有)") != -1)) {
                    result.destination = dat;
                } else if (dat.endsWith("店")) {
                    result.destination = dat;
                }
                preText = dat;
            }

            return result;
        },
        cvtAmount(val) {
            return Vue.$utils.convertCurrency(val, "¥");
        },
        getComment(val) {
            let result = "備考を記入...";
            if ((val != "") && (val != undefined)) {
                result = val;
            }
            return result;
        },
        getOrientation(buffer) {
			let dv = new DataView(buffer);
			let app1MarkerStart = 2;
			if (dv.getUint16(app1MarkerStart) !== 65505) {
				let length = dv.getUint16(4);
				app1MarkerStart += length + 2;
			}
			if (dv.getUint16(app1MarkerStart) !== 65505) {
				return 0
			}
			
			let littleEndian = dv.getUint8(app1MarkerStart + 10) === 73;
			let count = dv.getUint16(app1MarkerStart + 18, littleEndian);
			for (let i = 0; i < count; i++) {
				let start = app1MarkerStart + 20 + i * 12;
				let tag = dv.getUint16(start, littleEndian);
				if (tag === 274) {
					return dv.getUint16(start + 8, littleEndian);
				}
			}
			return 0;
		},
        editImage(e, data, ix) {
            if (document.getElementById("sideMenu").classList.contains("on_menu")) {
                return false;
            }

            e.stopPropagation();

            if (this.kakuteiFlag) return false;

            this.activeIndex = ix;
            this.onLoadImage = true;

            document.getElementById("photos").value = "";
			document.getElementById("photos").click();
        },
        editField(e, data, ix, field) {
            if (document.getElementById("sideMenu").classList.contains("on_menu")) {
                return false;
            }

            e.stopPropagation();

            if (this.kakuteiFlag) return false;

            this.activeIndex = ix;
            this.activeField = field;
            
            if (field == "date") {
                this.valEdit = data.date;
            } else if (field == "destination") {
                this.valEdit = data.destination;
            } else if (field == "name") {
                this.valEdit = data.name;
            } else if (field == "amount") {
                this.valEdit = data.amount + "";
            } else if (field == "comment") {
                this.valEdit = data.comment;
            }

            this.valBackup = this.valEdit;

            let elm = null;
            if (field == "date") {
                elm = this.$refs.edit_date;
                this.editDateFieldFlg = true;
                this.editFieldFlg = false;
                if (this.valEdit == "") {
                    let now = new Date();
                    this.valEdit = now.toChar("yyyy-MM-dd");
                }
            }  else if (field == "amount") {
                elm = this.$refs.edit_cell;
                this.editFieldFlg = true;
                this.editDateFieldFlg = false;
            } else {
                elm = this.$refs.edit_cell;
                this.editFieldFlg = true;
                this.editDateFieldFlg = false;
            }

            let area = document.querySelector("#paymentGoodsPg");
            let rect = e.target.getBoundingClientRect();
            
            elm.style.width = e.target.clientWidth + "px";
            elm.style.height = e.target.clientHeight + 2 + "px";
            elm.style.top = rect.top + document.documentElement.scrollTop - 1 + "px";
            elm.style.left = rect.left - area.offsetLeft + area.scrollLeft + "px";

            let _field = field
            setTimeout(function(){
                let inp = elm.getElementsByTagName("input")[0];
                if (_field != "date") {
                    inp.setSelectionRange(0, 10);
                }
                inp.focus();
            }, 100);
        },
        edit_keyup(e) {
            if (e.keyCode == 27) {
                this.base_click();
                this.items[this.activeIndex][this.activeField] = this.valBackup;
                return;
            }
            this.items[this.activeIndex][this.activeField] = e.currentTarget.value;
        },
        edit_keypress(e) {
            if ((e.keyCode == 13) || (e.keyCode == 9)) {
                if (this.editFieldFlg) { this.editFieldFlg = false; }
                if (this.editDateFieldFlg) { this.editDateFieldFlg = false; }
                return;
            }
        },
        edit_change(e) {
            if (this.activeField == "date") {
                this.items[this.activeIndex][this.activeField] = e.currentTarget.value;
            }
            if (this.editFieldFlg) { this.editFieldFlg = false; }
            if (this.editDateFieldFlg) { this.editDateFieldFlg = false; }
            return;
        },
        empty() {

        },
        base_click() {
            if (this.editFieldFlg) { this.editFieldFlg = false; }
            if (this.editDateFieldFlg) { this.editDateFieldFlg = false; }
            this.valEdit = "";
        }
    }
}
</script>

<style lang="less" scoped>
#paymentGoodsPg {
    position: relative;
    width: 100%;
    overflow: auto;
    padding: 110px 12% 24px;

    .receipt {
        position: relative;
        box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.2);
        margin-bottom: 12px;
        padding: 6px;
        border-radius: 4px;
        width: 360px;
        float: left;

        .closed_btn {
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            text-align: center;
            background-color: var(--backColor);
            box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
            top: -6px;
            right: -6px;
            cursor: pointer;
            padding-top: 1px;
            z-index: 1;

            &::after {
                content: '×';
            }
        }

        .image_area {
            position: relative;
            padding: 3px 3px 0;

            .edit_btn {
                position: absolute;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                text-align: center;
                background-color: var(--backColor);
                box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
                top: 6px;
                left: 6px;
                cursor: pointer;
                z-index: 1;
                
                &::after {
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M3%2017.25V21h3.75L17.81%209.94l-3.75-3.75L3%2017.25zM20.71%207.04c.39-.39.39-1.02%200-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41%200l-1.83%201.83%203.75%203.75%201.83-1.83z%22%2F%3E%3C%2Fsvg%3E");
                    mask: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M3%2017.25V21h3.75L17.81%209.94l-3.75-3.75L3%2017.25zM20.71%207.04c.39-.39.39-1.02%200-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41%200l-1.83%201.83%203.75%203.75%201.83-1.83z%22%2F%3E%3C%2Fsvg%3E");
                    mask-size: cover;
                    display: inline-block;
                    height: 17px;
                    width: 17px;
                    margin-top: 3px;
                    background-color: var(--foreColor);
                }
            }

            .photo {
                max-width: 100%;
                margin: auto;
            }
        }

        .row_area {
            display: grid;
            grid-template-columns: auto auto;

            div {
                padding: 6px;
            }

            .cell_right {
                text-align: right;
            }

            .disabled_cmt {
                color: #8a8a8a;
            }
        }
    }

    .footer {
        position: fixed;
        top: 50px;
        left: 0;
        width: 100%;
        height: 48px;
        background-color: #8e8e8e;
        z-index: 2;

        .photo_btn {
            position: absolute;
            top: 8px;
            right: 104px;
            width: 80px;
            height: 32px;
            border-radius: 8px;
            background-color: @color-base;
            text-align: center;
            padding: 4px;
            cursor: pointer;

            &::before {
                content: "追加";
                color: white;
            }
        }

        .complete_btn {
            position: absolute;
            top: 8px;
            right: 12px;
            width: 80px;
            height: 32px;
            border-radius: 8px;
            background-color: @color-base;
            text-align: center;
            padding: 4px;
            cursor: pointer;

            &::before {
                content: "確定";
                color: white;
            }
        }
    }

    .edit_field {
        position: absolute;
        background-color: #FFFFFF;
        box-shadow: 1px 1px 6px 2px rgba(0,0,0,0.2);
        color: #000000;
        
        input[type="text"], input[type="date"] {
            width: 100%;
            height: 100%;
            border: 0;
            padding: 0 6px;
            background-color: #FFFFFF;
            color: #000000;
            
            &:focus {
                border: 0;
                outline: 0;
            }
        }
    }
}

#base_area {
    height: 100%;

    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:500px) {
    #paymentGoodsPg {
        width: 100%;
        padding: 68px 12px 50px;

        .receipt {
            width: 100%;
        }

        .footer {
            position: fixed;
            top: auto;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 48px;
            border-top: 1px solid #000000;
            background-color: #8e8e8e;

            .photo_btn {
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto auto 3px;
                width: 52px;
                height: 52px;
                border-radius: 50%;
                background-color: @color-base;

                &::before {
                    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20height%3D%2224px%22%20width%3D%2224px%22%20fill%3D%22%23ffffff%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%223.2%22%2F%3E%3Cpath%20d%3D%22M9%202L7.17%204H4c-1.1%200-2%20.9-2%202v12c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2V6c0-1.1-.9-2-2-2h-3.17L15%202H9zm3%2015c-2.76%200-5-2.24-5-5s2.24-5%205-5%205%202.24%205%205-2.24%205-5%205z%22%2F%3E%3C%2Fsvg%3E");
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    margin-top: 9px;
                }
            }

            .complete_btn {
                top: 8px;
                right: 12px;
                width: 32px;
                height: 32px;
                border-radius: 0;
                background-color: initial;
                text-align: center;
                padding: 4px;

                &::before {
                    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20enable-background%3D%22new%200%200%2024%2024%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23FFFFFF%22%3E%3Cg%3E%3Crect%20fill%3D%22none%22%20height%3D%2224%22%20width%3D%2224%22%2F%3E%3C%2Fg%3E%3Cg%3E%3Cg%3E%3Crect%20height%3D%222%22%20width%3D%2211%22%20x%3D%223%22%20y%3D%2210%22%2F%3E%3Crect%20height%3D%222%22%20width%3D%2211%22%20x%3D%223%22%20y%3D%226%22%2F%3E%3Crect%20height%3D%222%22%20width%3D%227%22%20x%3D%223%22%20y%3D%2214%22%2F%3E%3Cpolygon%20points%3D%2220.59%2C11.93%2016.34%2C16.17%2014.22%2C14.05%2012.81%2C15.46%2016.34%2C19%2022%2C13.34%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                }
            }
        }
    }
}
</style>